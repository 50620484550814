import { createSelector } from "reselect";

const profileSelector = (state) => state.user.profileData;

export const selectorProfile = createSelector(
  profileSelector,
  (items) => items
);

const headerTitleSelector = (state) => state.header.title;

export const selectorHeaderTitle = createSelector(
  headerTitleSelector,
  (items) => items
);

const headerTitleBtnSelector = (state) => state.header.isBtnBack;

export const selectorHeaderTitleBtn = createSelector(
  headerTitleBtnSelector,
  (items) => items
);
// const isInventoryDataLoading = (state) => state.inventory.isLoading;

// export const selectorIsInventoryDataLoading = createSelector(
//   isInventoryDataLoading,
//   (items) => items
// );
