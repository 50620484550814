import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import {
  selectorEtsyConnectUrl,
  selectorEtsyConnectUrlError,
  selectorEtsyConnectUrlLoading,
} from "../../Selector";

import * as routes from "router/routes";

import platformsReducer from "store/entities/Platforms/platforms.reducer";

import { Button, Loader } from "components/simple";

import styles from "../integrationScreen.module.scss";

const {
  fetchEstyCallbackRequest,
  getEstyConnectUrlRequest,
} = platformsReducer.actions;

const Etsy = ({
  marketplaceName,
  channelId,
  statusConnection,
  isAuthPassed,
  redirect,
}) => {
  // const etsyConnectUrl = useSelector(selectorEtsyConnectUrl);
  const isEtsyUrlLoading = useSelector(selectorEtsyConnectUrlLoading);
  const connetcUrlError = useSelector(selectorEtsyConnectUrlError);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const credentials = location.search;

    // TODO: Remove
    // if (!credentials && !statusConnection) {
    //   // dispatch(getEstyConnectUrlRequest());
    // }

    if (credentials) {
      dispatch(
        fetchEstyCallbackRequest({
          credentials,
        })
      );
      history.push(`${routes.integrationsScreen}/${channelId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isEtsyUrlLoading || redirect ? (
        <Loader />
      ) : (
        <>
          {isAuthPassed || statusConnection ? (
            <div className={styles.connectMessage}>
              {`Your ${marketplaceName} account is now connected to ListAcross`}
            </div>
          ) : (
            <div className={styles.connectBtnWrapper}>
              {/* <a href={etsyConnectUrl} target="_blank" rel="noreferrer"> */}
              <Button
                className={styles.connectBtn}
                disabled={connetcUrlError}
                onClick={() => {
                  const credentials = location.search;

                  if (!credentials && !statusConnection) {
                    dispatch(getEstyConnectUrlRequest());
                  }
                }}
              >
                Connect Channel
              </Button>
              {/* </a> */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Etsy;
