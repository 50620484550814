import React from "react";

import Loader from "components/simple/Loader";

import styles from "./avatar.module.scss";
import helpers from "styles/helpers.module.scss";

export const API = `${process.env.REACT_APP_API_URL}`;

const Avatar = ({
  photo,
  fullName,
  setAvatarHandler,
  isAvatarLoading,
  isUserDataLoading,
}) => {
  const avatarChangeHandler = (e) => {
    if (e.target.files.length) {
      const id = localStorage.getItem("uid");
      const formData = new FormData();
      formData.append("attached_photo", e.target.files[0]);
      setAvatarHandler({ id, formData });
    }
  };

  return (
    <div className={`${styles.profileAvatarWrapper} ${helpers.contentRowLine}`}>
      <div className={styles.avatarBlock}>
        {isAvatarLoading || isUserDataLoading ? (
          <Loader />
        ) : (
          <>
            <input name="avatar" type="file" onChange={avatarChangeHandler} />
            {photo ? (
              <img src={`${API}${photo}`} alt="user avatar" />
            ) : (
              <label htmlFor="avatar">Upload</label>
            )}
          </>
        )}
      </div>
      <div className={styles.name}>{fullName}</div>
    </div>
  );
};

export default Avatar;
