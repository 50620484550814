import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import repliesReducer from "./replies.reducer";
import notificationsReducer from "store/entities/Notification/notifications.reducer";
import usersReducer from "store/entities/Users/users.reducer";

import * as service from "./replies.service";
import { makeRequest } from "../../sagas/helpers";

const {
  // Get auto-replies
  autoRepliesRequest,
  autoRepliesSuccess,
  autoRepliesFailure,

  // Create reply
  createAutoReplyRequest,
  createAutoReplySuccess,
  createAutoReplyFailure,

  // Update auto-reply
  updateAutoReplyRequest,
  updateAutoReplySuccess,
  updateAutoReplyFailure,

  // Delete reply
  deleteAutoReplyRequest,
  deleteAutoReplySuccess,
  deleteAutoReplyFailure,

  // Get replies
  repliesRequest,
  repliesSuccess,
  repliesFailure,

  // Get shortcuts
  shortcutsRequest,
  shortcutsSuccess,
  shortcutsFailure,
} = repliesReducer.actions;

const { logoutSuccess } = usersReducer.actions;

const {
  actions: { addNotification: addNotificationAction },
} = notificationsReducer;

// workers

function* getAutoRepliesWorker() {
  try {
    const response = yield call(makeRequest(service.fetchAutoReplies));

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: response?.message?.non_field_errors,
            type: "danger",
          })
        );
        yield put(autoRepliesFailure());
      }
    } else {
      yield put(autoRepliesSuccess(response));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(autoRepliesFailure({ non_field_errors: "Error" }));
  }
}

function* сreateAutoReplyWorker({ payload }) {
  const { data, callback } = payload;
  try {
    const response = yield call(makeRequest(service.createReply), data);

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: response?.message?.non_field_errors,
            type: "danger",
          })
        );
        yield put(createAutoReplyFailure());
      }
    } else {
      yield put(autoRepliesRequest());
      yield put(repliesRequest());
      yield put(createAutoReplySuccess());
      callback();
      yield put(
        addNotificationAction({ msg: "New reply was created", type: "success" })
      );
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(createAutoReplyFailure({ non_field_errors: "Error" }));
  }
}

function* updateAutoReplyWorker({ payload }) {
  const { data, uid, callback, fetchData } = payload;
  try {
    const response = yield call(makeRequest(service.updateAutoReply), {
      data,
      uid,
    });

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        for (const [key, value] of Object.entries(response.message)) {
          yield put(
            addNotificationAction({
              msg: `${key}: ${value}`,
              type: "danger",
            })
          );
        }
        yield put(updateAutoReplyFailure(uid));
      }
    } else {
      if (fetchData) {
        yield put(autoRepliesRequest());
        yield put(repliesRequest());
      }
      yield put(updateAutoReplySuccess(uid));
      if (callback) callback();
      yield put(
        addNotificationAction({ msg: "Reply was updated", type: "success" })
      );
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(updateAutoReplyFailure(uid));
  }
}

function* deleteReplyWorker({ payload }) {
  const { uid } = payload;

  try {
    const response = yield call(makeRequest(service.deleteAutoReply), uid);

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: response?.message?.non_field_errors,
            type: "danger",
          })
        );
        yield put(deleteAutoReplyFailure());
      }
    } else {
      yield put(autoRepliesRequest());
      yield put(deleteAutoReplySuccess());
      yield put(
        addNotificationAction({ msg: "Reply was deleted", type: "success" })
      );
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(deleteAutoReplyFailure({ non_field_errors: "Error" }));
  }
}

function* getRepliesWorker() {
  try {
    const response = yield call(makeRequest(service.fetchReplies));

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: response?.message?.non_field_errors,
            type: "danger",
          })
        );
        yield put(repliesFailure());
      }
    } else {
      yield put(repliesSuccess(response.results));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(repliesFailure({ non_field_errors: "Error" }));
  }
}

function* getShortcutsWorker() {
  try {
    const response = yield call(makeRequest(service.fetchShortcuts));

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: response?.message?.non_field_errors,
            type: "danger",
          })
        );
        yield put(shortcutsFailure());
      }
    } else {
      yield put(shortcutsSuccess(response));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(shortcutsFailure({ non_field_errors: "Error" }));
  }
}

// watchers

function* watchGetAutoReplies() {
  yield takeEvery(autoRepliesRequest, getAutoRepliesWorker);
}

function* watchCreateReply() {
  yield takeEvery(createAutoReplyRequest, сreateAutoReplyWorker);
}

function* watchUpdateReply() {
  yield takeEvery(updateAutoReplyRequest, updateAutoReplyWorker);
}

function* watchDeleteReply() {
  yield takeEvery(deleteAutoReplyRequest, deleteReplyWorker);
}

function* watchGetReplies() {
  yield takeEvery(repliesRequest, getRepliesWorker);
}

function* watchGetShortcuts() {
  yield takeEvery(shortcutsRequest, getShortcutsWorker);
}

export default function* platformsSaga() {
  yield all([
    fork(watchGetAutoReplies),
    fork(watchCreateReply),
    fork(watchUpdateReply),
    fork(watchDeleteReply),
    fork(watchGetReplies),
    fork(watchGetShortcuts),
  ]);
}
