import React from "react";
import PropTypes from "prop-types";

export default function ArrowDown(props) {
  return (
    <svg
      style={props.style}
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 19.93 21.63`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.63,0h-3.15h-4.23H8.67H3.41H1.29C0.58,0,0,0.58,0,1.29c0,0.71,0.58,1.29,1.29,1.29h0.56v0h9.4h5.98h1.4
	c0.72,0,1.29-0.58,1.29-1.29C19.93,0.58,19.35,0,18.63,0z"
      />
      <path
        d="M18.63,9.52h-3.15h-4.23H8.67H3.41H1.29C0.58,9.52,0,10.1,0,10.81c0,0.71,0.58,1.29,1.29,1.29h0.56v0h9.4h5.98h1.4
	c0.72,0,1.29-0.58,1.29-1.29C19.93,10.1,19.35,9.52,18.63,9.52z"
      />
      <path
        d="M18.63,19.04h-3.15h-4.23H8.67H3.41H1.29C0.58,19.04,0,19.62,0,20.33c0,0.71,0.58,1.29,1.29,1.29h0.56v0h9.4h5.98h1.4
	c0.72,0,1.29-0.58,1.29-1.29C19.93,19.62,19.35,19.04,18.63,19.04z"
      />
    </svg>
  );
}

ArrowDown.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowDown.defaultProps = {
  className: "",
  width: 11.98,
  height: 11.23,
};
