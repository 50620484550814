import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  channelsList: null,
  isChannelsRequestLoading: false,
  targetChannel: null,
  isTargetChannelRequestLoading: false,
  etsyConnectUrl: null,
  isEtsyConnectUrlLoading: false,
  etsyConnectUrlError: null,
  isEtsyCallbackLoading: false,
  isWooCommerceConnectLoading: false,
  isShopifyConnectUrlLoading: false,
  shopifyConnectUrl: null,
  isConnectFacebookLoading: false,
  facebookStatus: null,
  isConnectChannelLoading: false,
  isDisconnectChannelLoading: false,
  isEbayConnectUrlLoading: false,
  ebayConnectUrl: null,
  ebayConnectUrlError: null,
};

const platforms = createSlice({
  name: "platforms",
  initialState,
  reducers: {
    // Get Channels
    getChannelsRequest(state) {
      state.isChannelsRequestLoading = true;
    },
    getChannelsSuccess(state, { payload }) {
      state.channelsList = payload;
      state.isChannelsRequestLoading = false;
    },
    getChannelsFailure(state) {
      state.isChannelsRequestLoading = false;
      state.channelsList = [];
    },

    // Get One Channel
    getTargetChannelRequest(state) {
      state.isTargetChannelRequestLoading = true;
    },
    getTargetChannelSuccess(state, { payload }) {
      state.targetChannel = payload;
      state.isTargetChannelRequestLoading = false;
    },
    getTargetChannelFailure(state) {
      state.isTargetChannelRequestLoading = false;
    },

    // Remove (from storage) Selected Channel
    removeTargetChannel(state) {
      state.targetChannel = null;
    },

    // Connect Channel
    connectChannelRequest(state) {
      state.isConnectChannelLoading = true;
    },
    connectChannelSuccess(state) {
      state.isConnectChannelLoading = false;
    },
    connectChannelFailure(state) {
      state.isConnectChannelLoading = false;
    },

    // Disconnect Channel
    getDisconnectChannelRequest(state) {
      state.isDisconnectChannelLoading = true;
    },
    getDisconnectChannelSuccess(state) {
      state.isDisconnectChannelLoading = false;
    },
    getDisconnectChannelFailure(state) {
      state.isDisconnectChannelLoading = false;
    },

    // Get Esty Connect url
    getEstyConnectUrlRequest(state) {
      state.isEtsyConnectUrlLoading = true;
    },
    getEstyConnectUrlSuccess(state, { payload }) {
      state.etsyConnectUrl = payload;
      state.isEtsyConnectUrlLoading = false;
    },
    getEstyConnectUrlFailure(state) {
      state.isEtsyConnectUrlLoading = false;
    },
    // Esty Connect-url error
    setEstyConnectUrlError(state, { payload }) {
      state.etsyConnectUrlError = payload;
    },
    removeEstyConnectUrlError(state) {
      state.etsyConnectUrlError = null;
    },

    // Channel Esty CallBack
    fetchEstyCallbackRequest(state) {
      state.isEtsyCallbackLoading = true;
    },
    fetchEstyCallbackSuccess(state) {
      state.isEtsyCallbackLoading = false;
    },
    fetchEstyCallbackFailure(state) {
      state.isEtsyCallbackLoading = false;
    },

    // Channel WooCommerce Connect
    connectWooCommerceRequest(state, { payload }) {
      state.isWooCommerceConnectLoading = true;
    },
    connectWooCommerceSuccess(state, { payload }) {
      state.isWooCommerceConnectLoading = false;
    },
    connectWooCommerceFailure(state, { payload }) {
      state.isWooCommerceConnectLoading = false;
    },

    // Get Shopify Connect url
    getShopifyConnectUrlRequest(state) {
      state.isShopifyConnectUrlLoading = true;
    },
    getShopifyConnectUrlSuccess(state, { payload }) {
      state.isShopifyConnectUrlLoading = false;
      state.shopifyConnectUrl = payload;
    },
    getShopifyConnectUrlFailure(state) {
      state.isShopifyConnectUrlLoading = false;
    },
    // Remove Shopify Connect url
    removeShopifyConnectUrl(state) {
      state.shopifyConnectUrl = null;
    },

    // Channel Shopify CallBack
    fetchShopifyCallbackRequest(state) {},
    fetchShopifyCallbackSuccess(state) {},
    fetchShopifyCallbackFailure(state) {},

    // Channel Facebook Connect
    connectFacebookRequest(state) {
      state.isConnectFacebookLoading = true;
    },
    connectFacebookSuccess(state) {
      state.isConnectFacebookLoading = false;
    },
    connectFacebookFailure(state) {
      state.isConnectFacebookLoading = false;
    },

    // Channel Facebook check status
    statusFacebookRequest() {},
    statusFacebookSuccess(state, { payload }) {
      state.facebookStatus = payload;
    },
    statusFacebookFailure() {},

    // Remove Facebook status
    removeFacebookStatus(state) {
      state.facebookStatus = null;
    },

    // Get Ebay Connect url
    getEbayConnectUrlRequest(state) {
      state.isEbayConnectUrlLoading = true;
    },
    getEbayConnectUrlSuccess(state, { payload }) {
      state.ebayConnectUrl = payload;
      state.isEbayConnectUrlLoading = false;
    },
    getEbayConnectUrlFailure(state) {
      state.isEbayConnectUrlLoading = false;
    },
    // Ebay Connect-url error
    setEbayConnectUrlError(state, { payload }) {
      state.ebayConnectUrlError = payload;
    },
    removeEbayConnectUrlError(state) {
      state.ebayConnectUrlError = null;
    },

    // Channel Ebay CallBack
    fetchEbayCallbackRequest(state) {},
    fetchEbayCallbackSuccess(state) {},
    fetchEbayCallbackFailure(state) {},
  },
});

export default platforms;
