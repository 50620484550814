import React, { useRef } from "react";

import Chat from "./Chat/Chat";

import styles from "./chats.module.scss";
import helpers from "styles/helpers.module.scss";

import searchIcon from "assets/icons/search.png";
import closeIcon from "assets/icons/plus-icon.png";

const Chats = ({
  error,
  searchText,
  setSearchText,
  chats,
  chatsLoading,
  setActiveConvData,
  activeConvIndex,
  activeChatId,
  onSearchRequest,
  onClearSearch,
  searchResultList,
  searchLoading,
}) => {
  const timerId = useRef();

  const triggerSearchRequest = (search) => {
    clearTimeout(timerId.current);
    timerId.current = setTimeout(() => {
      if (search) {
        onSearchRequest(search);
        return;
      }
      onClearSearch(true);
    }, 500);
  };

  const inputChangeHandler = (e) => {
    setSearchText(() => {
      triggerSearchRequest(e.target.value);
      return e.target.value;
    });
  };

  return (
    <div className={styles.chatsContainer}>
      <div className={`${styles.searchBlock} ${helpers.contentRowLine}`}>
        <div className={styles.imgWrapper}>
          <img src={searchIcon} alt="search" />
        </div>
        <div className={styles.searchInput}>
          <input
            placeholder="Search"
            value={searchText}
            onChange={inputChangeHandler}
            type="text"
          />
        </div>
        {searchText && (
          <div onClick={onClearSearch} className={styles.closeBtn}>
            <img src={closeIcon} alt="close" />
          </div>
        )}
      </div>
      {chatsLoading ? (
        <div className={styles.emptyChats}>Loading conversations...</div>
      ) : error ? (
        <div
          className={styles.emptyChats}
        >{`Some server ERROR has happened: ${error}`}</div>
      ) : searchText ? (
        searchResultList?.length > 0 ? (
          searchResultList.map((chat, index) => (
            <Chat
              chat={chat}
              key={chat.id + index}
              index={index}
              setActiveConvData={setActiveConvData}
              activeConvIndex={activeConvIndex}
            />
          ))
        ) : searchLoading ? (
          <div className={styles.emptyChats}>Searching...</div>
        ) : (
          <div className={styles.emptyChats}>No search results</div>
        )
      ) : chats?.length && chats !== "Not Found" ? (
        chats.map((chat, index) => (
          <Chat
            chat={chat}
            key={chat.id}
            index={index}
            setActiveConvData={setActiveConvData}
            activeConvIndex={activeConvIndex}
            isChatActive={activeChatId === chat.id}
          />
        ))
      ) : (
        <div className={styles.emptyChats}>No available conversations</div>
      )}
    </div>
  );
};

export default React.memo(Chats);
