import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { history } from "router";
import * as routes from "router/routes";

// Reducers
import inventoryReducer from "store/entities/Inventory/inventory.reducer";
import headerReducer from "store/entities/Header/header.reducer";
import platformsReducer from "store/entities/Platforms/platforms.reducer";

// Selectors
import {
  selectorDeleteListingLoading,
  selectorPushListingLoading,
} from "./Selector";
import {
  selectorChannels,
  selectorChannelsLoading,
  selectorChannelsToPush,
} from "selectors/platformsSelectors";
import {
  selectorInventory,
  selectorCategoriesLoading,
  selectorFetchListingLoading,
} from "selectors/inventorySelectors";

// Components
import {
  ContentContainer,
  SearchInput as Search,
  Info,
} from "components/simple";
import InventoryTable from "./InventoryTable";
import PushToChannelModal from "./PushToChannelModal";
import SelectNewPlanModal from "./SelectNewPlanModal";
import DeleteModal from "./DeleteModal";
import AdditionalInfoModal from "./AdditionalInfoModal";
import ShippingModal from "./ShippingModal";

import styles from "./inventory.module.scss";

// Actions
const {
  actions: { getChannelsRequest },
} = platformsReducer;
const {
  actions: {
    updateListingRequest,
    fetchInventoryRequest,
    fetchCategoriesRequest,
    fetchEditingListingRequest,
    saveEditingListing,
    pushListingRequest,
    deleteListingRequest,
  },
} = inventoryReducer;
const {
  actions: { setHeaderTitle },
} = headerReducer;

function Inventory() {
  const initialStateModalsVisibility = {
    1: false,
    2: false,
    3: false,
    remove: false,
  };
  const [modalsVisibility, setModalsVisibility] = useState({
    ...initialStateModalsVisibility,
  });

  const [additionalInfoModalState, setAdditionalInfoModalState] = useState({});
  const [additionalInfoCategory, setAdditionalInfoCategory] = useState("");

  const [shippingModalState, setShippingModalState] = useState({});
  const [shippingType, setShippingType] = useState("local");

  const [search, setSearch] = useState("");

  const [showSelectNewPlanModal, setShowSelectNewPlanModal] = useState(false);

  const inventory = useSelector(selectorInventory);
  const inventoryList = inventory?.inventoryData;
  const categoriesList = inventory?.categoriesList;
  const editingListing = inventory?.editingListing;

  const isCategoriesLoading = useSelector(selectorCategoriesLoading);
  const channels = useSelector(selectorChannels);
  const isChannelsLoading = useSelector(selectorChannelsLoading);

  const channelsToPush = useSelector(selectorChannelsToPush);
  const deleteListLoading = useSelector(selectorDeleteListingLoading);
  const fetchListingLoading = useSelector(selectorFetchListingLoading);
  const isPushListingLoading = useSelector(selectorPushListingLoading);

  const dispatch = useDispatch();
  const timerRef = useRef();
  const { id: listingId, step: listingStep } = useParams();

  const triggerSearchRequest = (searchTerm) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (searchTerm) {
        dispatch(fetchInventoryRequest({ searchTerm }));
        return;
      }
      dispatch(fetchInventoryRequest());
    }, 500);
  };

  const handleSearch = (name, value) => {
    setSearch(() => {
      triggerSearchRequest(value);
      return value;
    });
  };

  useEffect(() => {
    dispatch(setHeaderTitle({ title: "Inventory" }));

    if (listingId && listingId !== editingListing?.id) {
      dispatch(fetchEditingListingRequest(listingId));
    }

    if (!categoriesList.length) dispatch(fetchCategoriesRequest());
    if (!channels) dispatch(getChannelsRequest());
    dispatch(fetchInventoryRequest());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { fields } = editingListing || {};

    const modalFields = fields?.reduce((acc, elem) => {
      return {
        ...acc,
        [elem.name_field_of_category]: {
          value: elem.value,
          fieldId: elem.field_of_category,
        },
      };
    }, {});

    setAdditionalInfoModalState({
      ...modalFields,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingListing, additionalInfoCategory]);

  useEffect(() => {
    const { category } = editingListing || {};
    setAdditionalInfoCategory(category);
  }, [editingListing]);

  useEffect(() => {
    if (listingId && listingId !== editingListing?.id) {
      const targetListing = inventoryList.find((l) => l.id === listingId);
      dispatch(saveEditingListing(targetListing));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingId]);

  useEffect(() => {
    if (listingStep === undefined) {
      setModalsVisibility({
        ...initialStateModalsVisibility,
      });
    } else {
      setModalsVisibility({
        ...modalsVisibility,
        [listingStep]: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingStep]);

  useEffect(() => {
    if (inventoryList.length) {
      if (listingId) {
        const targetListing = inventoryList.find((l) => l.id === listingId);
        dispatch(saveEditingListing(targetListing));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryList]);

  // Modals bottom controls
  const handleAdditionalInfoSubmit = (e, action) => {
    e.preventDefault();

    const modalFields = Object.entries(additionalInfoModalState).reduce(
      (acc, elem) => {
        if (elem) {
          return [
            ...acc,
            {
              name: elem[0],
              value: elem[1].value?.toString(),
              field_of_category: elem[1].fieldId,
            },
          ];
        } else {
          return acc;
        }
      },
      []
    );

    if (listingId) {
      dispatch(
        updateListingRequest({
          action,
          listingStep,
          status: editingListing?.status,
          listingId,
          images: [],
          fields: {
            ...editingListing,
            // category: additionalInfoCategory?.id,
            category: additionalInfoCategory?.id ? additionalInfoCategory?.id : additionalInfoCategory,
            fields: modalFields,
          },
        })
      );
    }
  };

  const handleShippingSubmit = (e, action) => {
    e.preventDefault();

    modalsVisibilityHandler(listingStep);

    // #TODO: add logic in next development milestone
    if (action) {
      // #TODO: move redirect to success submit saga
      history.push(
        `${routes.inventoryScreen}/${listingId}/${+listingStep + 1}`
      );
      return;
    }
    history.push(`${routes.inventoryScreen}`);
  };

  const handleBack = () => {
    // if first edit modal open => redirect to "/create-listing" screen
    if (+listingStep === 1) {
      history.push(`${routes.inventoryScreen}/${listingId}/edit`);
      return;
    }
    history.push(`${routes.inventoryScreen}/${listingId}/${+listingStep - 1}`);
  };

  const modalsVisibilityHandler = (step) => {
    setModalsVisibility({
      ...modalsVisibility,
      [step]: false,
    });
    history.push(routes.inventoryScreen);
  };
  //

  const onPushToChannelRequest = (platform) => {
    let data;
    switch (platform.toLowerCase()) {
      case "etsy":
        data = {
          shipping_template_id_on_etsy: listingId,
          who_made: "i_did",
          when_made: "2020_2021",
          listing: listingId,
        };
        dispatch(pushListingRequest({ data, platform }));
        break;

      case "ebay":
        data = {
          listing: listingId,
        };
        dispatch(pushListingRequest({ data, platform }));
        break;

      case "facebook":
        data = {
          condition: "New",
          listing: listingId,
        };
        dispatch(pushListingRequest({ data, platform }));
        break;

      case "shopify":
        data = {
          listing_id: listingId,
          handle: "Handle string",
          vendor: "wombatest", // shopName
          variant_weight: 10,
          variant_requires_shipping: true,
        };
        dispatch(pushListingRequest({ data, platform }));
        break;

      default:
        break;
    }
  };

  const onDeleteListing = (platform) => {
    dispatch(deleteListingRequest({ platform, uid: listingId }));
    // dispatch(deleteListingRequest({ platform, uid: openedModalListing.id }));
    // if (platform === "listacross") {
    //   setShowDeleteModal(false);
    // }
  };

  const categoriesOptions = categoriesList?.map((category) => ({
    value: category?.id,
    label: category.name,
  }));

  const setCategoryCallback = (_, value) => {
    let categoryById = categoriesList.find((item) => item.id === value)
    
    if (value !== additionalInfoCategory) {
      setAdditionalInfoCategory(value);
      // setAdditionalInfoCategory(categoryById);
    }
  };

  return (
    <div>
      <ContentContainer>
        <div className={styles.header}>
          <Search value={search} onChange={handleSearch} />
          <div className={styles.itemsCount}>
            <div>{inventoryList?.length} items</div>
            {/* <Info
              className={styles.infoIcon}
              title="Items"
              description="Lorem ipsum dolor sit amet, consectetur adipai scing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suei
              spendisse ultrices gravida. Lorem ipsum dolor sit amet, consectetur adipai scing elit."
            /> */}
          </div>
          <div></div>
          {/* <Button variant="secondary" onClick={() => console.log("click")}>
            <img
              className="mr-3"
              src={additionalInfoIcon}
              alt="additional info"
            />
            Filter Inventory
          </Button> */}
        </div>
        <div>
          <InventoryTable
            onSelectNewPlan={() => setShowSelectNewPlanModal(true)}
          />
        </div>

        <SelectNewPlanModal
          show={showSelectNewPlanModal}
          setShow={setShowSelectNewPlanModal}
        />
        <DeleteModal
          step={listingStep}
          show={modalsVisibility["remove"]}
          setShow={modalsVisibilityHandler}
          channelsList={editingListing?.channels}
          onDeleteListing={onDeleteListing}
          isDeleteLoading={deleteListLoading}
        />

        <AdditionalInfoModal
          step={listingStep}
          listingTitle={editingListing?.title}
          state={additionalInfoModalState}
          setState={setAdditionalInfoModalState}
          show={modalsVisibility[1]}
          setShow={modalsVisibilityHandler}
          channels={channels}
          categories={categoriesList}
          onSubmit={handleAdditionalInfoSubmit}
          onChangeCategory={setCategoryCallback}
          onBack={handleBack}
          categoriesOptions={categoriesOptions}
          value={additionalInfoCategory?.id}
          fetchListingLoading={fetchListingLoading}
          categoriesLoading={isCategoriesLoading}
        />

        <ShippingModal
          step={listingStep}
          show={modalsVisibility[2]}
          setShow={modalsVisibilityHandler}
          state={shippingModalState}
          setState={setShippingModalState}
          onSubmit={handleShippingSubmit}
          onBack={handleBack}
          type={shippingType}
          setType={setShippingType}
          channels={channels}
        />

        <PushToChannelModal
          step={listingStep}
          show={modalsVisibility[3]}
          setShow={modalsVisibilityHandler}
          channelsList={channelsToPush}
          isChannelsLoading={isChannelsLoading}
          alreadyPushedChannels={editingListing?.channels}
          pushListingRequest={onPushToChannelRequest}
          pushListingLoading={isPushListingLoading}
          fetchListingLoading={fetchListingLoading}
        />
      </ContentContainer>
    </div>
  );
}

Inventory.propTypes = {};

export default Inventory;
