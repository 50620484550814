import React from "react";
import PropTypes from "prop-types";

export default function Delete(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      fill={props.fill}
    >
      <g
        id="Component_14_5"
        data-name="Component 14 – 5"
        transform="translate(0.75 0.75)"
      >
        <g
          id="Group_157"
          data-name="Group 157"
          transform="translate(-1454.708 -236.023)"
        >
          <path
            id="Path_153"
            data-name="Path 153"
            d="M1454.708,239.412h19.871"
            fill="none"
            stroke={props.fill}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_154"
            data-name="Path 154"
            d="M1460.442,239.412v-3.389h8.4v3.389"
            fill="none"
            stroke={props.fill}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_155"
            data-name="Path 155"
            d="M1458.182,241.4v14.051h13.167V241.4"
            fill="none"
            stroke={props.fill}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_156"
            data-name="Path 156"
            d="M1466.76,252.061V241.414"
            fill="none"
            stroke={props.fill}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_157"
            data-name="Path 157"
            d="M1466.76,252.061V241.414"
            transform="translate(-4)"
            fill="none"
            stroke={props.fill}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}

Delete.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Delete.defaultProps = {
  className: "",
  width: 21.371,
  height: 20.926,
  fill: "#5f7fd3",
};
