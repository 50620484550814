import React from "react";
import PropTypes from "prop-types";

export default function Sort(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <image
        id="arrow"
        className="cls-1"
        width={props.width}
        height={props.height}
        xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAICAYAAAAvOAWIAAAAdUlEQVQYlY3PoQoCQRCA4U+Tj2zc91FQo6L1gskgGKwKBsGieHCIMnALq3Ccf9phvgk7SCmNMdLfc4j9HzDaBV7i2gPPWAduMMWrA9Z5Hzi6YNWBF7jFI+Noi+MPrHDIQ4nfmOPezidsyssSRw/M2oPJ1z/wARQxHD69p+gJAAAAAElFTkSuQmCC"
      />
    </svg>
  );
}

Sort.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Sort.defaultProps = {
  className: "",
  width: 11,
  height: 8,
  fill: "#BEBEBE",
};
