import React from "react";

import { SelectInput } from "components/simple";

import styles from "./stepSecond.module.scss";
import stepsBody from "../stepsBody.module.scss";

const StepSecond = ({ state, changeInputsHandler }) => {
  const onChangeHandler = (name, value) => {
    changeInputsHandler(name, value);
  };

  const triggers = {
    Last: "After buyer last message",
    First: "After buyer first message",
  };
  const triggersOptions = Object.entries(triggers).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const intervalValueOptions = Array.from({ length: 60 }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }));

  const intervalTypes = {
    Seconds: "Seconds",
    Minutes: "Minutes",
    Hours: "Hours",
  };
  const intervalTypeOptions = Object.entries(intervalTypes).map(
    ([key, value]) => ({
      value: key,
      label: value,
    })
  );

  return (
    <>
      <div className={stepsBody.stepTitle}>
        Choose When and How to Send The Message
      </div>
      <div className={styles.selectsContainer}>
        <SelectInput
          label={"When Should This Message Be Sent?"}
          name="trigger"
          value={state.trigger}
          onChange={onChangeHandler}
          options={triggersOptions}
        />

        <SelectInput
          label={"Amount"}
          name="intervalValue"
          value={state.intervalValue}
          onChange={onChangeHandler}
          options={intervalValueOptions}
        />

        <SelectInput
          label={"Timing"}
          name="intervalType"
          value={state.intervalType}
          onChange={onChangeHandler}
          options={intervalTypeOptions}
        />
      </div>
    </>
  );
};

export default StepSecond;
