import React from "react";

import { Button, Modal, ListingSteps } from "components/simple";

import styles from "./shippingModal.module.scss";

const DomesticShipping = ({ channels }) => {
  return (
    <div>
      <div className={styles.domesticTitle}>
        <div>Fill Delivery Information for Each Channel</div>
        <div>Specify Delivery Options for Each Channel</div>
      </div>
      <div className={styles.domesticContent}>
        {channels?.map((ch, i) => (
          <div key={i} className={styles.channel}>
            <div className={styles.logo}>
              <img src={ch.logo} alt="channel" />
            </div>
            <div>{ch.name}</div>
            <Button className={styles.btn} variant="secondary">
              Coming Soon
              {/* Setup Shipping */}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

const LocalShipping = () => {
  return <div></div>;
};

function ShippingModal({
  show,
  setShow,
  state,
  setState,
  channels,
  type,
  setType,
  onSubmit,
  onBack,
  onContinue,
  step,
}) {
  return (
    <Modal
      setShow={() => setShow(step)}
      show={show}
      containerStyles={styles.modalContainer}
      contentStyles={styles.modalContent}
    >
      <div>
        <div className={styles.title}>Delivery</div>
        <div className={styles.subtitle}>Select a Shipping Method</div>

        <div className={`${styles.controls} ${styles.top}`}>
          <Button
            onClick={() => setType("local")}
            className={`${styles.btn} ${type === "local" ? styles.active : ""}`}
            variant="secondary"
          >
            Free Shipping
          </Button>
          <Button
            onClick={() => setType("domestic")}
            className={`${styles.btn} ${
              type === "domestic" ? styles.active : ""
            }`}
            variant="secondary"
          >
            Domestic Shipping
          </Button>
        </div>

        <div className={styles.content}>
          {type === "local" ? (
            <LocalShipping />
          ) : (
            <DomesticShipping channels={channels} />
          )}
        </div>

        <ListingSteps
          step={step}
          onShowModal={setShow}
          onSaveClose={onSubmit}
          onBack={onBack}
        />
      </div>
    </Modal>
  );
}

export default ShippingModal;
