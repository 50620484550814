import React from "react";

import PlatformSwitcher from "./PlatformSwitcher/PlatformSwitcher";
import { Loader, SelectInput } from "components/simple";

import styles from "./stepThird.module.scss";
import stepsBody from "../stepsBody.module.scss";

const StepThird = ({
  state,
  setState,
  changeInputsHandler,

  selectedValue,
  handleSelectChange,
  channels,
  listingsOptions,
}) => {
  const removeValueHandler = (id, name) => {
    const clone = [...state[name]];
    const newArr = clone.filter((e) => e !== id);

    setState({
      ...state,
      [name]: newArr,
    });
  };

  const addValueHandler = (id, name) => {
    const newArr = [...state[name]];

    if (newArr.includes(id)) {
      return;
    } else {
      newArr.push(id);
    }

    setState({
      ...state,
      [name]: newArr,
    });
  };

  return (
    <>
      <div className={stepsBody.stepTitle}>Filters</div>
      <div className={styles.thirdStepWrapper}>
        <div className={styles.platformSwitchersWrapper}>
          <div className={styles.subtitle}>
            Select the channels where this message could be sent:
          </div>
          <div className={styles.switchersContainer}>
            {channels?.length > 0 ? (
              channels.map((channel) => (
                <PlatformSwitcher
                  key={channel.id}
                  channel={channel}
                  addHandler={addValueHandler}
                  removeHandler={removeValueHandler}
                />
              ))
            ) : channels?.length === 0 ? (
              <div style={{ textAlign: "center" }}>No data</div>
            ) : (
              <Loader />
            )}
          </div>
        </div>

        <div className={styles.listingsWrapper}>
          {/* <SelectInput
            label={"Select which listing triggers the automated message"}
            name="selectedListings"
            value={state.selectedListings}
            onChange={changeInputsHandler}
            options={listingsOptions}
          /> */}
          <SelectInput
            isMulti
            label={"Select which listing triggers the automated message"}
            name="selectedListings"
            value={listingsOptions.filter((obj) =>
              selectedValue.includes(obj.label)
            )}
            onChange={handleSelectChange}
            options={listingsOptions}
          />
        </div>
      </div>
    </>
  );
};

export default StepThird;
