import React from "react";
import PropTypes from "prop-types";

import DataTable from "react-data-table-component";
import NoDataComponent from "./NoDataComponent";

import customStyles from "./styles";

export default function TableComponent({
  columns,
  data,
  expandableRows,
  loading,
  pagination,
  paginationServer,
  totalRows,
  handlePageChange,
  expandableIcon,
  rendererExpandRow,
  isLoading,
  expandedRows,
  ...rest
}) {
  return (
    <DataTable
      style={{ display: "flex" }}
      {...rest}
      noHeader
      columns={columns}
      data={data}
      customStyles={customStyles}
      // expandableRows={expandableRows}
      // expandableRowsComponent={<RowExpandComponent rendererExpandRow={rendererExpandRow} />}
      // expandableRowExpanded={a => expandedRows.findIndex(row => row === a?.id) > -1}
      progressPending={loading}
      // pagination={pagination}
      // paginationServer={paginationServer}
      // paginationTotalRows={totalRows}
      // onChangePage={handlePageChange}
      // paginationComponentOptions={{
      //   noRowsPerPage: true
      // }}
      // paginationComponent={renderPaginaniton}
      noDataComponent={<NoDataComponent isLoading={isLoading} />}
      {...(expandableIcon && { expandableIcon })}
    />
  );
}

TableComponent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  expandableIcon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.any]),
  expandableRows: PropTypes.bool,
  loading: PropTypes.bool,
  pagination: PropTypes.bool,
  paginationServer: PropTypes.bool,
  totalRows: PropTypes.number,
  handlePageChange: PropTypes.func,
  rendererExpandRow: PropTypes.func,
  expandedRows: PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool,
};

TableComponent.defaultProps = {
  data: [],
  columns: [],
  totalRows: 0,
  handlePageChange: () => {},
  expandableRows: false,
  loading: false,
  pagination: false,
  paginationServer: false,
  expandableIcon: null,
  rendererExpandRow: () => {},
  expandedRows: [],
  isLoading: false,
};
