import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorProfile,
  selectorHeaderTitle,
  selectorHeaderTitleBtn,
} from "./Selector";

import { routes } from "router";

import usersReducer from "store/entities/Users/users.reducer";

import { Link } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import Button from "components/simple/Button";

import styles from "./navbar.module.scss";

const {
  actions: { logoutRequest },
} = usersReducer;

function Navbar({ title }) {
  const dispatch = useDispatch();

  const userProfile = useSelector(selectorProfile);
  const useTitle = useSelector(selectorHeaderTitle);
  const useTitleBtnBack = useSelector(selectorHeaderTitleBtn);

  const profileDropdownItems = [
    { title: "Profile", to: "/profile" },
    {
      title: "Sign Out",
      onClick: () => {
        dispatch(logoutRequest());
      },
    },
  ];

  return (
    <div className={styles.navbar}>
      <div className={styles.titleContainer}>
        {useTitleBtnBack && (
          <Link to={`/${useTitleBtnBack}`}>
            <Button variant="round" className={styles.btnBack} />
          </Link>
        )}

        <div className={styles.title}>{useTitle}</div>
      </div>
      <div className={styles.profile}>
        <div className={styles.avatar}>
          {userProfile?.photo ? (
            <img
              className={styles.avatarImage}
              src={`${process.env.REACT_APP_API_URL}${userProfile?.photo}`}
              alt="user avatar"
            />
          ) : null}
        </div>
        <div className={styles.name}>
          <Link to={routes.myProfileScreen}>
            {userProfile?.user?.first_name} {userProfile?.user?.last_name}
          </Link>
        </div>
        {/* // TODO: Replace with profile dropdown component */}
        {/* <Button variant="transparent" className={styles.profileButton}>
          ...
        </Button>
        <Button
          onClick={() => {
            dispatch(logoutRequest());
          }}
        >
          Logout
        </Button> */}
        <ProfileDropdown items={profileDropdownItems} />
      </div>
    </div>
  );
}

Navbar.propTypes = {
  title: PropTypes.string,
};

Navbar.defaultProps = {
  title: "",
};

export default Navbar;
