const validatePassword = (value, regEx, requiredMessage) => {
  const minLengthMessage = "Password must be at least 8 characters long";
  const maxLengthMessage = "Password max length is 16 characters";
  const wrongValueMessage =
    "Must contain at least one number and special symbol (@#$%^&*)";

  if (!value) {
    return { password: requiredMessage };
  } else if (value.length < 8) {
    return { password: minLengthMessage };
  } else if (value.length > 16) {
    return { password: maxLengthMessage };
  } else if (!regEx.test(value)) {
    return { password: wrongValueMessage };
  }
};

const validateName = (
  key,
  value,
  regEx,
  requiredMessage,
  wrongValueMessage
) => {
  if (!value) {
    return { [key]: requiredMessage };
  } else if (!regEx.test(value)) {
    return { [key]: wrongValueMessage };
  }
};

const validateEmail = (value, regEx, requiredMessage, wrongValueMessage) => {
  if (!value) {
    return { email: requiredMessage };
  } else if (!regEx.test(value)) {
    return { email: wrongValueMessage };
  }
};

const validatePhone = (
  key,
  value,
  regEx,
  requiredMessage,
  wrongValueMessage
) => {
  if (!value) {
    return { [key]: requiredMessage };
  } else if (!regEx.test(value)) {
    return { [key]: wrongValueMessage };
  }
};

const validateAddress = (key, value, requiredMessage) => {
  if (!value) {
    return { [key]: requiredMessage };
  }
};

const validateZipCode = (value, regEx, requiredMessage, wrongValueMessage) => {
  if (!value) {
    return { zipCode: requiredMessage };
  } else if (!regEx.test(value)) {
    return { zipCode: wrongValueMessage };
  }
};

const validateCity = (value, regEx, requiredMessage, wrongValueMessage) => {
  if (!value) {
    return { city: requiredMessage };
  } else if (!regEx.test(value)) {
    return { city: wrongValueMessage };
  }
};

const validateState = (value, regEx, requiredMessage, wrongValueMessage) => {
  if (!value) {
    return { state: requiredMessage };
  } else if (!regEx.test(value)) {
    return { state: wrongValueMessage };
  }
};

const validateTitle = (value, maxValue, requiredMessage, wrongValueMessage) => {
  if (!value) {
    return { listingTitle: requiredMessage };
  } else if (value.length > maxValue) {
    return { listingTitle: wrongValueMessage };
  }
};

const validatePrice = (value, regEx, requiredMessage, wrongValueMessage) => {
  if (!value) {
    return { price: requiredMessage };
  } else if (!regEx.test(value)) {
    return { price: wrongValueMessage };
  }
};

const validateDescription = (
  value,
  maxValue,
  requiredMessage,
  wrongValueMessage
) => {
  if (!value) {
    return { listingDescription: requiredMessage };
  } else if (value.length > maxValue) {
    return { listingDescription: wrongValueMessage };
  }
};

const validateCategory = (value, _, requiredMessage) => {
  if (!value) {
    return { category: requiredMessage };
  }
};

const validateLocation = (key, value, _, requiredMessage) => {
  if (!value) {
    return { [key]: requiredMessage };
  }
};

const onSetError = (key, value) => {
  let errors = {};

  const passwordRegex = /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,16}$/;
  // const phoneRegex = /^((\+1) \([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
  const phoneRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
  const numbersRegex = /^\d+$/;
  const priceRegex = /^[0-9]*\.?[0-9]*$/;
  const lettersRegex = /^[a-zA-Z-\s]+$/;
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const maxCharsTitle = 80;
  const maxCharsDescription = 4000;

  const requiredMessage = "This field is required";
  const lettersMessage = "Must contain only letters";
  const numbersMessage = "Must contain only numbers";
  const notValidMessage = `Not valid ${key}`;
  const titleMaxNumExceededMessage = `Characters maximum quantity (${maxCharsTitle}) exceeded`;
  const descriptionMaxNumExceededMessage = `Characters maximum quantity (${maxCharsDescription}) exceeded`;

  const textField = key === "firstName" ? key : key === "lastName" ? key : "";
  const addressField = key === "address1" ? key : key === "address2" ? key : "";

  switch (key) {
    case "password":
      errors = {
        ...errors,
        ...validatePassword(value, passwordRegex, requiredMessage),
      };
      break;

    case textField:
      errors = {
        ...errors,
        ...validateName(
          key,
          value,
          lettersRegex,
          requiredMessage,
          lettersMessage
        ),
      };
      break;

    case "email":
      errors = {
        ...errors,
        ...validateEmail(value, emailRegex, requiredMessage, notValidMessage),
      };
      break;

    case "phone":
      errors = {
        ...errors,
        ...validatePhone(
          key,
          value,
          phoneRegex,
          requiredMessage,
          notValidMessage
        ),
      };
      break;

    case addressField:
      errors = {
        ...errors,
        ...validateAddress(key, value, requiredMessage),
      };
      break;

    case "zipCode":
      errors = {
        ...errors,
        ...validateZipCode(
          value,
          numbersRegex,
          requiredMessage,
          numbersMessage
        ),
      };
      break;
    case "city":
      errors = {
        ...errors,
        ...validateCity(value, lettersRegex, requiredMessage, lettersMessage),
      };
      break;
    case "state":
      errors = {
        ...errors,
        ...validateState(value, lettersRegex, requiredMessage, lettersMessage),
      };
      break;
    case "listingTitle":
      errors = {
        ...errors,
        ...validateTitle(
          value,
          maxCharsTitle,
          requiredMessage,
          titleMaxNumExceededMessage
        ),
      };
      break;

    case "listingDescription":
      errors = {
        ...errors,
        ...validateDescription(
          value,
          maxCharsDescription,
          requiredMessage,
          descriptionMaxNumExceededMessage
        ),
      };
      break;

    case "price":
      errors = {
        ...errors,
        ...validatePrice(value, priceRegex, requiredMessage, numbersMessage),
      };
      break;

    case "category":
      errors = {
        ...errors,
        ...validateCategory(value, null, requiredMessage),
      };
      break;

    case "location":
      errors = {
        ...errors,
        ...validateLocation(key, value, null, requiredMessage),
      };
      break;

    // case "sku":
    //   errors = {
    //     ...errors,
    //     ...validateLocation(key, value, null, requiredMessage),
    //   };
    //   break;

    default:
      break;
  }

  return errors;
};

export function useValidate() {
  return function (key, value, errorsState, setErrorsState) {
    const error = onSetError(key, value);

    // set new error
    if (error.hasOwnProperty(key)) {
      setErrorsState({
        ...errorsState,
        ...error,
      });
      return true;
    }

    // remove previous error
    if (errorsState.hasOwnProperty(key)) {
      const newState = { ...errorsState };
      delete newState[key];
      setErrorsState(newState);
    }
  };
}
