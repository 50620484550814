import React from "react";

import { Button, Modal, Input } from "components/simple";
import BootstrapModal from "react-bootstrap/Modal";

import modalStyles from "../modalAddAddress.module.scss";
import helpers from "styles/helpers.module.scss";

function AddAddressModal({
  state,
  validate,
  errors,
  setErrors,
  isAddressPopupShown,
  closeModalHandler,
  modalInputsChangeHandler,
  submitHandler,
}) {
  return (
    <div>
      <Modal
        setShow={closeModalHandler}
        show={isAddressPopupShown}
        containerStyles={modalStyles.modalContainer}
        contentStyles={modalStyles.modalContent}
      >
        <BootstrapModal.Header
          bsPrefix={`${modalStyles.header} ${helpers.contentRowLine}`}
        >
          Add New Address
        </BootstrapModal.Header>
        <BootstrapModal.Body bsPrefix={modalStyles.modalBody}>
          <Input
            name="address1"
            label="Street Address"
            value={state.address1}
            onChange={modalInputsChangeHandler}
            onBlur={() =>
              validate("address1", state.address1, errors, setErrors)
            }
            error={errors.address1}
            type="text"
          />

          <Input
            name="address2"
            label="Apt. / Suite / Floor"
            value={state.address2}
            onChange={modalInputsChangeHandler}
            onBlur={() =>
              validate("address2", state.address2, errors, setErrors)
            }
            error={errors.address2}
            type="text"
          />

          <Input
            name="city"
            label="City"
            value={state.city}
            onChange={modalInputsChangeHandler}
            onBlur={() => validate("city", state.city, errors, setErrors)}
            error={errors.city}
            type="text"
          />

          <div className={modalStyles.halfInputs}>
            <Input
              name="state"
              label="State"
              value={state.state}
              onChange={modalInputsChangeHandler}
              onBlur={() => validate("state", state.state, errors, setErrors)}
              error={errors.state}
              type="text"
            />

            <Input
              name="zipCode"
              label="Zip Code"
              value={state.zipCode}
              onChange={modalInputsChangeHandler}
              onBlur={() =>
                validate("zipCode", state.zipCode, errors, setErrors)
              }
              error={errors.zipCode}
              type="text"
            />
          </div>
          <div className={modalStyles.btnsBlock}>
            <Button onClick={submitHandler} className={modalStyles.saveBtn}>
              Save Address
            </Button>
            <Button
              onClick={closeModalHandler}
              variant="transparent"
              className={modalStyles.cancelBtn}
            >
              Cancel
            </Button>
          </div>
        </BootstrapModal.Body>
      </Modal>
    </div>
  );
}

export default AddAddressModal;
