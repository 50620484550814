import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import notificationsReducer from "store/entities/Notification/notifications.reducer";

import { Modal as BootstrapModal, Form } from "react-bootstrap";
import {
  Button,
  Modal,
  SelectInput,
  Input,
  InputColor,
  Info,
  ListingSteps,
  Loader,
  CheckBox,
  InputTags,
} from "components/simple";

import styles from "./modalAddtionalInfo.module.scss";
import helpers from "styles/helpers.module.scss";

import SVG from "components/SVG";
// import { Form } from "react-bootstrap";

import { useLocation } from "react-router-dom";

export default function AdditionalInfoModal({
  listingTitle,
  state,
  setState,
  show,
  setShow,
  categories,
  channels,
  onSubmit,
  onBack,
  onChangeCategory,
  categoriesOptions,
  value,
  step,
  fetchListingLoading,
  categoriesLoading,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    actions: { addNotification: addNotificationAction },
  } = notificationsReducer;

  const [fields, setFields] = useState([]);
  const [errors, setErrors] = useState([]);
  const [channelColors, setChannelColors] = useState({});

  const getTargetCategory = (uid) => {
    if (uid) return categories?.find((c) => c.id.toString() === uid.toString());
  };

  const clearFields = () => {
    setState({});
  };

  useEffect(() => {
    if (channels) {
      const platformsColors = channels.reduce((acc, p) => {
        return {
          ...acc,
          [p.name.toLowerCase()]: p.channel_color,
        };
      }, {});
      setChannelColors(platformsColors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels]);

  useEffect(() => {
    if (categories) {
      const targetCategory = getTargetCategory(value);
      setFields(targetCategory?.fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    if (location?.state?.message) {
      dispatch(
        addNotificationAction({
          msg: location?.state?.message,
          type: "danger",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (value) {
      const targetCategory = getTargetCategory(value);
      setFields(targetCategory?.fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const selectCategoryHandler = (name, categoryValue) => {
    onChangeCategory(name, categoryValue);
    const targetCategory = getTargetCategory(categoryValue);
    if (value !== categoryValue) {
      setFields(targetCategory?.fields);
      clearFields();
    }
  };

  const inputChangeHandler = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const isFieldRequired = (field) =>
    field?.ebay === "Required" ||
    field?.etsy === "Required" ||
    field?.facebook === "Required" ||
    field?.shopify === "Required";

  const handleSubmit = (e, action) => {
    e.preventDefault();

    const validationErrors = {};
    fields.forEach((field) => {
      const key = field.name;

      if (isFieldRequired(field) && !state[key]?.value?.length) {
        validationErrors[key] = "This field is required";
      }
    });

    if (!Object.keys(validationErrors)?.length) {
      // TODO: Validate fields
      // TODO: if valid {

      onSubmit(e, action);
      setShow(step);
      clearFields();
    } else {
      setErrors(validationErrors);
      dispatch(
        addNotificationAction({
          msg: (() => {
            return (
              <>
                {Object.keys(validationErrors).map((key) => (
                  <p>{`${key} - ${validationErrors[key]}${";\n"}`}</p>
                ))}
              </>
            );
          })(),
          type: "warning",
        })
      );
    }
    // TODO: };
  };

  const renderFieldPlatforms = (platform, value, index) => {
    switch (value.trim()) {
      case "Required":
        return (
          <div
            key={index}
            className={`${styles.platform} ${styles.required}`}
            style={{ backgroundColor: `${channelColors[platform]}` }}
          ></div>
        );

      case "Optional":
        return (
          <div
            key={index}
            className={`${styles.platform} ${styles.optional}`}
            style={{
              border: `3px solid ${channelColors[platform]}`,
            }}
          ></div>
        );

      case "Not supported":
        return (
          <div
            key={index}
            className={`${styles.platform} ${styles.notAllowed}`}
          >
            <SVG.CloseX
              height={11.5}
              width={11.5}
              fill={channelColors[platform]}
            />
          </div>
        );

      case "":
        return (
          <div
            key={index}
            className={`${styles.platform} ${styles.notAppear}`}
            style={{ border: `1.5px solid ${channelColors[platform]}` }}
          ></div>
        );

      default:
        break;
    }
  };

  const getTooltip = (categoryId, fieldName) => {
    if (categoryId === "b6964d67-562a-4427-b5c5-2add935a1b02" && fieldName === "Department") {
      return "Gender and/or age grouping characterized by the category";
    }
    if (categoryId === "b6964d67-562a-4427-b5c5-2add935a1b02" && fieldName === "Type") {
      return "Specific type of product that is being sold in the product listing";
    }
  }

  return (
    <div>
      <Modal
        setShow={() => setShow(step)}
        show={show}
        containerStyles={styles.modalContainer}
        contentStyles={styles.modalContent}
      >
        <BootstrapModal.Header
          bsPrefix={`${styles.header} ${helpers.contentRowLine}`}
        >
          <div className={styles.titleBlock}>
            <div className={styles.title}>
              Channel-Specific Additional Information
            </div>
            <div className={styles.subtitle}>
              Required and Optional Fields for Certain Channels
            </div>
          </div>
          <Button
            className={styles.closeBtn}
            variant="round"
            onClick={() => setShow(step)}
          >
            <SVG.CloseX
              className={styles.img}
              width={17}
              height={17}
              fill={"#959595"}
            />
          </Button>
        </BootstrapModal.Header>
        {fetchListingLoading || categoriesLoading ? (
          <Loader />
        ) : (
          <BootstrapModal.Body bsPrefix={styles.modalBody}>
            <div className={`${styles.platformsRow} ${helpers.contentRowLine}`}>
              <div className={styles.infoContainer}>
                <div>Legend</div>
                <Info title="Information" placement="right" width="550px">
                  <div className={styles.legendContainer}>
                    <div className={styles.legendBlock}>
                      <div
                        className={`${styles.legend} ${styles.legendRequired}`}
                      ></div>
                      <div className={styles.legendText}>
                        Required field on channel
                      </div>
                    </div>

                    <div className={styles.legendBlock}>
                      <div
                        className={`${styles.legend} ${styles.legendOptional}`}
                      ></div>
                      <div className={styles.legendText}>
                        Available on channel, but not required
                      </div>
                    </div>

                    <div className={styles.legendBlock}>
                      <div
                        className={`${styles.legend} ${styles.legendNotAppear}`}
                      ></div>
                      <div className={styles.legendText}>
                      Does not appear on channel
                      </div>
                    </div>

                    <div className={styles.legendBlock}>
                      <div
                        className={`${styles.legend} ${styles.legendNotAllowed}`}
                      >
                        <SVG.CloseX height={15} width={15} />
                      </div>
                      <div className={styles.legendText}>
                        This category cannot be published
                      </div>
                    </div>
                  </div>
                    <div className={`${styles.legendText} ${styles.legendCategoryFieldsText}`}>Some channels require fields that do not appear applicable. For example, eBay adds “Language” and “Book Title” as required fields for the Jewelry category. If you see this, feel free to enter any information you like in those fields.</div>
                </Info>
              </div>
              <div className={styles.platformsContainer}>
                {channels?.map((ch) => (
                  <div key={ch.id} className={styles.platform}>
                    <div
                      className={styles.platformColor}
                      style={{ backgroundColor: ch.channel_color }}
                    ></div>
                    <div className={styles.platformName}>{ch.name}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className={`${styles.listingBlock} ${helpers.contentRowLine}`}>
              <div className={styles.listingName}>
                {listingTitle ? listingTitle : "Listing Title"}
              </div>
              <Button variant="secondary" className={`${styles.selectBtn}`}>
                <SelectInput
                  name="category"
                  placeholder="Choose Category"
                  value={value}
                  onChange={selectCategoryHandler}
                  options={categoriesOptions}
                  containerClass={styles.select}
                  width="120%"
                  left="-10%"
                  required
                />
              </Button>
            </div>

            <div className={styles.characteristicsContainer}>
              {fields?.map((field, i) => {
                const {
                  platformsRequirements,
                  options,
                  type,
                  id,
                  ...platforms
                } = field;

                const mapSelectOptions = (options) => {
                  return options.map((option) => ({
                    label: option.display_label,
                    value: option.label,
                  }));
                };

                const renderField = (field) => {
                  const { type, name } = field;
                  let targetCategory = getTargetCategory(value)

                  switch (type) {
                    case "Text": {
                      return (
                        <Input
                          name={name}
                          type="text"
                          value={state[name]?.value}
                          label={name}
                          onChange={(name, value) =>
                            inputChangeHandler(name, { value, fieldId: id })
                          }
                          required={isFieldRequired(field)}
                          tooltipTitle={getTooltip(targetCategory?.id, name)}
                        />
                      );
                    }
                    case "Select": {
                      return (
                        <SelectInput
                          name={name}
                          // placeholder={name}
                          label={name}
                          value={state[name]?.value}
                          onChange={(name, value) =>
                            inputChangeHandler(name, { value, fieldId: id })
                          }
                          options={mapSelectOptions(field.options)}
                          containerClass={styles.select}
                          required={isFieldRequired(field)}
                        />
                      );
                    }
                    case "Color": {
                      return (
                        <InputColor
                          name={name}
                          type="text"
                          value={state[name]?.value}
                          label={name}
                          onChange={(name, value) =>
                            inputChangeHandler(name, { value, fieldId: id })
                          }
                          required={isFieldRequired(field)}
                        />
                      );
                    }
                    case "Array": {
                      return (
                        <InputTags
                          name={name}
                          type="text"
                          value={
                            (state[name]?.value?.length &&
                              state[name]?.value?.split(",")) ||
                            []
                          }
                          label={name}
                          onChange={(name, value) => {
                            inputChangeHandler(name, { value, fieldId: id });
                          }}
                          required={isFieldRequired(field)}
                        />
                      );
                    }
                    case "Bool": {
                      return (
                        <CheckBox
                          name={name}
                          value={state[name]?.value === "true" ? true : false}
                          label={name}
                          onChange={(name, value) =>
                            inputChangeHandler(name, {
                              value: value?.toString(),
                              fieldId: id,
                            })
                          }
                          required={isFieldRequired(field)}
                        />
                      );
                    }
                    case "Number": {
                      return (
                        <Input
                          name={name}
                          type="number"
                          min={1}
                          value={state[name]?.value}
                          label={name}
                          onChange={(name, value) =>
                            inputChangeHandler(name, { value, fieldId: id })
                          }
                          tooltipTitle="Only numbers allowed"
                          required={isFieldRequired(field)}
                        />
                      );
                    }
                    default: {
                      return null;
                    }
                  }
                };

                return (
                  <div key={i} className={styles.fieldContainer}>
                    {renderField(field)}

                    <div className={styles.supportContainer}>
                      {Object.entries(platforms).map(([p, v], i) =>
                        renderFieldPlatforms(p, v, i)
                      )}
                      {/* {Object.entries(platforms).map(([p, v], i) => (
                        <div
                          key={i}
                          className={`${styles.platform}`}
                          style={{ backgroundColor: `${channelColors[p]}` }}
                        ></div>
                      ))} */}

                      {platformsRequirements?.length
                        ? platformsRequirements.map((p, i) => (
                            <div
                              key={i}
                              className={`${styles.platform}`}
                              style={{
                                boxShadow:
                                  p.isRequired === "optional"
                                    ? `0 0 0 3px inset ${channelColors[p.name]}`
                                    : "none",
                                backgroundColor: `${
                                  p.isRequired === "required"
                                    ? channelColors[p.name]
                                    : "transparent"
                                }`,
                              }}
                            ></div>
                          ))
                        : null}
                    </div>
                  </div>
                );
              })}
            </div>

            <ListingSteps
              step={step}
              onBack={onBack}
              onSaveClose={handleSubmit}
            />
          </BootstrapModal.Body>
        )}
      </Modal>
    </div>
  );
}
