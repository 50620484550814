import { createSelector } from "reselect";

const isListingBulkUploadLoadingSelector = (state) =>
  state.inventory.isListingBulkUploadLoading;

export const selectorIsListingBulkUploadLoading = createSelector(
  isListingBulkUploadLoadingSelector,
  (items) => items
);

// Channels with import
const channlesSelector = (state) => state.platforms.channelsList;
export const selectorChannelsWithImport = createSelector(
  channlesSelector,
  (channels) => channels?.filter((ch) => ch.import_listings)
);
