import { API, authorization, contentTypeJson } from "api/api";
import { get, post, remove } from "api/base";

export const fetchChannels = () => {
  return get(`${API}/channels/`, { ...authorization() });
};

export const fetchTargetChannel = (uid) => {
  return get(`${API}/channels/${uid}`, { ...authorization() });
};

export const disconnectEtsy = () => {
  return post(`${API}/integrations/etsy/disconnect`, null, {
    ...authorization(),
  });
};

export const fetchEtsyConnectUrl = () => {
  return get(`${API}/integrations/etsy/connect`, { ...authorization() });
};

export const fetchEtsyConnectCallback = (credentials) => {
  return get(`${API}/integrations/etsy/callback${credentials}`, {
    ...authorization(),
  });
};

export const postEtsyInboxConnect = (credentials) => {
  return post(
    `${API}/inbox/etsy-inbox/connect_to_etsy_inbox`,
    JSON.stringify(credentials),
    {
      ...authorization(),
      ...contentTypeJson(),
    }
  );
};

export const connectWooCommerce = ({ url, consumerKey, consumerSecret }) => {
  return post(
    `${API}/integrations/woocommerce/setup`,
    JSON.stringify({
      url,
      consumer_key: consumerKey,
      consumer_secret: consumerSecret,
    }),
    {
      ...authorization(),
      ...contentTypeJson(),
    }
  );
};

export const disconnectWooCommerce = () => {
  return remove(`${API}/integrations/woocommerce/setup`, {
    ...authorization(),
  });
};

export const fetchShopifyConnectUrl = (shopName) => {
  return post(`${API}/integrations/shopify/connect`, JSON.stringify(shopName), {
    ...authorization(),
    ...contentTypeJson(),
  });
};

export const fetchShopifyConnectCallback = (credentials) => {
  return post(
    `${API}/integrations/shopify/access-token`,
    JSON.stringify(credentials),
    {
      ...authorization(),
      ...contentTypeJson(),
    }
  );
};

export const disconnectShopify = () => {
  return remove(`${API}/integrations/shopify/logout`, {
    ...authorization(),
  });
};

export const connectFacebook = (credentials) => {
  return post(
    `${API}/integrations/facebook/connect`,
    JSON.stringify(credentials),
    {
      ...authorization(),
      ...contentTypeJson(),
    }
  );
};

export const fetchFacebookStatus = () => {
  return get(`${API}/integrations/facebook/status`, {
    ...authorization(),
  });
};

export const disconnectFacebook = () => {
  return remove(`${API}/integrations/facebook/disconnect`, {
    ...authorization(),
  });
};

export const fetchEbayConnectUrl = () => {
  return get(`${API}/integrations/ebay/user-url`, {
    ...authorization(),
  });
};

export const fetchEbayConnectCallback = (credentials) => {
  return get(`${API}/integrations/ebay/user-consent${credentials}`, {
    ...authorization(),
  });
};

export const disconnectEbay = () => {
  return remove(`${API}/integrations/ebay/disconnect`, {
    ...authorization(),
  });
};
