import React from "react";
// import PropTypes from "prop-types";

import * as routes from "router/routes";

import { Route } from "react-router-dom";
import Login from "../Login";
import Signup from "../SignUp";
import ResetPassword from "../ResetPassword";
import RestorePassword from "../RestorePassword";

import styles from "./auth-container.module.scss";

function AuthContainer(props) {
  return (
    <div className={styles["auth-background"]}>
      <div className={styles["auth-container"]}>
        <div className={styles["form-container"]}>
          <Route exact component={Login} path={routes.loginScreen} />
          <Route
            exact
            component={Login}
            path={`${routes.loginScreen}/:token`}
          />
          <Route component={Signup} path={routes.signupScreen} />
          <Route
            exact
            path={routes.resetPasswordScreen}
            component={ResetPassword}
          />
          <Route
            exact
            path={`${routes.chooseNewPasswordScreen}/:uid/:token`}
            component={RestorePassword}
          />
        </div>
      </div>
    </div>
  );
}

AuthContainer.propTypes = {};

export default AuthContainer;
