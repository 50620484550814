import React from "react";
import PropTypes from "prop-types";

import { Loader } from "components/simple";

import styles from "./styles.module.scss";

export default function NoDataComponent({ isLoading }) {
  return isLoading ? <Loader variant="dark" /> : <div className={styles.container}>No data</div>;
}

NoDataComponent.propTypes = {
  isLoading: PropTypes.bool
};

NoDataComponent.defaultProps = {
  isLoading: false
};
