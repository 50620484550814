import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import {
  selectorIsListingBulkUploadLoading,
  selectorChannelsWithImport,
} from "./Selector";
import { slectorImportLoading } from "selectors/inventorySelectors";

import inventoryReducer from "store/entities/Inventory/inventory.reducer";
import platformsReducer from "store/entities/Platforms/platforms.reducer";

import * as routes from "router/routes";

import { Button, Loader } from "components/simple";
import SidebarDateTime from "./SidebarDateTime";
import ImportListingsModal from "./ImportListingsModal/index";

import styles from "./sidebar.module.scss";

import SVG from "components/SVG";

import createListingIcon from "assets/icons/create-listing-icon.png";
import fileIcon from "assets/icons/file-icon.png";
import importIcon from "assets/icons/import-icon.png";

const {
  actions: { createListingBulkImportRequest, importListingsRequest },
} = inventoryReducer;

const {
  actions: { getChannelsRequest },
} = platformsReducer;

const referFriendsCallback = () => {
  // const subject = "Lisatcross invitation";
  // const body = "Hello! I want to invite you to this wonderful platform";
  // window.location.href = `mailto:any_user_here@example.com?subject=${subject}&body=${body}`;
  window.location.href = "https://listacross.com/refer";
};

const getSupportCallback = () => {
  window.open("https://listacross.freshdesk.com/support/home", "_blank");
};

const linksList = [
  {
    path: routes.dashboardScreen,
    title: "Dashboard",
    icon: (
      <SVG.Dashboard
        height={19}
        width={19}
        style={{ transform: "rotate(180deg)" }}
      />
    ),
    exact: true,
  },
  {
    path: routes.integrationsScreen,
    title: "Integrations",
    icon: <SVG.Integrations height={19} width={19} />,
    exact: false,
  },
  {
    path: routes.inventoryScreen,
    title: "Inventory",
    icon: <SVG.Inventory height={19} width={19} />,
    exact: false,
  },
  {
    path: routes.messagesScreen,
    title: "Messages",
    icon: <SVG.Messages height={19} width={19} />,
    exact: false,
  },
  {
    path: routes.autoRepliesScreen,
    title: "Auto Replies",
    icon: <SVG.AutoReplies height={22} width={19} />,
    exact: false,
  },
  // {
  //   path: "",
  //   title: "Calendar",
  //   icon: calendarIcon,
  // },
  {
    path: "",
    title: "Get Support",
    icon: <SVG.Support height={21} width={19} />,
    exact: false,
    redirect: true,
    callback: getSupportCallback,
  },
  {
    path: routes.settingsScreen,
    title: "Settings",
    icon: <SVG.Settings height={21} width={19} />,
    exact: true,
  },
  {
    path: "",
    title: "Refer Friends",
    icon: <SVG.Refer height={19} width={19} />,
    exact: false,
    redirect: true,
    callback: referFriendsCallback,
  },
];

function Sidebar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [showCreateListingDropdown, setShowCreateListingDropdown] =
    useState(false);
  const [showListingModal, setShowListingModal] = useState(false);

  const channels = useSelector(selectorChannelsWithImport);
  const isImportLoading = useSelector(slectorImportLoading);

  const inputFile = useRef(null);

  const changeHandler = (event) => {
    // TODO: clear field after upload
    dispatch(createListingBulkImportRequest({ file: event?.target?.files[0] }));
  };

  const isListingBulkUploadLoading = useSelector(
    selectorIsListingBulkUploadLoading
  );

  useEffect(() => {
    if (!channels) {
      dispatch(getChannelsRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isListingBulkUploadLoading === false) {
      inputFile.current.value = null;
      setShowCreateListingDropdown(false);
    }
  }, [isListingBulkUploadLoading]);

  const onImportListings = (platform) => {
    dispatch(importListingsRequest({ platform }));
  };

  const onConnectChannel = (uid) => {
    setShowListingModal(false);
    history.push(`${routes.integrationsScreen}/${uid}`);
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.createListingWrapper}>
        <Button
          className={`${styles.createListingDropdownButton} ${
            showCreateListingDropdown ? styles.active : ""
          }`}
          variant="transparent"
          onClick={() => {
            setShowCreateListingDropdown(!showCreateListingDropdown);
          }}
        >
          Create Listing
          <div
            className={`${styles.dropdownIcon} ${
              showCreateListingDropdown ? styles.dropdownIconUp : ""
            }`}
          >
            <SVG.ArrowDown />
          </div>
        </Button>
      </div>

      <div className={styles.content}>
        <div className={styles.linksList}>
          {linksList.map((link, index) => {
            return (
              <NavLink
                className={styles.link}
                activeClassName={link.redirect ? "" : styles.active}
                to={link.redirect ? location.pathname : link.path}
                onClick={link.redirect ? link.callback : null}
                key={`navlink-${index}`}
                exact={link.exact}
              >
                <div className={styles.linkIcon}>{link.icon}</div>
                <div>{link.title}</div>
              </NavLink>
            );
          })}
        </div>
        <SidebarDateTime />
        <div
          className={`${styles.createListingDropdown} ${
            showCreateListingDropdown ? styles.show : ""
          }`}
        >
          <input
            type="file"
            id="file"
            ref={inputFile}
            accept=".csv"
            style={{ display: "none" }}
            onChange={changeHandler}
          />
          <div className={styles.createListingMenu}>
            <NavLink
              className={styles.link}
              to={routes.createListingScreen}
              onClick={() => {
                setShowCreateListingDropdown(false);
              }}
            >
              <div className={styles.linkIcon}>
                <img src={createListingIcon} alt={"Create Listing"} />
              </div>
              <div>Create Listing</div>
            </NavLink>
            <NavLink
              className={styles.link}
              to={"/"}
              onClick={(e) => {
                e.preventDefault();
                inputFile.current.click();
              }}
            >
              <div className={styles.linkIcon}>
                <img src={fileIcon} alt={"Create Listing"} />
              </div>
              {isListingBulkUploadLoading ? (
                <Loader />
              ) : (
                <div>Import from .CSV</div>
              )}
            </NavLink>
            <NavLink
              className={styles.link}
              to={"/"}
              onClick={(e) => {
                e.preventDefault();
                setShowListingModal(true);
              }}
            >
              <div className={styles.linkIcon}>
                <img src={importIcon} alt={"Create Listing"} />
              </div>
              <div>Import from Channel</div>
            </NavLink>
          </div>
          <Button
            className={styles.hideCreateListingMenuButton}
            variant="transparent"
            onClick={() => {
              setShowCreateListingDropdown(false);
            }}
          ></Button>
        </div>
      </div>

      <ImportListingsModal
        show={showListingModal}
        setShow={setShowListingModal}
        channelsList={channels}
        onImportRequest={onImportListings}
        onConnectChannel={onConnectChannel}
        isImportLoading={isImportLoading}
      />
    </div>
  );
}

export default Sidebar;
