import React from "react";
import PropTypes from "prop-types";

import { ContentContainer, Button, Info } from "components/simple";
import { Link } from "react-router-dom";

import styles from "./integrationCard.module.scss";
import helpers from "styles/helpers.module.scss";

import { routes } from "router";

const IntegrationCard = ({ integration, disconnectHandler }) => {
  return (
    <ContentContainer
      className={`${
        integration.status === "inactive" && styles.cardUnavailable
      } ${styles.integrationCard}`}
    >
      <Link to={`${routes.integrationsScreen}/${integration?.id}`}>
        <div className={`${helpers.contentRowLine} ${styles.nameRow}`}>
          <div className={styles.marketplaceLogo}>
            <img src={integration.logo} alt="market logo" />
          </div>
          <div className={styles.marketplaceName}>{integration.name}</div>
        </div>
      </Link>
      <div className={`${helpers.contentRowLine} ${styles.cardRow}`}>
        <div className={styles.title}>Current Listings</div>
        <div className={styles.informationBlock}>
          <Info
            title="Information"
            description="This is the number of current listings you have in ListAcross that are active on this channel. Please note that if you published listings directly on the channel instead of using ListAcross, your total number of listings on the channel may be greater than the number that appears here."
          />
          <div className={styles.separator}></div>
          <div>{integration.listings_amount}</div>
        </div>
      </div>
      <div className={`${helpers.contentRowLine} ${styles.cardRow}`}>$ USD</div>
      <div
        className={`${helpers.contentRowLine} ${styles.cardRow} ${styles.isConnectedBlock}`}
      >
        <div>
          {integration.status_connection ? "Connected" : "Not Connected"}
        </div>
        <div
          className={`${
            integration.status_connection
              ? styles.cardConnected
              : styles.cardNotConnected
          } ${styles.isConnectedBulb}`}
        ></div>
      </div>
      <div className={styles.btnContainer}>
        {integration.status_connection ? (
          <Button
            onClick={() => disconnectHandler(integration)}
            className={`${styles.disconnectBtn} ${styles.btn}`}
            variant="transparent"
          >
            Disconnect
          </Button>
        ) : (
          <Link to={`${routes.integrationsScreen}/${integration.id}`}>
            <Button className={`${styles.connectBtn} ${styles.btn}`}>
              Connect
            </Button>
          </Link>
        )}
      </div>
    </ContentContainer>
  );
};

IntegrationCard.propTypes = {
  integration: PropTypes.object,
};

export default IntegrationCard;
