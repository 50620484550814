import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  autoReplies: null,
  autoRepliesLoading: false,
  createAutoReplyLoading: false,
  updateAutoReplyLoading: {},
  deleteAutoReplyLoading: false,
  shortcuts: null,
  shortcutsLoading: false,
  replies: null,
  repliesLoading: false,
};

const replies = createSlice({
  name: "replies",
  initialState,
  reducers: {
    // Get auto replies
    autoRepliesRequest(state) {
      state.autoRepliesLoading = true;
    },
    autoRepliesSuccess(state, { payload }) {
      state.autoRepliesLoading = false;
      state.autoReplies = payload;
    },
    autoRepliesFailure(state) {
      state.autoRepliesLoading = false;
    },

    // Create auto reply
    createAutoReplyRequest(state) {
      state.createAutoReplyLoading = true;
    },
    createAutoReplySuccess(state) {
      state.createAutoReplyLoading = false;
    },
    createAutoReplyFailure(state) {
      state.createAutoReplyLoading = false;
    },

    // Update auto reply
    updateAutoReplyRequest(state, { payload: { uid } }) {
      state.updateAutoReplyLoading = {
        ...state.updateAutoReplyLoading,
        [uid]: true,
      };
    },
    updateAutoReplySuccess(state, { payload: uid }) {
      state.updateAutoReplyLoading = {
        ...state.updateAutoReplyLoading,
        [uid]: false,
      };
      const target = state.autoReplies.find((a) => a.id === uid);
      target.status = !target.status;
    },
    updateAutoReplyFailure(state, { payload: uid }) {
      state.updateAutoReplyLoading = {
        ...state.updateAutoReplyLoading,
        [uid]: false,
      };
    },

    // Delete auto reply
    deleteAutoReplyRequest(state) {
      state.deleteAutoReplyLoading = true;
    },
    deleteAutoReplySuccess(state, { payload }) {
      state.deleteAutoReplyLoading = false;
    },
    deleteAutoReplyFailure(state) {
      state.deleteAutoReplyLoading = false;
    },

    // Get shortcuts
    shortcutsRequest(state) {
      state.shortcutsLoading = true;
    },
    shortcutsSuccess(state, { payload }) {
      state.shortcutsLoading = false;
      state.shortcuts = payload;
    },
    shortcutsFailure(state) {
      state.shortcutsLoading = false;
    },

    // Get replies
    repliesRequest(state) {
      state.repliesLoading = true;
    },
    repliesSuccess(state, { payload }) {
      state.repliesLoading = false;
      state.replies = payload;
    },
    repliesFailure(state) {
      state.repliesLoading = false;
    },
  },
});

export default replies;
