import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import { selectorProfile } from "selectors/userSelectors";
import { selectorTargetChannel } from "../Selector";

import usersReducer from "store/entities/Users/users.reducer";
import platformsReducer from "store/entities/Platforms/platforms.reducer";
import headerReducer from "store/entities/Header/header.reducer";

import { ContentContainer, Loader, Info } from "components/simple";
import { Etsy, Ebay, Shopify, Woocommerce, Facebook } from "./index";
import { Collapse } from "react-bootstrap";

import styles from "./integrationScreen.module.scss";
import helpers from "styles/helpers.module.scss";

import moreIcon from "assets/icons/plus-icon.png";
import lessIcon from "assets/icons/minus-icon.png";

const {
  actions: { setHeaderTitle },
} = headerReducer;

const { getUserRequest } = usersReducer.actions;

const { getTargetChannelRequest, removeTargetChannel, getChannelsRequest } =
  platformsReducer.actions;

const IntegrationScreen = () => {
  const dispatch = useDispatch();

  const [redirect, setRedirect] = useState(false);
  const redirectTimeoutRef = useRef();

  const profileData = useSelector(selectorProfile);
  const targetChannel = useSelector(selectorTargetChannel);

  const [isExtended, setIsExtended] = useState(false);
  const { id: paramsId } = useParams();
  const location = useLocation();

  if (targetChannel) {
    var {
      about,
      // id: channelId,
      additional_info: additionalInfo,
      fee_structure: feeStructure,
      geographic_focus: geographicFocus,
      synced_information: syncedInformation,
      status_connection: statusConnection,
      category,
      logo,
      name: channelName,
      terms_and_conditions: termsConditions,
      website,
      sub_title: subtitle,
      description,
    } = targetChannel;
  }

  useEffect(() => {
    if (!targetChannel && !!location.search) {
      dispatch(getTargetChannelRequest({ uid: paramsId }));
      setRedirectCallback();
    } else {
      dispatch(getTargetChannelRequest({ uid: paramsId }));
    }

    if (!profileData) {
      dispatch(getUserRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (targetChannel && targetChannel.id !== paramsId) {
      dispatch(removeTargetChannel());
      dispatch(getTargetChannelRequest({ uid: paramsId }));
      dispatch(
        setHeaderTitle({
          title: `Integrations / ...`,
          linkBack: location.pathname.split("/")[1],
        })
      );
      return;
    }
    if (targetChannel && targetChannel.id === paramsId) {
      dispatch(
        setHeaderTitle({
          title: `Integrations / ${channelName}`,
          linkBack: location.pathname.split("/")[1],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetChannel]);

  const setRedirectCallback = () => {
    setRedirect(true);

    // delay to get integration status from api
    redirectTimeoutRef.current = setTimeout(() => {
      dispatch(getTargetChannelRequest({ uid: paramsId }));
      dispatch(getChannelsRequest());
      setRedirect(false);
    }, 2000);
  };

  const readMoreHendler = () => {
    setIsExtended((isExtended) => !isExtended);
  };

  const getTargetConnectComponent = (channelName) => {
    if (channelName) {
      const name = channelName.toLowerCase();

      switch (name) {
        case "etsy":
          return (
            <Etsy
              marketplaceName={channelName}
              channelId={paramsId}
              statusConnection={statusConnection}
              isAuthPassed={!!location.search}
              redirect={redirect}
            />
          );

        case "shopify":
          return (
            <Shopify
              marketplaceName={channelName}
              statusConnection={statusConnection}
              channelId={paramsId}
              redirect={redirect}
            />
          );

        case "facebook":
          return (
            <Facebook
              marketplaceName={channelName}
              email={profileData?.user.email}
            />
          );

        case "ebay":
          return (
            <Ebay
              marketplaceName={channelName}
              channelId={paramsId}
              statusConnection={statusConnection}
              redirect={redirect}
            />
          );

        case "woocommerce":
          return (
            <Woocommerce
              marketplaceName={channelName}
              statusConnection={statusConnection}
              channelId={paramsId}
            />
          );

        default:
          break;
      }
    }
  };

  return (
    <>
      <div className={styles.screenContainer}>
        <ContentContainer className={styles.marketplaceInfo}>
          {targetChannel ? (
            <>
              <div className={`${styles.coInfo} ${helpers.contentRowLine}`}>
                <div className={styles.coLogo}>
                  <img src={logo} alt="logo" />
                </div>
                <div className={styles.coName}>{channelName}</div>
                <div className={styles.coSubtitle}>Marketplace</div>
              </div>
              <div
                className={`${styles.marketplaceData} ${helpers.contentRowLine}`}
              >
                <div className={styles.title}>Geographic Focus</div>
                <div className={styles.text}>{geographicFocus}</div>
              </div>
              <div
                className={`${styles.marketplaceData} ${helpers.contentRowLine}`}
              >
                <div className={styles.title}>Category</div>
                <div className={styles.text}>{category}</div>
              </div>
              <div
                className={`${styles.marketplaceData} ${helpers.contentRowLine}`}
              >
                <div className={styles.title}>Synced Information</div>
                <div className={styles.text}>{syncedInformation}</div>
              </div>
              <div
                className={`${styles.marketplaceData} ${helpers.contentRowLine}`}
              >
                <div className={styles.title}>Free + Add-Ons</div>
                <div className={styles.text}>{feeStructure}</div>
              </div>
              <div
                onClick={readMoreHendler}
                className={`${styles.marketplaceData} ${styles.readMoreBlock}`}
              >
                <div className={styles.text}>
                  {isExtended ? "Hide Information" : "Read More"}
                </div>
                <div className={styles.readMoreBtn}>
                  <img src={isExtended ? lessIcon : moreIcon} alt="read more" />
                </div>
              </div>
              <Collapse in={isExtended}>
                <div>
                  <div
                    className={`${styles.marketplaceData} ${helpers.contentRowLine}`}
                  >
                    <div className={styles.title}>About</div>
                    <div className={styles.text}>{about}</div>
                  </div>
                  <div
                    className={`${styles.marketplaceData} ${helpers.contentRowLine}`}
                  >
                    <div className={styles.title}>Additional Information</div>
                    <div className={styles.text}>{additionalInfo}</div>
                  </div>
                  <div
                    className={`${styles.marketplaceData} ${helpers.contentRowLine}`}
                  >
                    <div className={styles.title}>Terms & Conditions</div>
                    <div className={styles.text}>{termsConditions}</div>
                  </div>
                  <div
                    className={`${styles.marketplaceData} ${helpers.contentRowLine}`}
                  >
                    <div className={styles.title}>Website</div>
                    <a
                      href={website}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.text}
                    >
                      {website}
                    </a>
                  </div>
                </div>
              </Collapse>
            </>
          ) : (
            <Loader />
          )}
        </ContentContainer>

        {targetChannel && (
          <ContentContainer className={styles.connectChannelWrapper}>
            <div className={`${styles.header} ${helpers.contentRowLine}`}>
              <div className={`${styles.titleBlock}`}>
                <div className={styles.title}>
                  {`Connect and Link ${channelName} Marketplace`}
                </div>
                <div className={styles.subTitle}>{subtitle}</div>
              </div>
              <Info
                title={`${channelName} INFO`}
                description={description}
                width="420px"
              />
            </div>

            {getTargetConnectComponent(targetChannel.name)}
          </ContentContainer>
        )}
      </div>
    </>
  );
};

export default IntegrationScreen;
