import React from "react";
import PropTypes from "prop-types";

import { Button as BootstrapButton } from "react-bootstrap";
import Loader from "../Loader/Loader";

import styles from "./button.module.scss";

export default function Button(props) {
  const {
    className = "",
    variant,
    children,
    isLoading,
    ...buttonProps
  } = props;
  return (
    <BootstrapButton
      className={`${styles.button} ${variant ? styles[variant] : ""} 
         ${className} ${isLoading ? styles.loading : ""}`}
      {...buttonProps}
    >
      {!isLoading ? children : <Loader className={styles.loader} />}
    </BootstrapButton>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.string,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  className: "",
  variant: "",
  isLoading: false,
};
