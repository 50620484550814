import React, { useState } from "react";

import { useDispatch } from "react-redux";

import * as routes from "router/routes";

import usersReducer from "store/entities/Users/users.reducer";

import { Input, Button } from "components/simple";
import { Link } from "react-router-dom";

import authStyles from "../AuthContainer/auth-container.module.scss";

const { resetPasswordRequest } = usersReducer.actions;

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
  });
  const { email } = state;

  const inputChangeHandler = (name, value) => {
    let modifiedValue;

    if (name === "email") {
      modifiedValue = typeof value === "string" ? value.toLowerCase() : "";
    } else {
      modifiedValue = value;
    }

    setState({ ...state, [name]: modifiedValue });
  };

  const resetPasswordHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    // clearErrorMessage();
    dispatch(resetPasswordRequest({ email }));
  };

  return (
    <div>
      <div className={authStyles.title}>Reset Password</div>
      <form
        className={authStyles.form}
        style={{ marginTop: 30 }}
        onSubmit={resetPasswordHandler}
      >
        <Input
          name="email"
          type="email"
          value={email}
          label={"Email"}
          onChange={inputChangeHandler}
          required
        />

        <div className={authStyles.buttonContainer}>
          <Button type="submit" className={authStyles.signBtn}>
            Reset
          </Button>
        </div>

        <div
          className={authStyles.altActionContainer}
          style={{ marginTop: 50 }}
        >
          Remember Your Password?{" "}
          <Link to={routes.loginScreen}>Sign In Here</Link>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
