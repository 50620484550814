import React from "react";
import PropTypes from "prop-types";

export default function ArrowDown(props) {
  return (
    <svg
      style={props.style}
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 19.93 19.93`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.63,8.67h-7.38V1.29C11.26,0.58,10.68,0,9.96,0C9.25,0,8.67,0.58,8.67,1.29v7.38H1.29C0.58,8.67,0,9.25,0,9.97
	c0,0.71,0.58,1.29,1.29,1.29h7.37v7.38c0,0.72,0.58,1.29,1.29,1.29c0.71,0,1.29-0.58,1.29-1.29v-7.38h7.38
	c0.72,0,1.29-0.58,1.29-1.29C19.93,9.25,19.35,8.67,18.63,8.67z"
      />
    </svg>
  );
}

ArrowDown.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowDown.defaultProps = {
  className: "",
  width: 11.98,
  height: 11.23,
};
