import React from "react";

import { Button } from "components/simple";

import styles from "./listingSteps.module.scss";

const ListingSteps = ({
  step = 0,
  onSaveClose,
  onBack,
  isControlsVisible = true,
  containerClass = "",
}) => {
  const maxStepCount = 4;

  return (
    <div className={containerClass}>
      {isControlsVisible && (
        <div className={`${styles.controls}`}>
          <Button
            onClick={() => onBack()}
            className={styles.btn}
            variant="secondary"
          >
            Back
          </Button>
          <Button
            onClick={(e) => onSaveClose(e)}
            className={styles.btn}
            variant="secondary"
          >
            Save and Close
          </Button>
          <Button
            onClick={(e) => onSaveClose(e, "continue")}
            className={styles.btn}
            variant="primary"
          >
            Continue
          </Button>
        </div>
      )}

      <div className={styles.navigation}>
        {[...Array(maxStepCount)].map((_, i) => (
          <div
            key={i}
            className={`${styles.item} ${step >= i ? styles.passed : ""}`}
          />
        ))}
      </div>
    </div>
  );
};

export default ListingSteps;
