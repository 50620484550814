import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  etsyConversationsList: null,
  etsyConversationsListError: null,
  isEtsyConversationsLoading: false,
  openConversation: null,
  isOpenConversationLoading: false,
  isSendEtsyMessageLoading: false,
  messages: null,
  isMessagesRequestLoading: false,
  isDeleteEtsyConversationLoading: false,
  isUpdateMessageLoading: false,
  selectedMessages: [],
};

const messages = createSlice({
  name: "messages",
  initialState,
  reducers: {
    // Get Etsy Conversations
    getEtsyConversationsRequest(state) {
      state.isEtsyConversationsLoading = true;
    },
    getEtsyConversationsSuccess(state, { payload }) {
      state.etsyConversationsList = payload;
      state.isEtsyConversationsLoading = false;
    },
    getEtsyConversationsFailure(state) {
      state.isEtsyConversationsLoading = false;
    },

    // Set open conversation
    setOpenConversation(state, { payload }) {
      state.openConversation = payload;
      state.isOpenConversationLoading = false;
    },

    // Remove open conversation (storage)
    removeEtsyConversation(state) {
      state.openConversation = null;
    },

    // Delete Etsy Conversation
    deleteEtsyConversationRequest(state) {
      state.isDeleteEtsyConversationLoading = true;
    },
    deleteEtsyConversationSuccess(state) {
      state.isDeleteEtsyConversationLoading = false;
    },
    deleteEtsyConversationFailure(state) {
      state.isDeleteEtsyConversationLoading = false;
    },

    // Select message
    selectedMessageAdd(state, { payload }) {
      state.selectedMessages = [...state.selectedMessages, payload];
    },
    selectedMessageRemove(state, { payload: message }) {
      const newArr = state.selectedMessages.filter((m) => m.id !== message.id);
      state.selectedMessages = newArr;
    },
    selectedMessagesClear(state) {
      state.selectedMessages = [];
    },

    // Get messages
    getMessagesRequest(state) {
      state.isMessagesRequestLoading = true;
    },
    getMessagesSuccess(state, { payload }) {
      state.messages = payload;
      state.isMessagesRequestLoading = false;
    },
    getMessagesFailure(state) {
      state.isMessagesRequestLoading = false;
    },

    // Send message (add to storage)
    sendNewMessage(state, { payload }) {
      state.openConversation.messages = [
        ...state.openConversation.messages,
        payload,
      ];
    },

    // Update message
    updateMessageRequest(state) {
      state.isUpdateMessageLoading = true;
    },
    updateMessageSuccess(state) {
      state.isUpdateMessageLoading = false;
    },
    updateMessageFailure(state) {
      state.isUpdateMessageLoading = false;
    },

    // Delete message success
    deleteMessageSuccess(state, { payload: message }) {
      const newArr = state.openConversation.messages.filter(
        (m) => m.id !== message.id
      );
      state.openConversation.messages = newArr;
    },

    // Set conversations
    setConversations(state, { payload }) {
      state.etsyConversationsList = payload;
    },
    setConversationsError(state, { payload }) {
      state.etsyConversationsListError = payload;
    },
    removeConversationsError(state) {
      state.etsyConversationsListError = null;
    },
  },
});

export default messages;
