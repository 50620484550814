import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// import modalReducer from "store/entities/Modal/modal.reducer";

import ContentContainer from "components/simple/ContentContainer";
// import Button from "components/simple/Button";
import Input from "components/simple/Input";
// import DeleteModal from "components/simple/ModalConfirmReject";
import Loader from "components/simple/Loader";

import styles from "./billingInfo.module.scss";
import helpers from "styles/helpers.module.scss";
import password from "../Password/password.module.scss";

// const {
//   actions: { showModal },
// } = modalReducer;

function BillingInfo({
  // userId,
  card,
  billingData,
  billingInfoLoading,
  // deleteUserRequest,
  // isDeleteUserLoading,
}) {
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState({
    cardNumber: "",
    cvcNumber: "000",
  });

  useEffect(() => {
    if (card) {
      setSelectedOptions({
        ...selectedOptions,
        cardNumber: card.last4,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  // const setIsDeleteModalShown = () => {
  //   dispatch(showModal("deleteAccount"));
  // };
  
  // const deleteAccountHandler = () => {
  //   dispatch(deleteUserRequest({ uid: userId }));
  // };

  const selectChangeHandler = (name, value) => {
    setSelectedOptions({ ...selectedOptions, [name]: value });
  };


  return (
    <ContentContainer className={styles.billingInfoContainer}>
      <div className={`${helpers.contentRowLine} ${helpers.sectionTitle}`}>
        Billing Info
      </div>

      <div className={styles.subscriptionContainer}>
        {billingInfoLoading ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <>
            {card && billingData ? (
              <div className={styles.billingAddress}>
                <div
                  className={`${styles.personalData} ${helpers.contentRowLine}`}
                >
                  <div className={styles.title}>Name</div>
                  <div className={styles.text}>
                    {`${billingData?.user_profile.user?.first_name} ${billingData?.user_profile?.user?.last_name}`}
                  </div>
                </div>

                <div
                  className={`${styles.personalData} ${helpers.contentRowLine}`}
                >
                  <div className={styles.title}>Address</div>
                  <div className={styles.text}>
                    {`${billingData?.address}, ${billingData?.city}, ${billingData?.country}, ${billingData?.zip_code}`}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noPayments}>No billing info</div>
            )}

            <div className={styles.billingCardNum}>
              {card ? (
                <>
                  <Input
                    name="cardNumber"
                    label="Card Number"
                    value={`•••• •••• •••• ${selectedOptions.cardNumber}`}
                    onChange={selectChangeHandler}
                    containerClass={password.passInputContainer}
                    type="text"
                    maxLength={16}
                    isActiveDisabled
                    disabled
                  />

                  <Input
                    name="cvcNumber"
                    label="CVC"
                    value={selectedOptions.cvcNumber}
                    onChange={selectChangeHandler}
                    containerClass={password.passInputContainer}
                    type="password"
                    maxLength={3}
                    isActiveDisabled
                    disabled
                  />
                </>
              ) : (
                ""
              )}

              {/* <Button
                onClick={setIsDeleteModalShown}
                variant="alert"
                className={styles.btn}
              >
                Delete Account
              </Button> */}

              {/* <DeleteModal
                type={"deleteAccount"}
                messageText={"Are you sure you want to delete your account? This action is permanent and cannot be undone."}
                confirmBtnText={"Yes"}
                rejectBtnText={"No"}
                onConfirmHandler={deleteAccountHandler}
                isLoading={isDeleteUserLoading}
              /> */}
            </div>
          </>
        )}
      </div>
    </ContentContainer>
  );
}

export default BillingInfo;
