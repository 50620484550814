import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import notificationReducer from "store/entities/Notification/notifications.reducer";
import { selectorToasts } from "store/entities/Notification/notifications.selectors";

import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import styles from "./styles.module.scss";

const {
  actions: { removeNotification },
} = notificationReducer;

const Notifications = () => {
  const dispatch = useDispatch();
  const toasts = useSelector(selectorToasts);

  useEffect(() => {
    if (toasts?.length > 0) {
      setTimeout(() => {
        dispatch(removeNotification(toasts[toasts?.length - 1].id));
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toasts]);

  const messages = toasts?.map((note) => {
    return (
      <CSSTransition
        key={note.id}
        onDoubleClick={() => dispatch(removeNotification(note.id))}
        classNames={{
          enter: styles.itemEnter,
          enterActive: styles.itemEnterActive,
          exitActive: styles.itemLeaveActive,
        }}
        className={
          note.position === "top" ? styles.positionTop : styles.positionBottom
        }
       
        timeout={500}
      >
        {note.link ? (
          <Alert variant={note.type}  styles={{ zIndex: 10000 }}>
            <Link to={note.link}>{note.msg}</Link>
          </Alert>
        ) : (
          <Alert variant={note.type}>{note.msg}</Alert>
        )}
      </CSSTransition>
    );
  });

  return <TransitionGroup className={styles.box}  styles={{ zIndex: 10000 }}>{messages}</TransitionGroup>;
};

export default Notifications;
