import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import modalReducer from "store/entities/Modal/modal.reducer";
import { selectorIsModalShownFactory } from "selectors/modalSelectors";

import { Button } from "components/simple";
import BootstrapModal from "react-bootstrap/Modal";

import styles from "./styles.module.scss";

const {
  actions: { hideModal },
} = modalReducer;

const Modal = ({
  type,
  onConfirmHandler,
  isLoading,
  messageText,
  confirmBtnText,
  rejectBtnText,
  containerStyles,
  contentStyles,
}) => {
  const dispatch = useDispatch();
  const selectorIsModalShown = selectorIsModalShownFactory(type);
  const isModalShown = useSelector(selectorIsModalShown);

  const setHide = () => {
    dispatch(hideModal(type));
  };

  useEffect(() => {
    if (!isLoading && isModalShown) {
      setHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <div>
      <BootstrapModal
        show={isModalShown}
        onHide={setHide}
        aria-labelledby="example-custom-modal-styling-title"
        centered={true}
        dialogClassName={`${containerStyles} ${styles.modalContainer}`}
        contentClassName={`${contentStyles} ${styles.modalContent}`}
      >
        <BootstrapModal.Header bsPrefix={`${styles.header}`}>
          <BootstrapModal.Title
            id="example-custom-modal-styling-title"
            bsPrefix={styles.title}
          >
            {messageText}
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body bsPrefix={styles.modalBody}>
          <div>
            <Button
              onClick={onConfirmHandler}
              variant="transparent"
              className={styles.modalBtn}
              isLoading={isLoading}
            >
              {confirmBtnText}
            </Button>
            <Button onClick={setHide} className={styles.modalBtn}>
              {rejectBtnText}
            </Button>
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

Modal.propTypes = {
  onConfirmHandler: PropTypes.func,
  onRejectHandler: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  messageText: PropTypes.string.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  rejectBtnText: PropTypes.string.isRequired,
  containerStyles: PropTypes.object,
  contentStyles: PropTypes.object,
};

export default Modal;
