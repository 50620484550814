import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import modalReducer from "store/entities/Modal/modal.reducer";

import ContentContainer from "components/simple/ContentContainer";
import Button from "components/simple/Button";
import AddCardModal from "components/simple/ModalAddCard";
import Loader from "components/simple/Loader";

import styles from "./cardAdding.module.scss";
import helpers from "styles/helpers.module.scss";
import paymentsReducer from "store/entities/Payments/payments.reducer";
import visaIcon from "assets/icons/visa-icon.svg";
import masterCardIcon from "assets/icons/mastercard-icon.png";

const {
  actions: { showModal },
} = modalReducer;

const {
  actions: { removeCardRequest, setDefaultCardRequest },
} = paymentsReducer;

function CardAdding({
  cards,
  countries,
  profile,
  isCountriesLoading,
  isCardsListLoading,
}) {
  const dispatch = useDispatch();

  const setIsAddCardModalShown = () => {
    dispatch(showModal("addCard"));
  };

  let defaultCard = cards?.filter((card) => {
    return card.default_card;
  });

  let notDefaultCards = cards?.filter((card) => {
    return !card.default_card;
  });

  const renderCards = (card) => {
    return (
      <div className={styles.cardContainer}>
        <div className={styles.cardHeaderContainer}>
          <div className={styles.cardHeader}>
            {card.brand} Ending in {card.last4}
            <div className={styles.cardInfo}>
              {profile?.user?.first_name} {profile?.user?.last_name}
            </div>
          </div>
          <img
            className={styles.cardBrandIcon}
            src={card.brand === "Visa" ? visaIcon : masterCardIcon}
            alt="Card Brand"
          />
        </div>
        {/* <div className={styles.cardInfo}>Expires 02/24</div> */}
        <div className={styles.cardLineContainer}>
          <div className={styles.cardLine}></div>
        </div>

        <div className={styles.cardButtonsContainer}>
          <span
            className={`${styles.cardBtn} ${styles.cardManageBtn}`}
            // onClick={(e) => {
            //   e.preventDefault();
            //   dispatch(removeCardRequest(card?.id));
            // }}
          >
            Edit
          </span>
          <span
            className={`${styles.cardBtn} ${styles.cardManageBtn}`}
            // onClick={(e) => {
            //   e.preventDefault();
            //   dispatch(removeCardRequest(card?.id));
            // }}
          >
            Manage
          </span>

          {!card.default_card ? (
            <>
              <span
                className={`${styles.cardBtn} ${styles.cardDeleteBtn}`}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(removeCardRequest(card?.id));
                }}
              >
                Delete
              </span>
            </>
          ) : null}
        </div>
        {/* <input
          type="radio"
          name="default-card"
          value={card.id}
          checked={card.default_card}
          onChange={(e) => {
            console.log(e.target.value);
            dispatch(
              setDefaultCardRequest({
                cardId: e.target.value,
              })
            );
          }}
        /> */}
      </div>
    );
  };

  return (
    <ContentContainer className={styles.billingInfoContainer}>
      <div className={`${helpers.contentRowLine} ${helpers.sectionTitle}`}>
        Payment Options
      </div>

      <div className={styles.subscriptionContainer}>
        {isCardsListLoading ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <>
            {cards?.length ? (
              <div
                style={{
                  marginTop: "29px",
                }}
              >
                {defaultCard.map((card, index) => (
                  <React.Fragment key={index}>
                    {renderCards(card)}
                  </React.Fragment>
                ))}
                {notDefaultCards.map((card, index) => (
                  <React.Fragment key={index}>
                    {renderCards(card)}
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div className={styles.noPayments}>No payment options</div>
            )}
            <AddCardModal
              type={"addCard"}
              messageText={"Add a New Card"}
              confirmBtnText={"Save Card"}
              rejectBtnText={"Cancel"}
              countries={countries}
            />
          </>
        )}
      </div>
      <div className={styles.addBtnContainer}>
        <Button onClick={setIsAddCardModalShown} className={styles.btn}>
          Add a Payment Option
        </Button>
      </div>
    </ContentContainer>
  );
}

export default CardAdding;
