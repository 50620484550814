import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectorFacebookStatus } from "../../Selector";

import platformsReducer from "store/entities/Platforms/platforms.reducer";

import { Input, Button } from "components/simple";

import styles from "../integrationScreen.module.scss";

const {
  actions: {
    connectFacebookRequest,
    statusFacebookRequest,
    statusFacebookSuccess,
    getChannelsRequest,
  },
} = platformsReducer;

const Facebook = ({ marketplaceName, email }) => {
  const dispatch = useDispatch();
  const timeoutRef = useRef();
  const timeoutCountRef = useRef(3);

  const status = useSelector(selectorFacebookStatus);

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const handleInput = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const handleClearInputs = () => {
    setState({
      email: "",
      password: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(connectFacebookRequest(state));
    handleClearInputs();
    dispatch(statusFacebookSuccess("pending"));
  };

  const connectionStatusChecker = () => {
    if (!timeoutRef.current && status !== "active") {
      timeoutRef.current = setInterval(() => {
        if (timeoutCountRef.current === 0) {
          clearInterval(timeoutRef.current);
          return;
        }
        dispatch(statusFacebookRequest());
        timeoutCountRef.current--;
      }, 10000);
    }
  };

  useEffect(() => {
    if (!status) {
      dispatch(statusFacebookRequest());
    }

    return () => {
      clearInterval(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status !== "active" && status !== null) {
      connectionStatusChecker();
    } else {
      clearInterval(timeoutRef.current);
      dispatch(getChannelsRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (email) {
      setState({ ...state, email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <div className={styles.connectContainer}>
      {!status || status === "pending" ? (
        <form onSubmit={handleSubmit}>
          <div className={styles.connectFromTitle}>Sign in to connect</div>
          <Input
            name="email"
            label="Email"
            value={state.email}
            onChange={handleInput}
            required
          />
          <Input
            name="password"
            label="Password"
            type="password"
            value={state.password}
            onChange={handleInput}
            required
          />
          <Button
            isLoading={status === "pending"}
            style={{ width: "100%" }}
            type="submit"
          >
            Connect
          </Button>
        </form>
      ) : status === "inactive" ? (
        <div>
          {`Due to some reasons Facebook rejected your request. It happens :) /n
            Please disconnect this channel and try again`}
        </div>
      ) : (
        <div className={styles.connectMessage}>
          {`Your ${marketplaceName} account is now connected to ListAcross`}
        </div>
      )}
    </div>
  );
};

export default Facebook;
