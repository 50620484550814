export { default } from "./SelectInput";

export const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    background: "#fff",
    opacity: 1,
    padding: 10,
    zIndex: 999,
    left: state.selectProps.left,
  }),

  control: () => ({
    minHeight: "60px",
    lineHeight: "30px",
    display: "flex",
    fontFamily: "Aeonik",
  }),

  option: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    marginBottom: "5px",
    color: state.isSelected
      ? state.selectProps.menuColor
      : state.selectProps.optionColor,
    cursor: "pointer",
  }),

  placeholder: () => ({
    width: "96%",
    height: "auto",
    position: "absolute",
    fontSize: "24px",
    lineHeight: "30px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "#7d7d7d",
  }),

  singleValue: () => ({
    width: "96%",
    textOverflow: "ellipsis",
    position: "absolute",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "24px",
    lineHeight: "28px",
  }),

  dropdownIndicator: (_, { selectProps }) => ({
    transition: "0.5s",
    transform: selectProps.menuIsOpen && "rotate(180deg)",
  }),

  indicatorSeparator: () => ({
    display: "none",
  }),
};
