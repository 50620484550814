import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import subscriptionReducer from "store/entities/Subscriptions/subscriptions.reducer";
import paymentsReducer from "store/entities/Payments/payments.reducer";
import headerReducer from "store/entities/Header/header.reducer";

import { selectorSubscriptionPlan, selectorCards } from "./Selector";

import { ContentContainer, Input, Button } from "components/simple";
// TODO: Move to simple components
import { Alert } from "react-bootstrap";

import styles from "./payment.module.scss";

import checkIcon from "assets/icons/check-green.png";
import stripeIcon from "assets/icons/stripe.png";

const {
  actions: { setHeaderTitle },
} = headerReducer;

const {
  actions: { selectPlan, fetchPlansRequest },
} = subscriptionReducer;

const {
  actions: {
    proceedSubscription,
    proceedSubscriptionWithExistingCard,
    fetchCardsRequest,
    removeCardRequest,
    setDefaultCardRequest,
  },
} = paymentsReducer;

// TODO: Replace with const from global env
const stripePromise = loadStripe(
  "pk_test_51GqiPzH7OfnnyW5V26DRll5Hsyriq9fnnTamAehTft35Ogr8vO2xXwrtOCg42KZmJ1ubj2QorFQNwGVeA5LDw5zW00EkMHhHv9"
);

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#000",
      color: "#000",
      fontWeight: 500,
      borderBottom: "5px solid #000",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "19px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#000",
      },
      "::placeholder": {
        color: "#959595",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#000",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <Input
      inputClass={styles.paymentInput}
      labelClass={styles.paymentInput}
      label={label}
      id={id}
      type={type}
      //   placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <Button
    style={{}}
    className={`${styles.subscribeButton} ${
      error ? "SubmitButton--error" : ""
    }`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </Button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  const { id: planId } = useParams();

  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    city: "",
    address: "",
    zip_code: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    // console.log(await stripe.createToken(elements.getElement(CardElement)));

    // const payload = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: elements.getElement(CardElement),
    //   // billing_details: billingDetails,
    // });

    const payload = await stripe.createToken(elements.getElement(CardElement));

    dispatch(
      proceedSubscription({
        planId,
        paymentMethod: payload?.token?.id,
        billingInfo: billingDetails,
      })
    );

    setProcessing(false);

    if (payload.error) {
      setError(payload.error);
    } else {
      setPaymentMethod(payload.paymentMethod);
    }
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      name: "",
      email: "",
      phone: "",
      country: "",
      city: "",
      address: "",
      zip_code: "",
    });
  };

  return paymentMethod ? (
    <div className="Result">
      <Alert variant={"success"} className={"text-center"}>
        Payment successful
      </Alert>

      {/* <div className="ResultMessage">PaymentMethod: {paymentMethod.id}</div> */}
      <ResetButton onClick={reset} />
    </div>
  ) : (
    <form
      className="Form"
      onSubmit={handleSubmit}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <fieldset className="FormGroup">
        <Field
          label="Name"
          id="name"
          type="text"
          placeholder="Jane Doe"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(name, value) => {
            setBillingDetails({ ...billingDetails, name: value });
          }}
        />
        <Field
          label="Email"
          id="email"
          type="email"
          placeholder="janedoe@gmail.com"
          required
          autoComplete="email"
          value={billingDetails.email}
          onChange={(name, value) => {
            setBillingDetails({ ...billingDetails, email: value });
          }}
        />
        <Field
          label="Phone"
          id="phone"
          type="tel"
          placeholder="(941) 555-0123"
          required
          autoComplete="tel"
          value={billingDetails.phone}
          onChange={(name, value) => {
            setBillingDetails({ ...billingDetails, phone: value });
          }}
        />
        <Field
          label="Country"
          id="country"
          type="text"
          placeholder="(941) 555-0123"
          required
          autoComplete="country"
          value={billingDetails.country}
          onChange={(name, value) => {
            setBillingDetails({ ...billingDetails, country: value });
          }}
        />
        <Field
          label="City"
          id="city"
          type="text"
          required
          autoComplete="city"
          value={billingDetails.city}
          onChange={(name, value) => {
            setBillingDetails({ ...billingDetails, city: value });
          }}
        />
        <Field
          label="Address"
          id="address"
          type="text"
          required
          autoComplete="address"
          value={billingDetails.address}
          onChange={(name, value) => {
            setBillingDetails({ ...billingDetails, address: value });
          }}
        />
        <Field
          label="Zip code"
          id="zip_code"
          type="text"
          required
          autoComplete="zip_code"
          value={billingDetails.zip_code}
          onChange={(name, value) => {
            setBillingDetails({ ...billingDetails, zip_code: value });
          }}
        />
      </fieldset>
      <fieldset className={`FormGroup ${styles.cardInput}`}>
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </fieldset>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <SubmitButton processing={processing} error={error} disabled={!stripe}>
        Subscribe
      </SubmitButton>
    </form>
  );
};

export default function Payment() {
  const dispatch = useDispatch();

  const { id } = useParams();
  const cards = useSelector(selectorCards);

  const planData = useSelector(selectorSubscriptionPlan);

  const ELEMENTS_OPTIONS = {
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
      },
    ],
  };

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.

  useEffect(() => {
    dispatch(fetchPlansRequest());
    dispatch(selectPlan(id));
    dispatch(setHeaderTitle({ title: "Payment" }));
    dispatch(fetchCardsRequest());

    // TODO: fetch subscription plan info

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatPrice = (price) => {
    const [int, fract] = price.split(".");
    return (
      <div className={styles.priceValue}>
        <div className={styles.priceValueInt}>{`$${int}`}</div>
        <div className={styles.priceValueFract}>
          <div>{`.${fract}`}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ContentContainer>
        <div className={styles.paymentContainer}>
          <div className={styles.planContainer}>
            <div className={styles.planWrapper}>
              {planData ? (
                <>
                  <div className={styles.subscribeTo}>Subscribe to</div>
                  <div className={styles.planName}>
                    {planData.plan_type} Subscription
                  </div>
                  <div className={styles.limitations}>
                    <div className={styles.checkRow}>
                      <img src={checkIcon} alt="check" />
                      <div>{`Up to ${planData.max_pushes} Insertions Per Month`}</div>
                    </div>
                    <div className={styles.checkRow}>
                      <img src={checkIcon} alt="check" />
                      <div>{`${planData.max_integrations} integrations`}</div>
                    </div>
                  </div>
                  <div className={styles.price}>
                    {formatPrice(planData.cost)}
                    <div className={styles.perMonth}>Per Month</div>
                  </div>
                  <div className={styles.poweredBy}>
                    <div className={styles.poweredByStripe}>
                      Powered by <img src={stripeIcon} alt="stripe" />
                    </div>
                    <div className={styles.poweredByTerms}>
                      <a href="https://listacross.com/terms">Terms</a>{' '}
                      <a href="https://listacross.com/privacy">Privacy</a>
                    </div>
                  </div>
                </>
              ) : null}
              {/* <PlanCard plan={{}} /> */}
            </div>
          </div>
          <div className={styles.cardContainer}>
            <div className={styles.cardWrapper}>
              <div className={styles.cardHeader}>Pay with card</div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(
                    proceedSubscriptionWithExistingCard({
                      /* cardId */
                    })
                  );
                }}
              >
                {cards?.length ? (
                  <>
                    <div className="mb-3 text-center font-weight-bold">
                      Pay with existing payment methods:
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      {cards?.map((card) => (
                        <div
                          style={{
                            width: "300px",
                            height: "100px",
                            borderRadius: 15,
                            marginBottom: 10,
                            padding: 10,
                            display: "flex",
                            justifyContent: "space-between",
                            border: "1px solid #d5d5d5",
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="default-card"
                              value={card.id}
                              checked={card.default_card}
                              onChange={(e) => {
                                dispatch(
                                  setDefaultCardRequest({
                                    cardId: e.target.value,
                                  })
                                );
                              }}
                            />
                            {/* {JSON.stringify(card)} */}
                            <div
                              styles={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div>{card.brand}</div>
                              <div>**** **** **** {card.last4}</div>
                            </div>
                          </div>
                          {!card.default_card ? (
                            <Button
                              variant="transparent"
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(removeCardRequest(card?.id));
                              }}
                            >
                              X
                            </Button>
                          ) : null}
                        </div>
                      ))}
                      <Button
                        className={styles.subscribeButton}
                        style={{ marginTop: 15, marginBottom: 15 }}
                        type="submit"
                      >
                        Subscribe
                      </Button>
                    </div>
                  </>
                ) : null}
              </form>

              <div className="mt-3 mb-3 text-center font-weight-bold">
                {cards?.length ? "Or create new one:" : null}
              </div>
              <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <CheckoutForm />
              </Elements>
              <div className={`${styles.agreement} mt-3`}>
                By confirming your subscription, you allows ListAcross, Inc. to
                charge your card for this payment and future payments in
                accordance with their terms.
              </div>
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
}
