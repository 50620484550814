import React from "react";
import PropTypes from "prop-types";

export default function Edit(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      fill="none"
    >
      <g
        id="Component_34_1"
        data-name="Component 34 – 1"
        transform="translate(0.75 0.832)"
      >
        <path
          id="Path_105"
          data-name="Path 105"
          d="M-14002.75-22191.8h-8.725v18.4h18.773v-8.988"
          transform="translate(14011.475 22194.607)"
          stroke={props.fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_106"
          data-name="Path 106"
          d="M-14003.463-22185.125l-.837,4.623,4.749-.922s8.293-7.984,9.293-8.984-3.177-5.668-5.109-3.734S-14003.463-22185.125-14003.463-22185.125Z"
          transform="translate(14011.475 22194.607)"
          stroke={props.fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}

Edit.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Edit.defaultProps = {
  className: "",
  width: 22.878,
  height: 22.795,
  fill: "#ccc",
};
