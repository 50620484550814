import React, { useState, useMemo, useEffect } from "react";

import { Link } from "react-router-dom";
import { Button, Modal, Loader, ListingSteps } from "components/simple";
import BootstrapModal from "react-bootstrap/Modal";
import SVG from "components/SVG";

import pushToChannelModal from "./modalPushToChannel.module.scss";
import helpers from "styles/helpers.module.scss";

export default function PushToChannelModal({
  step,
  show,
  setShow,
  channelsList,
  isChannelsLoading,
  alreadyPushedChannels,
  pushListingLoading,
  pushListingRequest,
  fetchListingLoading,
}) {
  const [pushedChannels, setPushedChannels] = useState("");

  const makeIdsArr = () => {
    if (pushedChannels) {
      const arr = pushedChannels.reduce((acc, ch) => [...acc, ch.id], []);
      return arr;
    }
  };

  useEffect(() => {
    if (alreadyPushedChannels) {
      setPushedChannels(alreadyPushedChannels);
    }
  }, [alreadyPushedChannels]);

  const idsArr = useMemo(makeIdsArr, [pushedChannels]);

  return (
    <div>
      <Modal
        setShow={() => setShow(step)}
        show={show}
        containerStyles={pushToChannelModal.modalContainer}
        contentStyles={pushToChannelModal.modalContent}
      >
        <BootstrapModal.Header
          bsPrefix={`${pushToChannelModal.header} ${helpers.contentRowLine}`}
        >
          <BootstrapModal.Title
            id="example-custom-modal-styling-title"
            bsPrefix={pushToChannelModal.title}
          >
            Push to Channel
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body bsPrefix={pushToChannelModal.modalBody}>
          {isChannelsLoading || fetchListingLoading ? (
            <Loader />
          ) : (
            channelsList?.map((channel) => {
              if (idsArr?.includes(channel.id)) return "";
              return (
                <div
                  key={channel.id}
                  className={`${pushToChannelModal.channel} ${
                    helpers.contentRowLine
                  } ${
                    channel.status_connection
                      ? ""
                      : pushToChannelModal.disconnected
                  }`}
                >
                  <div className={pushToChannelModal.channelLogo}>
                    <img src={channel.logo} alt="channel logo" />
                  </div>
                  <div className={pushToChannelModal.channelName}>
                    {channel.name}
                  </div>
                  {channel.status_connection ? (
                    pushListingLoading[channel.name.toLowerCase()] ? (
                      <Loader className={pushToChannelModal.loader} />
                    ) : (
                      <Button
                        onClick={() => {
                          pushListingRequest(channel.name.toLowerCase());
                        }}
                        className={pushToChannelModal.channelAddBtn}
                        variant="round"
                      >
                        <SVG.Plus />
                      </Button>
                    )
                  ) : (
                    <Link to={`/integrations/${channel.id}`}>
                      <Button
                        className={pushToChannelModal.channelConnectBtn}
                        variant="transparent"
                      >
                        Connect Channel
                      </Button>
                    </Link>
                  )}
                </div>
              );
            })
          )}
          <ListingSteps
            containerClass={pushToChannelModal.stepsContainer}
            step={step}
            isControlsVisible={false}
          />
        </BootstrapModal.Body>
      </Modal>
    </div>
  );
}
