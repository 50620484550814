/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { history, routes } from "router";

import { Table } from "components/complex";
import { Button } from "components/simple";
import SVG from "components/SVG";

import {
  selectorInventoryListings,
  selectorInventoryLoading,
} from "selectors/inventorySelectors";

import styles from "./inventory-table.module.scss";

const sortFunction = (rows, field, direction) => {
  if (field) {
    let asc = 1;
    if (direction === "asc") {
      asc = 1;
    } else {
      asc = -1;
    }

    return [
      ...rows.slice().sort((a, b) => {
        switch (field) {
          case "title":
            return a[field].length > b[field].length ? asc * 1 : asc * -1;

          case "price":
            return parseFloat(a[field]) > parseFloat(b[field])
              ? asc * 1
              : asc * -1;

          case "category":
            return a[field].name.split(" ")[0] > b[field].name.split(" ")[0]
              ? asc * 1
              : asc * -1;

          default:
            return a[field] > b[field] ? asc * 1 : asc * -1;
        }
      }),
    ];
  }
  return [...rows];
};

const DeliveryTable = ({
  onRowExpand,
  rendererExpandRow,
  onChangePage,
  onPushToChannel,
  onSelectNewPlan,
  ...props
}) => {
  const inventoryListings = useSelector(selectorInventoryListings);
  const areDeliveriesLoading = useSelector(selectorInventoryLoading);

  const columns = [
    {
      selector: "",
      name: "",
      cell: (row) => {
        return <div className={styles.circle}></div>;
      },
      sortable: true,
      maxWidth: "50px",
      minWidth: "50px",
    },
    {
      selector: "title",
      name: "Item",
      maxWidth: "400px",
      minWidth: "200px",
      cell: (row) => {
        return row?.title && row?.title;
      },
      sortable: true,
    },
    {
      selector: "sku",
      name: "SKU",
      maxWidth: "200px",
      minWidth: "100px",
      cell: (row) => {
        return row?.sku && row?.sku;
      },
      sortable: true,
    },
    {
      selector: "category",
      name: "Category",
      minWidth: "110px",
      cell: (row) => {
        return row?.category && row?.category?.name;
      },
      sortable: true,
    },
    {
      selector: "quantity",
      name: "No. In stock",
      maxWidth: "200px",
      minWidth: "100px",
      cell: (row) => {
        return row?.quantity && row?.quantity;
      },
      sortable: true,
    },
    {
      selector: "price",
      name: "Price",
      maxWidth: "200px",
      minWidth: "100px",
      cell: (row) => {
        return row?.price && `$${row?.price}`;
      },
      sortable: true,
    },
    {
      selector: "status",
      name: "Status",
      maxWidth: "150px",
      minWidth: "150px",
      cell: (row) => {
        return (
          row?.status && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className={
                  row?.status === "active" ? styles.active : styles.inactive
                }
              ></div>
              <div className={styles.statusText}>{row?.status}</div>
            </div>
          )
        );
      },
      sortable: true,
    },
    {
      selector: "channels",
      name: "Active channels",
      maxWidth: "400px",
      minWidth: "300px",
      cell: (row) => {
        return (
          row?.channels && (
            <div className="d-flex">
              {row?.channels?.map((channel) => {
                return (
                  <div key={channel.id} className={styles.channelLogo}>
                    <img src={channel.logo} alt={channel.name}></img>
                  </div>
                );
              })}
              <Button
                className={styles.btn}
                disabled={row?.status === 'inactive' ? true : false}
                variant="round"
                onClick={() => {
                  const pushModalStep = 3;
                  history.push(
                    `${routes.inventoryScreen}/${row?.id}/${pushModalStep}`
                  );
                }}
              >
                <SVG.Plus />
              </Button>

              <Button
                variant="round"
                className={`${styles.btn} ${styles.alertBtn}`}
                onClick={() => {
                  history.push(`${routes.inventoryScreen}/${row?.id}/remove`);
                }}
              >
                <SVG.Delete />
              </Button>
            </div>
          )
        );
      },
      sortable: true,
    },
    {
      selector: "status",
      name: "",
      maxWidth: "80px",
      minWidth: "80px",
      cell: (row) => {
        return (
          row?.channels && (
            <div
              className={styles.editListing}
              onClick={() => {
                history.push(`${routes.inventoryScreen}/${row?.id}/edit`);
              }}
            >
              <SVG.Edit />
            </div>
          )
        );
      },
      sortable: false,
    },
  ];

  return (
    <div
      className="d-flex flex-column mt-3 mb-3"
      style={{ flex: 1, overflowY: "hidden" }}
    >
      <Table
        sortIcon={<SVG.Sort className={styles.sortIcon} />}
        // defaultSortField="title"

        sortFunction={sortFunction}
        expandableRows
        isLoading={areDeliveriesLoading}
        pagination
        paginationServer
        // totalRows={totalPage}
        // handlePageChange={(page) => {
        //   onChangePage();
        //   dispatch(
        //     setFetchDeliveriesParams({
        //       offset: (page - 1) * 10,
        //       limit: 10,
        //       page,
        //     })
        //   );
        // }}
        columns={columns}
        {...props}
        data={inventoryListings}
        responsive
        // totalSize={totalPage}
        // curPage={curPage}
        // sizePerPage={curSizePerPage}
        // ishidePageListOnlyOnePage={curSizePerPage >= totalPage}
        // fixedHeader
        // paginationDefaultPage={curPage}
        // paginationResetDefaultPage
      />
    </div>
  );
};

DeliveryTable.propTypes = {
  onRowExpand: PropTypes.func,
  rendererExpandRow: PropTypes.func,
  onChangePage: PropTypes.func,
};

DeliveryTable.defaultProps = {
  onRowExpand: () => {},
  rendererExpandRow: () => {},
  onChangePage: () => {},
};

export default DeliveryTable;
