import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { slectorImportLoading } from "selectors/inventorySelectors";

import platformsReducer from "store/entities/Platforms/platforms.reducer";
import inventoryReducer from "store/entities/Inventory/inventory.reducer";

import { Input, Button } from "components/simple";

import styles from "../integrationScreen.module.scss";

const {
  actions: { importListingsRequest },
} = inventoryReducer;

const {
  actions: { connectWooCommerceRequest },
} = platformsReducer;

const WooCommerce = ({ statusConnection, channelId, marketplaceName }) => {
  const dispatch = useDispatch();

  const importListingsLoading = useSelector(slectorImportLoading);

  const [state, setState] = useState({
    url: "",
    consumerKey: "",
    consumerSecret: "",
  });

  const handleInput = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(connectWooCommerceRequest({ ...state, channelId }));
  };

  const handleWooCommerceImport = (e) => {
    dispatch(
      importListingsRequest({ platform: marketplaceName.toLowerCase() })
    );
  };

  const isConnected = statusConnection;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        marginTop: 30,
      }}
    >
      {!isConnected ? (
        <form onSubmit={handleSubmit}>
          <Input
            name="url"
            label="URL"
            type="url"
            value={state.url}
            onChange={handleInput}
          />
          <Input
            name="consumerKey"
            label="Consumer Key"
            value={state.consumerKey}
            onChange={handleInput}
          />
          <Input
            name="consumerSecret"
            label="Consumer Secret"
            value={state.consumerSecret}
            onChange={handleInput}
          />
          <Button style={{ width: "100%" }} type="submit">
            Connect
          </Button>
        </form>
      ) : (
        <div className={styles.importContainer}>
          <div
            className={styles.text}
          >{`Your ${marketplaceName} account is now connected to ListAcross`}</div>
          <div className={styles.text}>
            {`Import listings from your ${marketplaceName} account`}
          </div>
          <Button
            isLoading={importListingsLoading[marketplaceName.toLowerCase()]}
            style={{ width: "300px" }}
            className={styles.btn}
            onClick={handleWooCommerceImport}
          >
            Import
          </Button>
        </div>
      )}
    </div>
  );
};

export default WooCommerce;
