import { API, authorization, contentTypeJson } from "api/api";
import { get, patch, post, remove } from "api/base";

export const fetchInventory = ({ search }) => {
  return get(`${API}/inventory/${search ? `?search=${search}` : ""}`, {
    ...authorization(),
  });
};

export const fetchCategories = ({ id }) => {
  return get(`${API}/category/${id ? id : ""}`, { ...authorization() });
};

export const fetchListing = ({ id }) => {
  return get(`${API}/listings/${id ? id : ""}`, { ...authorization() });
};

export const createListing = ({ fields, images }) => {
  const formData = new FormData();

  // eslint-disable-next-line array-callback-return
  Object.keys(fields).map((key) => {
    if (typeof fields[key] === "object") {
      formData.append(key, JSON.stringify(fields[key]));
    } else {
      formData.append(key, fields[key]);
    }
  });

  // eslint-disable-next-line array-callback-return
  images.map((img) => {
    formData.append("image", img?.file);
  });

  // if (images[0]) {
  //   formData.append("photo", images[0].file);
  // }

  return post(`${API}/listings/`, formData, {
    ...authorization(),
  });
};

export const updateListing = ({ listingId, fields }) => {
  return patch(`${API}/listings/${listingId}`, JSON.stringify({ ...fields }), {
    ...authorization(),
    ...contentTypeJson(),
  });
};

export const duplicateListing = ({ listingId }) => {
  return post(`${API}/listings/${listingId}/duplicate`, JSON.stringify({ listingId }), {
    ...authorization(),
    // ...contentTypeJson(),
  });
};

export const uploadListingImages = ({ listingId, images }) => {
  const formData = new FormData();
  let trigger = false;

  // eslint-disable-next-line array-callback-return
  images?.map((img) => {
    if (img?.file) {
      formData.append("image", img?.file);
      trigger = true;
    }
  });

  if (!trigger) return;

  formData.append("listing", listingId);

  return post(`${API}/listings/image`, formData, {
    ...authorization(),
  });
};

export const deleteImage = (uid) => {
  return remove(`${API}/listings/image/${uid}`, {
    ...authorization(),
  });
};

export const fetchListingImages = ({ id }) => {
  return get(`${API}/listings/${id}/get-images`, {
    ...authorization(),
  });
};

export const createListingBulkImport = ({ file }) => {
  const formData = new FormData();

  formData.append("file_name", file);

  return post(`${API}/listings/bulk-import`, formData, {
    ...authorization(),
  });
};

export const importShopify = () => {
  return post(`${API}/integrations/shopify/import/listings`, null, {
    ...authorization(),
  });
};

export const importWooCommerce = () => {
  return get(`${API}/integrations/woocommerce/import`, {
    ...authorization(),
  });
};

export const pushEtsyListing = (data) => {
  return post(`${API}/integrations/etsy/listing`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
  });
};

export const fetchEtsyShippingTemplate = () => {
  return get(`${API}/integrations/etsy/shipping-template`, {
    ...authorization(),
  });
};

export const deleteEtsyListing = (listing) => {
  return post(
    `${API}/integrations/etsy/remove-etsy-listing`,
    JSON.stringify({ listing }),
    {
      ...authorization(),
      ...contentTypeJson(),
    }
  );
};

export const pushFacebookListing = (data) => {
  return post(`${API}/integrations/facebook/listing`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
  });
};

export const deleteFacebookListing = (uid) => {
  return remove(`${API}/integrations/facebook/listing/${uid}`, {
    ...authorization(),
  });
};

export const deleteShopifyListing = (uid) => {
  return remove(`${API}/integrations/shopify/listing/${uid}`, {
    ...authorization(),
  });
};

export const pushShopifyListing = (data) => {
  return post(
    `${API}/integrations/shopify/listing/push`,
    JSON.stringify(data),
    {
      ...authorization(),
      ...contentTypeJson(),
    }
  );
};

export const pushEbayListing = (data) => {
  return post(`${API}/integrations/ebay/listing`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
  });
};

export const deleteEbayListing = (uid) => {
  return remove(`${API}/integrations/ebay/listing/${uid}`, {
    ...authorization(),
  });
};

export const deleteListacrossListing = (uid) => {
  return remove(`${API}/listings/${uid}`, {
    ...authorization(),
  });
};
