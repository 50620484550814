import React from "react";

import moment from "moment";

import styles from "./chat.module.scss";
import helpers from "styles/helpers.module.scss";

import platformLogo from "assets/icons/ebay-icon.jpg";

const Chat = ({ chat, setActiveConvData, activeConvIndex, index }) => {
  function lastMessageTimeConverter() {
    const dateNow = new Date();
    const datePast = new Date(chat.time_last_message);

    if (chat.time_last_message) {
      var delta = Math.abs(dateNow - datePast) / 1000;
      var result = {};
      var values = {
        day: 86400,
        hour: 3600,
        minute: 60,
      };

      Object.keys(values).forEach((key) => {
        result[key] = Math.floor(delta / values[key]);
        delta -= result[key] * values[key];
      });

      if (result.day >= 1) {
        return moment(datePast).format("M/D/YY");
      } else if (result.hour >= 1) {
        return `${result.hour} H`;
      } else {
        return result.minute === 0 ? `JUST NOW` : `${result.minute} MIN`;
      }
    }
  }

  const time = lastMessageTimeConverter();

  return (
    <div
      onClick={() => setActiveConvData(chat.id, index, chat.message)}
      className={`${chat.unread && styles.unreadChat} ${
        activeConvIndex === index && styles.currentChat
      } ${styles.chatContainer} ${helpers.contentRowLine} `}
    >
      <div className={styles.userInfoWrapper}>
        <div className={styles.avatarWrapper}>
          {chat.avatar ? (
            <img src={chat.avatar} alt="avatar" />
          ) : (
            <div
              className={styles.withoutAvatar}
              style={{ backgroundColor: chat.color }}
            >
              {chat.name_interlocutor[0].toUpperCase()}
            </div>
          )}
        </div>
        <div className={styles.nameWrapper}>
          <div>{chat.name_interlocutor}</div>
          <div>{chat.listing_title}</div>
        </div>
      </div>

      <div className={styles.timeWrapper}>
        <div>{time}</div>
        <div className={styles.channelLogoWrapper}>
          <img src={platformLogo} alt="marketplace logo" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Chat);
