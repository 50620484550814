import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { history, routes } from "router";

import inventoryReducer from "store/entities/Inventory/inventory.reducer";
import usersReducer from "store/entities/Users/users.reducer";

import notificationsReducer from "../Notification/notifications.reducer";

import * as service from "./inventory.service";
import { makeRequest } from "../../sagas/helpers";

import { watchUpdateListing } from "./sagas/updateListing";

const {
  actions: {
    // Fetch inventory
    fetchInventoryRequest,
    fetchInventorySuccess,
    fetchInventoryFailure,

    // Fetch categories
    fetchCategoriesRequest,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,

    // Import listings
    importListingsRequest,
    importListingsSuccess,
    importListingsFailure,

    // Create listing
    createListingRequest,
    createListingSuccess,
    createListingFailure,

    createListingBulkImportRequest,
    createListingBulkImportSuccess,
    createListingBulkImportFailure,

    // Edit listing
    fetchEditingListingRequest,
    fetchEditingListingSuccess,
    fetchEditingListingFailure,

    fetchEditingListingImagesRequest,
    fetchEditingListingImagesSuccess,
    fetchEditingListingImagesFailure,

    // Integrate listing to platform
    pushListingRequest,
    pushListingSuccess,
    pushListingFailure,

    // Fetch Etsy shipping templates
    fetchEtsyShippingTemplatesRequest,
    fetchEtsyShippingTemplatesSuccess,
    fetchEtsyShippingTemplatesFailure,

    // Delete listing
    deleteListingRequest,
    deleteListingSuccess,
    deleteListingFailure,
  },
} = inventoryReducer;

const {
  actions: { logoutRequest },
} = usersReducer;

const {
  actions: { addNotification: addNotificationAction },
} = notificationsReducer;

// watchers //

function* fetchInventoryWorker({ payload }) {
  const { searchTerm } = payload || {};

  try {
    const inventoryResponse = yield call(makeRequest(service.fetchInventory), {
      search: searchTerm,
    });

    if (inventoryResponse.message) {
      if (inventoryResponse.message.logout) {
        yield put(
          addNotificationAction({
            msg: inventoryResponse?.message,
            type: "danger",
          })
        );
        yield put(logoutRequest(inventoryResponse.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: inventoryResponse?.message?.non_field_errors,
            type: "danger",
          })
        );
        yield put(fetchInventoryFailure(inventoryResponse.message));
      }
    } else {
      const { results } = inventoryResponse;

      yield put(fetchInventorySuccess(results));
    }
  } catch (error) {
    yield put(fetchInventoryFailure({ non_field_errors: "Error" }));
  }
}

function* fetchCategoriesWorker() {
  try {
    const categoriesResponse = yield call(
      makeRequest(service.fetchCategories),
      {}
    );

    if (categoriesResponse.message) {
      if (categoriesResponse.message.logout) {
        yield put(
          addNotificationAction({
            msg: categoriesResponse?.message,
            type: "danger",
          })
        );
        yield put(logoutRequest(categoriesResponse.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: categoriesResponse?.message?.non_field_errors[0],
            type: "danger",
          })
        );
        yield put(fetchCategoriesFailure(categoriesResponse.message));
      }
    } else {
      const { results } = categoriesResponse;

      yield put(fetchCategoriesSuccess(results));
    }
  } catch (error) {
    yield put(fetchCategoriesFailure({ non_field_errors: "Error" }));
  }
}

function* importListingsWorker({ payload }) {
  const { platform } = payload;
  let response;

  try {
    switch (platform) {
      case "shopify":
        response = yield call(makeRequest(service.importShopify));
        break;

      case "woocommerce":
        response = yield call(makeRequest(service.importWooCommerce));
        break;

      default:
        break;
    }

    if (response.message) {
      if (response.message.logout) {
        yield put(
          addNotificationAction({
            msg: response?.message,
            type: "danger",
          })
        );
        yield put(logoutRequest(response.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: response?.message?.non_field_errors,
            type: "danger",
          })
        );
        yield put(fetchCategoriesFailure(response.message));
      }
    } else {
      yield put(
        addNotificationAction({
          msg: `${platform} listings imported successfully, please check inventory page`,
          type: "success",
        })
      );
      yield put(importListingsSuccess(platform));
      yield put(fetchInventoryRequest());
    }
  } catch (error) {
    yield put(importListingsFailure(platform));
    yield put(
      addNotificationAction({
        msg: "Failed import",
        type: "danger",
      })
    );
  }
}

function* createListingWorker({ payload }) {
  const { images, fields } = payload;

  try {
    const response = yield call(makeRequest(service.createListing), {
      fields,
      images,
    });

    if (response.message) {
      if (response.message.logout) {
        yield put(
          addNotificationAction({
            msg: response?.message,
            type: "danger",
          })
        );
        yield put(logoutRequest(response.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: JSON.stringify(response?.message),
            type: "danger",
          })
        );
        yield put(createListingFailure(JSON.stringify(response?.message)));
      }
    } else {
      const newListing = response;
      const status = fields.status;

      yield put(createListingSuccess({ newListing, status }));
      yield put(
        addNotificationAction({
          msg: "Listing was created",
          type: "success",
        })
      );
    }
  } catch (error) {
    yield put(createListingFailure({ non_field_errors: "Error" }));
  }
}

function* createListingSuccessWorker({ payload }) {
  const { status, newListing } = payload;
  const createListingModalStep = 1;

  yield put(fetchInventoryRequest());
  status === "inactive"
    ? yield history.push(routes.inventoryScreen)
    : yield history.push(
        `${routes.inventoryScreen}/${newListing.id}/${createListingModalStep}`
      );
}

function* createListingBulkImportWorker({ payload }) {
  const { file } = payload;

  try {
    const response = yield call(makeRequest(service.createListingBulkImport), {
      file,
    });

    if (response.message) {
      if (response.message.logout) {
        yield put(
          addNotificationAction({
            msg: response?.message,
            type: "danger",
          })
        );
        yield put(logoutRequest(response.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: JSON.stringify(response?.message),
            type: "danger",
          })
        );
        yield put(
          createListingBulkImportFailure(JSON.stringify(response?.message))
        );
      }
    } else {
      const { results } = response;

      yield put(fetchInventoryRequest());
      yield put(
        addNotificationAction({
          msg: "Listings are successfully uploaded",
          type: "success",
        })
      );
      yield put(createListingBulkImportSuccess(results));
    }
  } catch (error) {
    yield put(createListingBulkImportFailure({ non_field_errors: "Error" }));

    yield put(
      addNotificationAction({
        msg: JSON.stringify(error),
        type: "danger",
      })
    );
  }
}

function* fetchEditingListingRequestWorker({ payload }) {
  try {
    const listingResponse = yield call(makeRequest(service.fetchListing), {
      id: payload,
    });

    if (listingResponse.message) {
      if (listingResponse.message.logout) {
        yield put(
          addNotificationAction({
            msg: listingResponse?.message,
            type: "danger",
          })
        );
        yield put(logoutRequest(listingResponse.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: listingResponse?.message?.non_field_errors[0],
            type: "danger",
          })
        );
        yield put(fetchEditingListingFailure(listingResponse.message));
      }
    } else {
      yield put(fetchEditingListingSuccess(listingResponse));
    }
  } catch (error) {
    yield put(fetchEditingListingFailure({ non_field_errors: "Error" }));
  }
}

function* fetchEditingListingSuccessWorker({ payload }) {
  yield put(fetchEditingListingImagesRequest({ id: payload?.id }));
}

function* fetchEditingListingImagesRequestWorker({ payload }) {
  const { id: listingId } = payload;

  try {
    const listingResponse = yield call(
      makeRequest(service.fetchListingImages),
      {
        id: listingId,
      }
    );

    if (listingResponse.message) {
      if (listingResponse.message.logout) {
        yield put(
          addNotificationAction({
            msg: listingResponse?.message,
            type: "danger",
          })
        );
        yield put(logoutRequest(listingResponse.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: listingResponse?.message?.non_field_errors[0],
            type: "danger",
          })
        );
        yield put(fetchEditingListingImagesFailure(listingResponse.message));
      }
    } else {
      yield put(fetchEditingListingImagesSuccess(listingResponse));
    }
  } catch (error) {
    yield put(fetchEditingListingImagesFailure({ non_field_errors: "Error" }));
  }
}

function* pushListingIntegrationWorker({ payload }) {
  const { data, platform } = payload;

  try {
    let pushResponse;
    switch (platform) {
      case "etsy":
        pushResponse = yield call(makeRequest(service.pushEtsyListing), data);
        break;

      case "ebay":
        pushResponse = yield call(makeRequest(service.pushEbayListing), data);
        break;

      case "facebook":
        pushResponse = yield call(
          makeRequest(service.pushFacebookListing),
          data
        );
        break;

      case "shopify":
        pushResponse = yield call(
          makeRequest(service.pushShopifyListing),
          data
        );
        break;

      default:
        break;
    }

    if (pushResponse.message) {
      if (pushResponse.message.logout) {
        yield put(
          addNotificationAction({
            msg: pushResponse?.message,
            type: "danger",
          })
        );
        yield put(logoutRequest(pushResponse.message.logout));
      } else {
        yield put(pushListingFailure(platform));

        if (pushResponse.message[0] === "Required fields were created. You should fill them.") {
          const createListingModalStep = 1;
          history.push({
            pathname: `${routes.inventoryScreen}/${data.listing}/${createListingModalStep}`,
            state: { message: pushResponse.message[0] },
          });
          history.go(0);
        } else {
          for (const [key, value] of Object.entries(pushResponse.message)) {
            yield put(
              addNotificationAction({
                msg: `${key}: ${value}`,
                type: "danger",
              })
            );
          }
        }

        // if (pushResponse.message?.listing[0] === "This field must be unique.") {
        //   yield put(
        //     addNotificationAction({
        //       msg: "Such listing already exists",
        //       type: "danger",
        //     })
        //   );
        //   return;
        // }

        
      }
    } else {
      yield put(pushListingSuccess(platform));
      yield put(
        addNotificationAction({
          msg: "Listing was added successfully",
          type: "success",
        })
      );
      yield put(fetchInventoryRequest());
    }
  } catch (error) {
    yield put(pushListingFailure(platform));
  }
}

function* deleteListingWorker({ payload }) {
  const { uid, platform } = payload;
  try {
    let response;

    switch (platform) {
      case "etsy":
        response = yield call(makeRequest(service.deleteEtsyListing), uid);
        break;

      case "ebay":
        response = yield call(makeRequest(service.deleteEbayListing), uid);
        break;

      case "facebook":
        response = yield call(makeRequest(service.deleteFacebookListing), uid);
        break;

      case "shopify":
        response = yield call(makeRequest(service.deleteShopifyListing), uid);
        break;

      case "listacross":
        response = yield call(
          makeRequest(service.deleteListacrossListing),
          uid
        );
        break;

      default:
        break;
    }

    if (response.message) {
      if (response.message.logout) {
        yield put(
          addNotificationAction({
            msg: response?.message,
            type: "danger",
          })
        );
        yield put(logoutRequest(response.message.logout));
      } else {
        for (const [key, value] of Object.entries(response.message)) {
          yield put(
            addNotificationAction({
              msg: `${key}: ${value}`,
              type: "danger",
            })
          );
        }
        yield put(deleteListingFailure(platform));
      }
    } else {
      yield put(deleteListingSuccess(platform));
      yield put(fetchInventoryRequest());
      if (platform === "listacross") {
        yield history.push(routes.inventoryScreen);
      }
      yield put(
        addNotificationAction({
          msg: "Listing was removed successfully",
          type: "success",
        })
      );
    }
  } catch (error) {
    yield put(deleteListingFailure(platform));
  }
}

function* fetchEtsyShippingTemplatesWorker() {
  try {
    const templatesResponse = yield call(
      makeRequest(service.fetchEtsyShippingTemplate)
    );

    if (templatesResponse.message) {
      if (templatesResponse.message.logout) {
        yield put(
          addNotificationAction({
            msg: templatesResponse?.message,
            type: "danger",
          })
        );
        yield put(logoutRequest(templatesResponse.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: templatesResponse?.message?.non_field_errors[0],
            type: "danger",
          })
        );
        yield put(fetchEtsyShippingTemplatesFailure(templatesResponse.message));
      }
    } else {
      yield put(fetchEtsyShippingTemplatesSuccess(templatesResponse.results));
    }
  } catch (error) {
    yield put(fetchEtsyShippingTemplatesFailure({ non_field_errors: "Error" }));
  }
}

function* watchFetchInventory() {
  yield takeEvery(fetchInventoryRequest, fetchInventoryWorker);
}

function* watchFetchCategories() {
  yield takeEvery(fetchCategoriesRequest, fetchCategoriesWorker);
}

function* watchImportListings() {
  yield takeEvery(importListingsRequest, importListingsWorker);
}

function* watchCreateListing() {
  yield takeEvery(createListingRequest, createListingWorker);
}

function* watchCreateListingSuccess() {
  yield takeEvery(createListingSuccess, createListingSuccessWorker);
}

function* watchBulkUpload() {
  yield takeEvery(
    createListingBulkImportRequest,
    createListingBulkImportWorker
  );
}

function* watchFetchEditingListing() {
  yield takeEvery(fetchEditingListingRequest, fetchEditingListingRequestWorker);
}

function* watchFetchEditingListingSuccess() {
  yield takeEvery(fetchEditingListingSuccess, fetchEditingListingSuccessWorker);
}

function* watchFetchEditingListingImages() {
  yield takeEvery(
    fetchEditingListingImagesRequest,
    fetchEditingListingImagesRequestWorker
  );
}

function* watchPushListing() {
  yield takeEvery(pushListingRequest, pushListingIntegrationWorker);
}

function* watchFetchEtsyShippingTemplates() {
  yield takeEvery(
    fetchEtsyShippingTemplatesRequest,
    fetchEtsyShippingTemplatesWorker
  );
}

function* watchDeleteListing() {
  yield takeEvery(deleteListingRequest, deleteListingWorker);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchInventory),
    fork(watchFetchCategories),
    fork(watchImportListings),
    fork(watchCreateListing),
    fork(watchCreateListingSuccess),
    fork(watchBulkUpload),
    fork(watchFetchEditingListing),
    fork(watchFetchEditingListingSuccess),
    fork(watchFetchEditingListingImages),
    fork(watchUpdateListing),
    fork(watchPushListing),
    fork(watchFetchEtsyShippingTemplates),
    fork(watchDeleteListing),
  ]);
}
