import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectorIsPasswordLoading } from "../Selector";

import usersReducer from "store/entities/Users/users.reducer";
import notificationsReducer from "store/entities/Notification/notifications.reducer";

import ContentContainer from "components/simple/ContentContainer";
import Button from "components/simple/Button/Button";
import Input from "components/simple/Input";

import styles from "./password.module.scss";
import helpers from "styles/helpers.module.scss";

const {
  actions: { addNotification },
} = notificationsReducer;

const {
  actions: { changePasswordRequest, changePasswordFailure },
} = usersReducer;

const Password = () => {
  const dispatch = useDispatch();
  const isPasswordLoading = useSelector(selectorIsPasswordLoading);
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword1: "",
    newPassword2: "",
  });

  const setPasswordHandler = (name, value) => {
    setPassword({ ...password, [name]: value });
  };

  const onSubmitChangePassword = () => {
    const {
      currentPassword: old_password,
      newPassword1: new_password,
    } = password;

    if (password.newPassword1 === password.newPassword2) {
      dispatch(
        changePasswordRequest({
          old_password,
          new_password,
        })
      );
    } else {
      dispatch(
        addNotification({
          msg: "New passwords do not match",
          type: "danger",
        })
      );
      dispatch(changePasswordFailure());
    }
  };

  return (
    <ContentContainer className={styles.passwordContainer}>
      <div className={`${helpers.contentRowLine} ${helpers.sectionTitle}`}>
        Change Password
      </div>

      <Input
        name="currentPassword"
        label="Current Password"
        value={password.currentPassword ? password.currentPassword : ""}
        onChange={setPasswordHandler}
        containerClass={styles.passInputContainer}
        type="password"
      />

      <Input
        name="newPassword1"
        label="New Password"
        value={password.newPassword1 ? password.newPassword1 : ""}
        onChange={setPasswordHandler}
        containerClass={styles.passInputContainer}
        type="password"
      />

      <Input
        name="newPassword2"
        label="Repeat New Password"
        value={password.newPassword2 ? password.newPassword2 : ""}
        onChange={setPasswordHandler}
        containerClass={styles.passInputContainer}
        type="password"
      />

      <Button
        onClick={onSubmitChangePassword}
        isLoading={isPasswordLoading}
        className={styles.btn}
      >
        Save
      </Button>
    </ContentContainer>
  );
};

export default Password;
