import { API, authorization, contentTypeJson } from "api/api";
import { get, post, remove, patch } from "api/base";

export const fetchAutoReplies = () => {
  return get(`${API}/inbox/replies/auto-replies`, { ...authorization() });
};

export const updateAutoReply = ({ data, uid }) => {
  return patch(
    `${API}/inbox/replies/auto-replies/${uid}`,
    JSON.stringify(data),
    {
      ...authorization(),
      ...contentTypeJson(),
    }
  );
};

export const createReply = (data) => {
  return post(`${API}/inbox/replies/auto-replies`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
  });
};

export const deleteAutoReply = (uid) => {
  return remove(`${API}/inbox/replies/auto-replies/${uid}`, {
    ...authorization(),
  });
};

export const fetchReplies = () => {
  return get(`${API}/inbox/replies/replies`, { ...authorization() });
};

export const fetchShortcuts = () => {
  return get(`${API}/inbox/replies/shortcuts`, { ...authorization() });
};
