import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  selectorDeleteUserLoading,
  selectorBillingInfo,
  selectorBillingInfoLoading,
  selectorDeleteAddressLoading,
} from "./Selector";
import {
  selectorCards,
  selectorDefaultCard,
  selectorCardsListLoading
} from "selectors/paymentsSelectors";
import {
  selectorProfile,
  selectorProfileLoading,
  selectorTimezones,
  selectorTimezonesLoading,
  selectorCountries,
  selectorCountriesLoading,
} from "selectors/userSelectors";
import { selectorCurrentSubscription } from "selectors/subscriptionSelectors";

import subscriptionReducer from "store/entities/Subscriptions/subscriptions.reducer";
import paymentReducer from "store/entities/Payments/payments.reducer";
import userReducer from "store/entities/Users/users.reducer";
import headerReducer from "store/entities/Header/header.reducer";

import Password from "./Password/Password";
import Address from "./Address/Address";
import Subscription from "./Subscription/Subscription";
import BillingInfo from "./BillingInfo/BillingInfo";

import styles from "./settings.module.scss";
import CardAdding from "./CardAdding/CardAdding";
import Button from "components/simple/Button";
import modalReducer from "store/entities/Modal/modal.reducer";
import DeleteModal from "components/simple/ModalConfirmReject";

const {
  actions: { showModal },
} = modalReducer;

const {
  actions: { setHeaderTitle },
} = headerReducer;

const {
  actions: { subscriptionRequest },
} = subscriptionReducer;

const {
  actions: { fetchCardsRequest, fetchBillingInfoRequest },
} = paymentReducer;

const {
  actions: {
    getUserRequest,
    getTimezonesRequest,
    updateUserTimezoneRequest,
    setNewAddressRequest,
    setNewAddressesOrderRequest,
    deleteAddressRequest,
    deleteUserRequest,
    getCountriesRequest,
  },
} = userReducer;

const Settings = () => {  
  const dispatch = useDispatch();

  const cards = useSelector(selectorCards);
  const profile = useSelector(selectorProfile);
  const isProfileLoading = useSelector(selectorProfileLoading);
  const subscriptionData = useSelector(selectorCurrentSubscription);
  const timezones = useSelector(selectorTimezones);
  const isTimezonesLoading = useSelector(selectorTimezonesLoading);
  const isDeleteUserLoading = useSelector(selectorDeleteUserLoading);
  const isDeleteAddressLoading = useSelector(selectorDeleteAddressLoading);
  const defaultCard = useSelector(selectorDefaultCard);
  const billingData = useSelector(selectorBillingInfo);
  const billingInfoLoading = useSelector(selectorBillingInfoLoading);
  const countries = useSelector(selectorCountries);
  const isCountriesLoading = useSelector(selectorCountriesLoading);
  const isCardsListLoading = useSelector(selectorCardsListLoading);

  useEffect(() => {
    // TODO: Rename actions
    if (!subscriptionData) {
      dispatch(subscriptionRequest());
    }
    if (!profile) {
      dispatch(getUserRequest());
    }
    if (!timezones) {
      dispatch(getTimezonesRequest());
    }
    if (!cards?.length) {
      dispatch(fetchCardsRequest());
    }
    if (!billingData) {
      dispatch(fetchBillingInfoRequest());
    }
    if (!countries) {
      dispatch(getCountriesRequest());
    }
    dispatch(
      setHeaderTitle({
        title: "Settings",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setIsDeleteModalShown = () => {
    dispatch(showModal("deleteAccount"));
  };
  
  const deleteAccountHandler = () => {
    dispatch(deleteUserRequest({ uid: profile?.id }));
  };

  return (
    <>
      <div className={styles.settingsContainer}>
        <Password />
        <Address
          profile={profile}
          timezones={timezones}
          onAddNewAddress={setNewAddressRequest}
          isTimezonesLoading={isTimezonesLoading}
          onUpdateTimezone={updateUserTimezoneRequest}
          isProfileLoading={isProfileLoading}
          onSaveNewAddressesOrder={setNewAddressesOrderRequest}
          onDeleteAddress={deleteAddressRequest}
          isDeleteLoading={isDeleteAddressLoading}
        />
      </div>
      <Subscription subscriptionData={subscriptionData} />
      <BillingInfo
        userId={profile?.id}
        card={defaultCard}
        billingData={billingData}
        billingInfoLoading={billingInfoLoading}
        deleteUserRequest={deleteUserRequest}
        isDeleteUserLoading={isDeleteUserLoading}
      />
      <CardAdding
        profile={profile}
        userId={profile?.id}
        card={defaultCard}
        cards={cards}
        countries={countries}
        isCountriesLoading={isCountriesLoading}
        selectorBillingInfo={selectorBillingInfo}
        isCardsListLoading={isCardsListLoading}
      />
      <div className={styles.deleteButtonContainer}>
        <Button
          onClick={setIsDeleteModalShown}
          variant="alert"
          className={styles.btn}
        >
          Delete Account
        </Button>
      </div>
      <DeleteModal
        type={"deleteAccount"}
        messageText={"Are you sure you want to delete your account? This action is permanent and cannot be undone."}
        confirmBtnText={"Yes"}
        rejectBtnText={"No"}
        onConfirmHandler={deleteAccountHandler}
        isLoading={isDeleteUserLoading}
      />
    </>
  );
};

export default Settings;
