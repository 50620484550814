import React from "react";
import { Provider } from "react-redux";
import { history } from "./router/history";

import { Router } from "react-router";
import AppRouter from "./router/router";

import configureStore from "./store";

import "./App.css";
import "./styles/index.scss";

import Notifications from "components/simple/Notifications";

export const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Router history={history}>
          <AppRouter />
          <Notifications />
        </Router>
      </div>
    </Provider>
  );
}

export default App;
