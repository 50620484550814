import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { routes } from "router";

// Third-party libraries
import { socket, wsConnect, wsSend } from "api/socket";
import moment from "moment";

// Selectors
import { selectorProfile } from "selectors/userSelectors";
import { selectorInventory } from "selectors/inventorySelectors";
import {
  selectorConversations,
  selectorOpenConversation,
  selectorLoadingMessages,
  selectorSearchedConversations,
  selectorSelectedMessages,
  selectorErrorConversations,
} from "./Selector";

// Reducers
import headerReducer from "store/entities/Header/header.reducer";
import messagesReducer from "store/entities/Messages/messages.reducer";
import inventoryReducer from "store/entities/Inventory/inventory.reducer";

// Components
import { ContentContainer, Loader } from "components/simple";
import Chats from "./Chats/Chats";
import TopControls from "./ChatControls/TopControls";
import BottomControls from "./ChatControls/BottomControls";
import Message from "./Message/Message";

// Styles
import styles from "./messages.module.scss";

// Actions
const {
  actions: { setHeaderTitle },
} = headerReducer;
const {
  removeEtsyConversation,
  getMessagesRequest,
  selectedMessageAdd,
  selectedMessageRemove,
  selectedMessagesClear,
  updateMessageRequest,
  setConversations,
  setConversationsError,
  setOpenConversation,
  sendNewMessage,
} = messagesReducer.actions;
const { fetchInventoryRequest } = inventoryReducer.actions;

const Messages = () => {
  const [searchText, setSearchText] = useState("");
  const [searchResultList, setSearchResultList] = useState([]);
  const [searchedMessage, setSearchedMessage] = useState("");
  const [activeConversationId, setActiveConversationId] = useState("");
  const [activeConversationIndex, setActiveConversationIndex] = useState("");
  const [targetUser, setTargetUser] = useState("");
  const [conversationsLoading, setConversationsLoading] = useState(false);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [loadingMessage, setMessageLoading] = useState(false);

  const profile = useSelector(selectorProfile);
  const conversations = useSelector(selectorConversations);
  const conversationsError = useSelector(selectorErrorConversations);

  const openConversation = useSelector(selectorOpenConversation);
  const messagesLoading = useSelector(selectorLoadingMessages);
  const searchedConvs = useSelector(selectorSearchedConversations);
  const selectedMessages = useSelector(selectorSelectedMessages);

  const listings = useSelector(selectorInventory);

  const messagesContainerRef = useRef();
  const messageTimeRef = useRef();
  const searchedMessageRef = useRef();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id: convIdParams } = useParams();
  const searchParams = location.search.split("=")[1];

  const findTargetUserToChat = (conversationId) => {
    const targetUser = conversations.find(
      (conversation) => conversation.id === conversationId
    );
    setTargetUser(targetUser);
  };

  const scrollToSerachedMessage = () => {
    if (searchedMessageRef.current && searchText && searchedMessage) {
      // TODO: think it over
      searchedMessageRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    dispatch(setHeaderTitle({ title: "Messages" }));

    if (!listings.length) dispatch(fetchInventoryRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchResultList(searchedConvs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedConvs]);

  const setActiveConvData = (id, index, message) => {
    if (activeConversationId !== id) {
      setConversationLoading(true);
      setActiveConversationId(id);
      setActiveConversationIndex(index);
      setSearchedMessage(message);
    }
  };

  const scrollOnOpenConv = () => {
    const container = messagesContainerRef.current;
    if (!searchText && !selectedMessages.length && container) {
      // if open mormal conversation
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    if (!convIdParams) {
      setActiveConvData("", "");
      setTargetUser("");
      dispatch(removeEtsyConversation());
      history.push(`${routes.messagesScreen}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convIdParams]);

  useEffect(() => {
    if (activeConversationId) {
      findTargetUserToChat(activeConversationId);
      wsSend({ detail: activeConversationId });
      history.push(
        `${routes.messagesScreen}/${activeConversationId}${
          searchText ? `?search=${searchText}` : ""
        }`
      );
      dispatch(selectedMessagesClear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversationId]);

  // =============================== SOCKETS =================================
  useEffect(() => {
    if (!conversations) {
      convIdParams && history.push(`${routes.messagesScreen}`);
      wsConnect();
      setConversationsLoading(true);
    }

    if (socket) {
      socket.onmessage = function (e) {
        const data = JSON.parse(e.data);
        const conversations = data?.conversations;
        const conversation = data?.conversation;
        const message = data?.message;

        if (conversations) {
          dispatch(setConversations(conversations));
          setConversationsLoading(false);
        } else if (conversation) {
          dispatch(setOpenConversation(conversation));
          setConversationLoading(false);
        } else if (message) {
          dispatch(sendNewMessage(message));
          setMessageLoading(false);
        } else {
          // Error handling
          // think over how to handle different errors
          setConversationsLoading(false);
          dispatch(setConversationsError(data.error));
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMessageHandler = (message) => {
    setMessageLoading(true);
    wsSend(message);
  };

  // =============================== SOCKETS END =================================

  const searchRequestHandler = (search) => {
    dispatch(getMessagesRequest(search));
    setTargetUser("");
    setActiveConvData("", "");
  };

  const cleanSearchHandler = (isParamsToClear) => {
    if (searchText) {
      setSearchText("");
      if (isParamsToClear) history.push(`${routes.messagesScreen}`);
      setActiveConvData("", "");
      setSearchResultList([]);
    }
  };

  const selectMessagesHandler = (message, remove) => {
    if (remove) {
      dispatch(selectedMessageRemove(message));
      return;
    }
    dispatch(selectedMessageAdd(message));
    return;
  };

  const deleteHandler = (conversationId) => {
    if (selectedMessages.length) {
      selectedMessages.forEach((m) => {
        const message = { ...m };
        message.status = "Removed";
        dispatch(updateMessageRequest({ message, searchParams }));
        if (searchParams) setActiveConvData("", "");
      });
      return;
    }
    // ELSE dispatch(DELETE_CONVERSATION({ conversationId }));
  };

  const afterRenderHandler = () => {
    setTimeout(() => {
      scrollOnOpenConv();
      scrollToSerachedMessage();
    }, 0);
  };

  return (
    <ContentContainer className={styles.messagesContainer}>
      <Chats
        error={conversationsError}
        searchText={searchText}
        setSearchText={setSearchText}
        searchResultList={searchResultList}
        searchLoading={messagesLoading}
        onSearchRequest={searchRequestHandler}
        onClearSearch={cleanSearchHandler}
        chats={conversations}
        chatsLoading={conversationsLoading}
        setActiveConvData={setActiveConvData}
        activeChatId={activeConversationId}
        activeConvIndex={activeConversationIndex}
      />
      <div className={styles.chatDetailsWrapper}>
        {targetUser ? (
          <>
            <TopControls
              target={targetUser}
              onDeleteConv={deleteHandler}
              selectedMessages={selectedMessages}
            />
            <div ref={messagesContainerRef} className={styles.dialogWrapper}>
              {conversationLoading ? (
                <Loader className={styles.conversationLoder} />
              ) : openConversation?.messages.length === 0 ? (
                <div className={styles.emptyConversation}>
                  Start the conversation
                </div>
              ) : (
                openConversation?.messages.map((message, index, arr) => {
                  const messageDate = moment(message.created_at).format(
                    "YYYY-MM-DD"
                  );
                  let prevMessageDate;
                  if (messageTimeRef.current) {
                    prevMessageDate = moment(messageTimeRef.current).format(
                      "YYYY-MM-DD"
                    );
                  }

                  const receivedHours = moment(message.created_at).format(
                    "h:mm A"
                  );
                  const receivedDay = moment(message.created_at).format("dddd");
                  const receivedDate = moment(message.created_at).format(
                    "MMMM Do"
                  );

                  messageTimeRef.current = message.created_at;

                  if (index === arr.length - 1) {
                    afterRenderHandler();
                  }

                  const profileData = {
                    name: profile.user.first_name,
                    photo: profile.photo,
                  };

                  return (
                    <div key={message.id}>
                      {(messageDate !== prevMessageDate || index === 0) && (
                        <div
                          className={styles.messageTime}
                        >{`${receivedHours} on ${receivedDay}, ${receivedDate}`}</div>
                      )}

                      <Message
                        message={message}
                        searchedMessageRef={searchedMessageRef}
                        searchedMessage={searchedMessage}
                        profile={profileData}
                        interlocutor={openConversation.name_interlocutor}
                        interlocutorAvatar={targetUser.color}
                        onSelectMessage={selectMessagesHandler}
                      />
                    </div>
                  );
                })
              )}

              {loadingMessage && <Loader className={styles.messageLoader} />}
            </div>
            <BottomControls
              onSendMessage={sendMessageHandler}
              conversationId={targetUser.id}
              // platform={openConversation?.name}
            />
          </>
        ) : (
          <div className={styles.emptyTargetDialog}>Select a conversation</div>
        )}
      </div>
    </ContentContainer>
  );
};

Messages.propTypes = {};

export default Messages;
