import { all, call, spawn } from "redux-saga/effects";

import userSaga from "../entities/Users/users.saga";
import subscriptionSaga from "../entities/Subscriptions/subscriptions.saga";
import paymentsSaga from "../entities/Payments/payments.saga";
import inventorySaga from "../entities/Inventory/inventory.saga";
import platformsSaga from "../entities/Platforms/platforms.saga";
import messagesSaga from "../entities/Messages/messages.saga";
import repliesSaga from "../entities/Replies/replies.saga";

export default function* rootSaga() {
  const sagas = [
    userSaga,
    subscriptionSaga,
    paymentsSaga,
    inventorySaga,
    platformsSaga,
    messagesSaga,
    repliesSaga,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.error(error, "Saga Failed!");
          }
        }
      })
    )
  );
}
