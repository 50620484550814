import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { history, routes } from "router";

import usersReducer from "store/entities/Users/users.reducer";
import subscriptionReducer from "store/entities/Subscriptions/subscriptions.reducer";
import notificationsReducer from "store/entities/Notification/notifications.reducer";

import * as service from "./users.service";
import { makeRequest } from "../../sagas/helpers";

const {
  // Signup
  signupRequest,
  signupSuccess,
  signupFailure,

  // Login
  loginRequest,
  loginSuccess,
  loginFailure,

  // Login Facebook
  loginFacebookRequest,

  // Login Google
  loginGoogleRequest,

  // Logout
  logoutRequest,
  logoutSuccess,

  // Get user profile
  getUserRequest,
  getUserSuccess,
  getUserFailure,

  // Change password
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,

  // Get timezones
  getTimezonesRequest,
  getTimezonesSuccess,
  getTimezonesFailure,

  // Update user Timezone
  updateUserTimezoneRequest,
  updateUserTimezoneSuccess,
  updateUserTimezoneFailure,

  // Add new Address
  setNewAddressRequest,
  setNewAddressSuccess,
  setNewAddressFailure,

  // Save new Addresses Order
  setNewAddressesOrderRequest,
  setNewAddressesOrderSuccess,
  setNewAddressesOrderFailure,

  // Delete Address
  deleteAddressRequest,
  deleteAddressSuccess,
  deleteAddressFailure,

  // Set new Avatar
  setAvatarRequest,
  setAvatarSuccess,
  setAvatarFailure,

  // Reset password
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,

  // Reset password confirm
  resetPasswordConfirmRequest,
  resetPasswordConfirmSuccess,
  resetPasswordConfirmFailure,

  // Activate user
  activateRequest,
  activateSuccess,
  activateFailure,

  // Delete user
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,

  // Get countries
  getCountriesRequest,
  getCountriesSuccess,
  getCountriesFailure,
} = usersReducer.actions;

const { subscriptionRequest } = subscriptionReducer.actions;

const {
  actions: { addNotification: addNotificationAction },
} = notificationsReducer;

// watchers //

function* getUserWorker() {
  try {
    const response = yield call(makeRequest(service.fetchProfile));
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(getUserFailure(response.message));
      }
    } else {
      localStorage.setItem("uid", response.id);
      yield put(getUserSuccess(response));
    }
  } catch (error) {
    yield put(getUserFailure({ non_field_errors: "Error" }));
  }
}

function* signupWorker({ payload }) {
  const { email, firstName, lastName, phone, password } = payload;
  try {
    const signupResponse = yield call(makeRequest(service.signup), {
      email,
      firstName,
      lastName,
      phone,
      password,
    });

    if (signupResponse.message) {
      if (signupResponse.message.logout) {
        yield put(logoutSuccess(signupResponse.message.logout));
      } else {
        yield put(signupFailure(signupResponse.message));
        yield put(
          addNotificationAction({
            msg: JSON.stringify(signupResponse?.message),
            type: "danger",
          })
        );
      }
    } else {
      yield put(signupSuccess(signupResponse));
      yield put(
        addNotificationAction({
          msg: `Successfully Signed Up!
                Check your email and activate your profile via activation link`,
          type: "success",
        })
      );
    }
  } catch (error) {
    yield put(signupFailure({ non_field_errors: "Error" }));
  }
}

function* signupSuccessWorker() {
  yield history.push(routes.loginScreen);
}

function* loginWorker({ payload }) {
  try {
    const { email, password } = payload;
    const loginResponse = yield call(makeRequest(service.login), {
      email,
      password,
    });

    if (loginResponse.message) {
      if (loginResponse.message.logout) {
        yield put(
          addNotificationAction({
            msg: loginResponse?.message?.non_field_errors[0],
            type: "danger",
          })
        );
        yield put(logoutSuccess(loginResponse.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: loginResponse?.message?.non_field_errors[0],
            type: "danger",
          })
        );
        yield put(loginFailure(loginResponse.message));
      }
    } else {
      const { key: token } = loginResponse;
      if (token) {
        localStorage.setItem("token", token);
        yield put(loginSuccess());

        yield put(getUserRequest());
        yield put(subscriptionRequest());
      }
    }
  } catch (error) {
    yield put(loginFailure({ non_field_errors: "Error" }));
  }
}

function* loginSuccessWorker() {
  yield history.push(routes.loginScreen);
}

function* loginGoogleWorker({ payload }) {
  try {
    const { accessToken } = payload;
    const loginResponse = yield call(makeRequest(service.loginGoogle), {
      accessToken,
    });

    if (loginResponse.message) {
      if (loginResponse.message.logout) {
        yield put(
          addNotificationAction({
            msg: loginResponse?.message?.non_field_errors[0],
            type: "danger",
          })
        );
        yield put(logoutSuccess(loginResponse.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: loginResponse?.message?.non_field_errors[0],
            type: "danger",
          })
        );
        yield put(loginFailure(loginResponse.message));
      }
    } else {
      const { token } = loginResponse;
      if (token) {
        localStorage.setItem("token", token);
        yield put(loginSuccess());

        yield put(getUserRequest());
        yield put(subscriptionRequest());
      }
    }
  } catch (error) {
    yield put(loginFailure({ non_field_errors: "Error" }));
  }
}

function* loginFacebookWorker({ payload }) {
  try {
    const { accessToken } = payload;
    const loginResponse = yield call(makeRequest(service.loginFacebook), {
      accessToken,
    });

    if (loginResponse.message) {
      if (loginResponse.message.logout) {
        yield put(
          addNotificationAction({
            msg: loginResponse?.message?.non_field_errors[0],
            type: "danger",
          })
        );
        yield put(logoutSuccess(loginResponse.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: loginResponse?.message?.non_field_errors[0],
            type: "danger",
          })
        );
        yield put(loginFailure(loginResponse.message));
      }
    } else {
      const { token } = loginResponse;
      if (token) {
        localStorage.setItem("token", token);
        yield put(loginSuccess());

        yield put(getUserRequest());
        yield put(subscriptionRequest());
      }
    }
  } catch (error) {
    yield put(loginFailure({ non_field_errors: "Error" }));
  }
}

function* activateWorker({ payload }) {
  try {
    const activateResponse = yield call(makeRequest(service.activate), payload);
    if (activateResponse.message) {
      if (activateResponse.message.logout) {
        yield put(logoutSuccess(activateResponse.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: JSON.stringify(activateResponse.message),
            type: "danger",
          })
        );
        yield put(activateFailure(activateResponse.message));
      }
    } else {
      yield put(
        addNotificationAction({ msg: "Your account is successfully activated" })
      );
      yield put(activateSuccess());
      localStorage.setItem("signupSeccess", true);
    }
  } catch (error) {
    yield put(
      addNotificationAction({
        msg: JSON.stringify(error),
        type: "danger",
      })
    );
    yield put(activateFailure({ non_field_errors: "Error" }));
  }
}

function* changePasswordWorker({ payload }) {
  // eslint-disable-next-line camelcase
  const { old_password, new_password } = payload;
  try {
    const result = yield call(makeRequest(service.changePassword), {
      old_password,
      new_password,
    });

    if (result.message) {
      if (result.message.logout) {
        yield put(logoutSuccess(result.message.logout));
      } else if (result.message.new_password2) {
        yield put(changePasswordFailure(result.message.new_password2[0]));
      } else {
        for (const [key, value] of Object.entries(result.message)) {
          yield put(
            addNotificationAction({
              msg: `${key}: ${value}`,
              type: "danger",
            })
          );
        }
        yield put(changePasswordFailure());
      }
    } else {
      yield put(addNotificationAction({ msg: "Password was changed" }));
      yield put(changePasswordSuccess());
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(changePasswordFailure({ non_field_errors: "Error" }));
  }
}

function* getTimezonesWorker() {
  try {
    const result = yield call(makeRequest(service.fetchTimeZones));

    if (result.message) {
      if (result.message.logout) {
        yield put(logoutSuccess(result.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: result?.message?.password?.toString().split(",").join(" "),
            type: "danger",
          })
        );
        yield put(getTimezonesFailure(result.message));
      }
    } else {
      yield put(getTimezonesSuccess(result.timezones));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(getTimezonesFailure({ non_field_errors: "Error" }));
  }
}

function* getCountriesWorker() {
  try {
    const result = yield call(makeRequest(service.fetchCountries));

    if (result.message) {
      if (result.message.logout) {
        yield put(logoutSuccess(result.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: result?.message?.password?.toString().split(",").join(" "),
            type: "danger",
          })
        );
        yield put(getCountriesFailure(result.message));
      }
    } else {
      yield put(getCountriesSuccess(result));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(getCountriesFailure({ non_field_errors: "Error" }));
  }
}

function* updateTimezoneWorker({ payload }) {
  const { uid, data } = payload;
  try {
    const result = yield call(makeRequest(service.updateTimezone), {
      uid,
      data,
    });

    if (result.message) {
      if (result.message.logout) {
        yield put(logoutSuccess(result.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: result?.message?.non_field_errors,
            type: "danger",
          })
        );
        yield put(updateUserTimezoneFailure());
        yield put(getTimezonesRequest());
      }
    } else {
      yield put(
        addNotificationAction({
          msg: "Timezone was changed successfully",
          type: "success",
        })
      );
      yield put(updateUserTimezoneSuccess());
      yield put(getTimezonesRequest());
      yield put(getUserRequest());
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(updateUserTimezoneFailure());
  }
}

function* addNewAddressWorker({ payload }) {
  try {
    const result = yield call(makeRequest(service.addNewAddress), payload);
    if (result.message) {
      if (result.message.logout) {
        yield put(logoutSuccess(result.message.logout));
      } else {
        for (const [key, value] of Object.entries(result.message)) {
          yield put(
            addNotificationAction({
              msg: `${key}: ${value[0]}`,
              type: "danger",
            })
          );
        }
        yield put(setNewAddressFailure(result.message));
      }
    } else {
      yield put(addNotificationAction({ msg: "New Address was added" }));
      yield put(setNewAddressSuccess(result));
      yield put(getUserRequest());
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(setNewAddressFailure({ non_field_errors: "Error" }));
  }
}

function* saveNewAddressesOrderWorker({ payload }) {
  const { addressesIdArr } = payload;

  try {
    const result = yield call(
      makeRequest(service.saveNewAddressesOrder),
      addressesIdArr
    );

    if (result.message) {
      if (result.message.logout) {
        yield put(logoutSuccess(result.message.logout));
      } else {
        for (const [key, value] of Object.entries(result.message)) {
          yield put(
            addNotificationAction({
              msg: `${key}: ${value}`,
              type: "danger",
            })
          );
        }
        yield put(setNewAddressesOrderFailure(result.message));
        yield put(getUserRequest());
      }
    } else {
      yield put(setNewAddressesOrderSuccess());
      yield put(addNotificationAction({ msg: result, type: "success" }));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(setNewAddressesOrderFailure({ non_field_errors: "Error" }));
  }
}

function* deleteAddressWorker({ payload }) {
  try {
    const result = yield call(makeRequest(service.deleteAddress), payload);
    if (result.message) {
      if (result.message.logout) {
        yield put(logoutSuccess(result.message.logout));
      } else {
        for (const [key, value] of Object.entries(result.message)) {
          yield put(
            addNotificationAction({
              msg: `${key}: ${value}`,
              type: "danger",
            })
          );
        }
        yield put(deleteAddressFailure());
      }
    } else {
      yield put(addNotificationAction({ msg: "Address was deleted" }));
      yield put(deleteAddressSuccess());
      yield put(getUserRequest());
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(deleteAddressFailure());
  }
}

function* setAvatarWorker({ payload }) {
  try {
    const { id: uid, formData: data } = payload;
    const response = yield call(makeRequest(service.setProfileAvatar), {
      uid,
      data,
    });
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        for (const [key, value] of Object.entries(response.message)) {
          yield put(
            addNotificationAction({
              msg: `${key}: ${value}`,
              type: "danger",
            })
          );
        }
        yield put(setAvatarFailure());
      }
    } else {
      yield put(addNotificationAction({ msg: "New Photo was loaded" }));
      yield put(setAvatarSuccess());
      yield put(getUserRequest());
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(setAvatarFailure({ non_field_errors: "Error" }));
  }
}

// function* resetPasswordWorker({ payload }) {
//   try {
//     const reset = yield call(makeRequest(service.resetPassword), payload);

//     if (reset.message) {
//       if (reset.message.logout) {
//         yield put(logoutSuccess(reset.message.logout));
//       } else {
//         yield put(resetPasswordFailed(reset.message));
//         yield put(
//           addNotificationAction({
//             msg: `${
//               typeof reset.message === "string"
//                 ? reset.message
//                 : JSON.stringify(reset.message)
//             }`,
//             type: "danger",
//           })
//         );
//       }
//     } else {
//       yield put(resetPasswordSuccess());
//     }
//   } catch (error) {
//     yield put(addNotificationAction({ msg: "Error", type: "danger" }));
//     yield put(resetPasswordFailed({ non_field_errors: "Error" }));
//   }
// }

// function* logoutWorker({ payload }) {
//   try {
//     yield call(makeRequest(service.logout));
//     yield put(logoutSuccess(payload));
//   } catch (error) {
//     yield put(logoutSuccess(payload));
//   }
// }

// function* createOrganisationWorker({ payload }) {
//   try {
//     const response = yield call(
//       makeRequest(service.createOrganisation),
//       payload
//     );

//     if (response.message) {
//       if (response.message.logout) {
//         yield put(logoutSuccess(response.message.logout));
//       } else {
//         yield put(addNotificationAction({ msg: "Error", type: "danger" }));
//         yield put(createOrganisationFailed(response.message));
//       }
//     } else {
//       yield put(
//         addNotificationAction({ msg: "Organization successfully created" })
//       );
//       yield put(createOrganisationSuccess());
//     }
//   } catch (error) {
//     yield put(addNotificationAction({ msg: "Error", type: "danger" }));
//     yield put(createOrganisationFailed({ non_field_errors: "Error" }));
//   }
// }

// function* createUserWorker({ payload }) {
//   try {
//     const response = yield call(makeRequest(service.createUser), payload);
//     if (response.message) {
//       if (response.message.logout) {
//         yield put(logoutSuccess(response.message.logout));
//       } else {
//         yield put(addNotificationAction({ msg: "Error", type: "danger" }));
//         yield put(createUserFailed(response.message));
//       }
//     } else {
//       yield put(addNotificationAction({ msg: "User successfully created" }));
//       yield put(createUserSuccess());
//     }
//   } catch (error) {
//     yield put(addNotificationAction({ msg: "Error", type: "danger" }));
//     yield put(createUserFailed({ non_field_errors: "Error" }));
//   }
// }
function* resetPasswordWorker({ payload }) {
  try {
    const reset = yield call(makeRequest(service.resetPassword), payload);

    if (reset.message) {
      if (reset.message.logout) {
        yield put(logoutSuccess(reset.message.logout));
      } else {
        yield put(resetPasswordFailure(reset.message));
        yield put(
          addNotificationAction({
            msg: `${
              typeof reset.message === "string"
                ? reset.message
                : JSON.stringify(reset.message)
            }`,
            type: "danger",
          })
        );
      }
    } else {
      yield put(
        addNotificationAction({ msg: "We sent a reset link to your email" })
      );
      yield put(resetPasswordSuccess());
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(resetPasswordFailure({ non_field_errors: "Error" }));
  }
}

function* resetPasswordConfirmWorker({ payload }) {
  // eslint-disable-next-line camelcase
  const { newPassword1, newPassword2, uid, token } = payload;
  try {
    const result = yield call(makeRequest(service.resetPasswordConfirm), {
      newPassword1,
      newPassword2,
      uid,
      token,
    });

    if (result.message) {
      if (result.message.logout) {
        yield put(logoutSuccess(result.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: JSON.stringify(result?.message),
            type: "danger",
          })
        );
        yield put(resetPasswordConfirmFailure(result.message));
      }
    } else {
      yield put(addNotificationAction({ msg: "Password was changed" }));
      yield put(resetPasswordConfirmSuccess());
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(resetPasswordConfirmFailure({ non_field_errors: "Error" }));
  }
}

function* resetPasswordConfirmSuccessWorker() {
  yield history.push(routes.loginScreen);
}

function* logoutWorker({ payload }) {
  try {
    yield call(makeRequest(service.logout));
    yield put(logoutSuccess(payload));
  } catch (error) {
    yield put(logoutSuccess(payload));
  }
}

function* logoutSuccessWorker() {
  yield localStorage.clear();
  yield history.push(routes.loginScreen);
}

function* deleteUserWorker({ payload }) {
  let { uid } = payload;
  try {
    const response = yield call(makeRequest(service.deleteUserProfile), uid);
    if (response.message) {
      yield put(deleteUserFailure());
      for (const [, value] of Object.entries(response.message)) {
        yield put(
          addNotificationAction({
            msg: `${value}`,
            type: "danger",
          })
        );
      }
    } else {
      yield put(deleteUserSuccess());
      yield put(logoutRequest());
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(deleteUserFailure());
  }
}

// function* deleteUserWorker({ payload }) {
//   let { uid } = payload;
//   try {
//     const response = yield call(makeRequest(service.deleteUserProfile), uid);
//     if (response.message) {
//       if (response.message.logout) {
//         yield put(logoutSuccess(response.message.logout));
//       } else {
//         yield put(deleteUserFailure());
//         for (const [, value] of Object.entries(response.message)) {
//           yield put(
//             addNotificationAction({
//               msg: `${value}`,
//               type: "danger",
//             })
//           );
//         }
//       }
//     } else {
//       yield put(deleteUserSuccess());
//       yield put(logoutRequest());
//     }
//   } catch (error) {
//     yield put(addNotificationAction({ msg: "Error", type: "danger" }));
//     yield put(deleteUserFailure());
//   }
// }

// // end-watchers //

function* watchGetUser() {
  yield takeEvery(getUserRequest, getUserWorker);
}

function* watchSignup() {
  yield takeEvery(signupRequest, signupWorker);
}

function* watchSignupSuccess() {
  yield takeEvery(signupSuccess, signupSuccessWorker);
}

function* watchLogin() {
  yield takeEvery(loginRequest, loginWorker);
}

function* watchLoginSuccess() {
  yield takeEvery(loginSuccess, loginSuccessWorker);
}

function* watchLoginGoogle() {
  yield takeEvery(loginGoogleRequest, loginGoogleWorker);
}

function* watchLoginFacebook() {
  yield takeEvery(loginFacebookRequest, loginFacebookWorker);
}

function* watchActivate() {
  yield takeEvery(activateRequest, activateWorker);
}

function* watchChangePassword() {
  yield takeEvery(changePasswordRequest, changePasswordWorker);
}

function* watchGetTimezones() {
  yield takeEvery(getTimezonesRequest, getTimezonesWorker);
}

function* watchUpdateTimezone() {
  yield takeEvery(updateUserTimezoneRequest, updateTimezoneWorker);
}

function* watchGetCountries() {
  yield takeEvery(getCountriesRequest, getCountriesWorker);
}

function* watchAddNewAddress() {
  yield takeEvery(setNewAddressRequest, addNewAddressWorker);
}

function* watchSaveNewAddressesOrder() {
  yield takeEvery(setNewAddressesOrderRequest, saveNewAddressesOrderWorker);
}

function* watchDeleteAddress() {
  yield takeEvery(deleteAddressRequest, deleteAddressWorker);
}

function* watchSetAvatar() {
  yield takeEvery(setAvatarRequest, setAvatarWorker);
}

// function* watchResetPassword() {
//   yield takeEvery(RESET_PASSWORD, resetPasswordWorker);
// }
function* watchResetPassword() {
  yield takeEvery(resetPasswordRequest, resetPasswordWorker);
}

function* watchResetPasswordConfirm() {
  yield takeEvery(resetPasswordConfirmRequest, resetPasswordConfirmWorker);
}

function* watchResetPasswordConfirmSuccess() {
  yield takeEvery(
    resetPasswordConfirmSuccess,
    resetPasswordConfirmSuccessWorker
  );
}

function* watchLogout() {
  yield takeEvery(logoutRequest, logoutWorker);
}

function* watchLogoutSuccess() {
  yield takeEvery(logoutSuccess, logoutSuccessWorker);
}

function* watchDeleteUser() {
  yield takeEvery(deleteUserRequest, deleteUserWorker);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUser),
    fork(watchSignup),
    fork(watchSignupSuccess),
    fork(watchLogin),
    fork(watchLoginSuccess),
    fork(watchLoginGoogle),
    fork(watchLoginFacebook),
    fork(watchActivate),
    fork(watchChangePassword),
    fork(watchGetTimezones),
    fork(watchGetCountries),
    fork(watchUpdateTimezone),
    fork(watchAddNewAddress),
    fork(watchSaveNewAddressesOrder),
    fork(watchDeleteAddress),
    fork(watchSetAvatar),
    // fork(watchResetPassword),
    // fork(watchLogout),
    // fork(watchCreateOrganisation),
    // fork(watchCreateUser),
    fork(watchResetPassword),
    fork(watchResetPasswordConfirm),
    fork(watchResetPasswordConfirmSuccess),
    fork(watchLogout),
    fork(watchLogoutSuccess),
    fork(watchDeleteUser),
  ]);
}
