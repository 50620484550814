import React from "react";

import BootstrapModal from "react-bootstrap/Modal";

const Modal = ({
  children,
  setShow,
  show,
  isConntected = false,
  containerStyles,
  contentStyles,
}) => {
  return (
    <BootstrapModal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName={containerStyles}
      contentClassName={contentStyles}
      aria-labelledby="example-custom-modal-styling-title"
      centered={true}
    >
      {children}
    </BootstrapModal>
  );
};

export default Modal;
