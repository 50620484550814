import React from "react";

import ContentContainer from "components/simple/ContentContainer";

import styles from "./firstEnter.module.scss";

import welcomeIcon from "assets/icons/welcome.png";
import closeIcon from "assets/icons/plus-icon.png";

const FirstEnter = ({ closeHandler }) => {
  return (
    <ContentContainer className={styles.firstEnterWrapper}>
      <div className={styles.welcomeImg}>
        <img src={welcomeIcon} alt="welcome" />
      </div>
      <div className={styles.welcomeText}>
        <div>Success! Thank you for signing up.</div>
        <div>
          You’ve successfully joined ListAcross with a Basic subscription.
          You’ll only be charged when you publish your first listing.
        </div>
      </div>
      <div className={styles.closeBtn} onClick={closeHandler}>
        <img src={closeIcon} alt="close" />
      </div>
    </ContentContainer>
  );
};

export default FirstEnter;
