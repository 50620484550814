import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useValidate } from "hooks/useValidation";

import { history } from "router";
import * as routes from "router/routes";

// Reducers
import inventoryReducer from "store/entities/Inventory/inventory.reducer";
import platformsReducer from "store/entities/Platforms/platforms.reducer";
import headerReducer from "store/entities/Header/header.reducer";
import notificationsReducer from "store/entities/Notification/notifications.reducer";

// Selectors
import {
  selectorInventory,
  selectorCreateLoading,
  selectorEditingImages,
  selectorEditingImagesLoading,
} from "./Selector";
import { selectorProfile } from "selectors/userSelectors";
import {
  selectorCategories,
  selectorEditListingLoading,
} from "selectors/inventorySelectors";
import { selectorChannels } from "selectors/platformsSelectors";

// Components
import ImageUploading from "react-images-uploading";
import {
  ContentContainer,
  Button,
  Input,
  Textarea,
  SelectInput,
  Info,
  Loader,
  ListingSteps,
} from "components/simple";

import styles from "./create-listing.module.scss";

import arrowLeftIcon from "assets/icons/arrow-left.png";
import arrowRightIcon from "assets/icons/arrow-right.png";
import plusIcon from "assets/icons/plus-icon.png";
import crossIcon from "assets/icons/cross-small-icon.png";

// Actions
const {
  actions: {
    fetchCategoriesRequest,
    createListingRequest,
    updateListingRequest,
    fetchEditingListingRequest,
    deleteImageRequest,
    duplicateListingRequest
  },
} = inventoryReducer;
const {
  actions: { getChannelsRequest },
} = platformsReducer;
const {
  actions: { setHeaderTitle },
} = headerReducer;
const {
  actions: { addNotification },
} = notificationsReducer;

function CreateListing() {
  const initialState = {
    listingTitle: "",
    listingDescription: "",
    category: "",
    location: "",
    price: 0,
    sellAs: "single",
    quantity: 1,
    status: "active",
    sku: "",
  };

  const [state, setState] = useState({
    ...initialState,
  });

  const [images, setImages] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const maxPhotosNumber = 10;

  const [errors, setErrors] = useState({});

  const [isPriceEmpty, setPriceEmpty] = useState(true);

  const inventory = useSelector(selectorInventory);
  const profile = useSelector(selectorProfile);
  const categories = useSelector(selectorCategories);
  const channels = useSelector(selectorChannels);

  const editListingLoading = useSelector(selectorEditListingLoading);
  const createListingLoading = useSelector(selectorCreateLoading);

  const editingImages = useSelector(selectorEditingImages);
  const editingImagesLoading = useSelector(selectorEditingImagesLoading);
  const { editingListing } = inventory;

  const dispatch = useDispatch();
  const { id: listingId, step: listingStep } = useParams();
  const validate = useValidate();

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  useEffect(() => {
    if (images.length === 10) {
      dispatch(
        addNotification({
          msg: `Maximum number of photos is ${maxPhotosNumber}`,
          type: "danger",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (listingId) {
      dispatch(fetchEditingListingRequest(listingId));
      dispatch(setHeaderTitle({ title: "Edit Listing" }));
    } else {
      dispatch(setHeaderTitle({ title: "Create Listing" }));
    }
    if (!categories.length) dispatch(fetchCategoriesRequest());
    if (!channels) dispatch(getChannelsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editingImages) {
      setImages(editingImages.images);
    }
  }, [editingImages]);

  useEffect(() => {
    const sellAs = state.sellAs;

    if (listingId) {
      setState({
        ...state,
        quantity:
          sellAs === "single"
            ? "1"
            : sellAs === "out"
            ? "0"
            : inventory?.editingListing?.quantity || {},
      });
    } else {
      setState({
        ...state,
        quantity: sellAs === "single" ? "1" : sellAs === "out" ? "0" : "2",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sellAs]);

  useEffect(() => {
    if (listingId && editingListing) {
      const {
        title,
        description,
        category,
        location,
        price,
        quantity,
        status,
        // fields,
        sku,
      } = editingListing;

      setState({
        ...state,
        listingTitle: title,
        listingDescription: description,
        category,
        location,
        price: Math.round(price),
        quantity,
        status,
        sku,
        sellAs: quantity === 0 ? "out" : quantity === 1 ? "single" : "stock",
      });

      if (Number(price) > 0) setPriceEmpty(false);
    } else {
      setState({
        ...state,
        ...initialState,
      });
      setImages([]);
    }

    if (!listingId) {
      dispatch(setHeaderTitle({ title: "Create Listing" }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingListing, listingId]);

  // const defineQuantityHandler = (quantity) => {
  //   setState({
  //     ...state,
  //     quantity,
  //     sellAs: quantity === 0 ? "out" : quantity === 1 ? "single" : "stock",
  //   });
  // };

  const blurInputQuantityHandler = () => {
    if (state.quantity < 2 && state.sellAs === "stock") {
      setErrors({
        ...errors,
        quantity: "Quantity can not be less then 2 in stock",
      });
    } else {
      setErrors({
        ...errors,
        quantity: "",
      });
    }
  };

  const priceInputFocusHandler = (price) => {
    if (Number(price) === 0) {
      setPriceEmpty(false);
      setState({
        ...state,
        price: "",
      });
      setErrors({
        ...errors,
        price: "",
      });
    }
  };

  const priceRegex = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;

  const priceInputBlurHandler = (price) => {
    if (!priceRegex.test(price)) {
      setErrors({
        ...errors,
        price: "Must contain only numbers",
      });
      return;
    } else {
      setErrors({
        ...errors,
        price: "",
      });
    }

    if (!price || Number(price) === 0) {
      setPriceEmpty(true);
      setState({
        ...state,
        price: 0,
      });
      return;
    }

    if (!!(Number(price) % 1)) {
      setState({
        ...state,
        price: Math.abs(Math.ceil(price)),
      });
    }
  };

  const handleInputChange = (name, value) => {
    setState({
      ...state,
      [name]:
        name === "price"
          ? value
            ? priceRegex.test(value.slice(2, value.length))
              ? value.slice(2, value.length)
              : state.price
            : ""
          : value,
    });
  };

  // const handleSubmitDuplicate = (e) => {
  //   e.preventDefault()

  //   console.log('listing', inventory?.editingListing?.status)
    
  //   dispatch(
  //     duplicateListingRequest({
  //       listingId: listingId,
  //     })
  //   );
  // };

  const handleSubmit = (e, draft) => {
    e.preventDefault();
    // const status = draft ? "inactive" : 'active';
    // const status = draft && !listingId ? "inactive" : 'active';
    // const status = draft && !listingId ? "inactive" : inventory?.editingListing?.status ? inventory?.editingListing?.status : 'active';

    let status;

    if (draft) {
      if (listingId) {
        status = inventory?.editingListing?.status
      } else {
        status = 'inactive'
      }
    } else {
      status = 'active'
    }

    // #TODO: change to all fields validation
    if (!images.length) {
      dispatch(
        addNotification({
          msg: "You need to add at least one photo",
          type: "danger",
        })
      );
      return;
    }

    const isError = onSubmitValidate(state);

    if (isError) return;

    const { listingTitle: title, listingDescription: description } = state;

    let imageFiles = images;

    // TODO: Fetch images when updating listing
    if (listingId) {
      imageFiles = images.filter((image) => image.file);

      if (imageFiles.length > 0) {
        dispatch(
          updateListingRequest({
            action: "continue",
            listingStep: 0,
            status: status === "active",
            listingId,
            images: imageFiles,
            draft,
            fields: {
              ...state,
              status,
              title,
              description,
            },
          })
        );
      } else {
        dispatch(
          updateListingRequest({
            action: "continue",
            listingStep: 0,
            status: status === "active",
            listingId,
            draft,
            fields: {
              ...state,
              status,
              title,
              description,
            },
          })
        );
      }

      if (draft) {
        dispatch(
          duplicateListingRequest({
            listingId: listingId,
          })
        );
      }

      // update
    } else {
      // create
      dispatch(
        createListingRequest({
          images: images,
          fields: {
            ...state,
            status,
            title,
            description,
          },
        })
      );
    }
  };

  const onSubmitValidate = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      const isError = validate(key, value, errors, setErrors);
      if (isError) return isError;
    }
  };

  const onValidateSelect = (name, value) =>
    validate(name, value, errors, setErrors);

  const handleCancel = () => {
    if (listingId) {
      history.push(routes.inventoryScreen);
    }
  };

  const categoriesOptions = inventory?.categoriesList?.map((category) => ({
    value: category?.id,
    label: category.name,
  }));

  const locationOptions = profile?.addresses?.map((address) => ({
    value: address?.id,
    label: `${address?.address_line_1} ${address?.address_line_2}, ${address?.city}, ${address?.state}, ${address?.zip_code}`,
  }));

  const noLocationsOptionsHandler = () => {
    return <Link to="/settings">Add your first location here</Link>;
  };

  const removeImageChecker = (uid, deleteCallback, index) => {
    const trigger = images.find((i) => !i.file && i.id === uid);

    trigger
      ? dispatch(
          deleteImageRequest({
            uid,
            deleteCallback,
            index,
          })
        )
      : deleteCallback(index);
  };

  const sellAs = {
    stock: "In-Stock",
    single: "Single Item",
    out: "Out of Stock",
  };

  const sellAsOptions = Object.entries(sellAs).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  return (
    <div className={styles.container}>
      <ContentContainer className={styles.panel}>
        <div className={styles.panelTitle}>Featured Photo</div>
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxPhotosNumber}
          dataURLKey="image"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <>
              <div className={styles.galleryContainer}>
                {editingImagesLoading ? (
                  <div className={styles.imgLoader}>
                    <Loader />
                  </div>
                ) : imageList?.length ? (
                  <img
                    className={styles.galleryView}
                    src={imageList[selectedPhoto]?.image}
                    alt="listing1"
                  />
                ) : null}
                <Button
                  variant="secondary"
                  className={styles.uploadButton}
                  onClick={onImageUpload}
                >
                  Upload Photo
                </Button>
                <div className={styles.navButtons}>
                  <Button
                    variant="secondary"
                    className={styles.navButton}
                    onClick={() => {
                      if (selectedPhoto > 0) {
                        setSelectedPhoto(selectedPhoto - 1);
                      }
                    }}
                  >
                    <img src={arrowLeftIcon} alt="navigate to left" />
                  </Button>
                  <Button
                    variant="secondary"
                    className={styles.navButton}
                    onClick={() => {
                      if (selectedPhoto < imageList.length - 1) {
                        setSelectedPhoto(selectedPhoto + 1);
                      }
                    }}
                  >
                    <img src={arrowRightIcon} alt="navigate to left" />
                  </Button>
                </div>
              </div>
              <div className={styles.galleryPreviewContainer}>
                {imageList.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className={`${styles.galleryPreview} ${
                        index === selectedPhoto ? styles.selected : ""
                      }`}
                    >
                      <img
                        className={styles.photoPreview}
                        src={image.image}
                        alt="preview"
                        onClick={() => {
                          setSelectedPhoto(index);
                        }}
                      />
                      <Button
                        className={styles.removePreviewButton}
                        onClick={() => {
                          if (
                            imageList.length > 1 &&
                            selectedPhoto > imageList.length - 2
                          ) {
                            setSelectedPhoto(imageList.length - 2);
                          }

                          removeImageChecker(
                            imageList[index].id,
                            onImageRemove,
                            index
                          );
                        }}
                      >
                        <img src={crossIcon} alt="remove" />
                      </Button>
                    </div>
                  );
                })}
                <Button
                  className={styles.addImageButton}
                  onClick={onImageUpload}
                >
                  <img src={plusIcon} alt="add new" />
                </Button>
              </div>
            </>
          )}
        </ImageUploading>
        <div className={styles.hint}>
          You Can Upload Multiple Photos at Once
        </div>
      </ContentContainer>
      <ContentContainer className={styles.panel}>
        <form>
          <div className={styles.panelTitle}>Product Information</div>
          <div className={styles.formContainer}>
            <Input
              name="listingTitle"
              label={"Title"}
              value={state.listingTitle}
              onChange={handleInputChange}
              onBlur={() =>
                validate("listingTitle", state.listingTitle, errors, setErrors)
              }
              error={errors.listingTitle}
              containerClass={styles.field}
            />
            <Textarea
              name="listingDescription"
              label={"Product Description"}
              value={state.listingDescription}
              onChange={handleInputChange}
              error={errors.listingDescription}
              onBlur={() =>
                validate(
                  "listingDescription",
                  state.listingDescription,
                  errors,
                  setErrors
                )
              }
              containerClass={styles.field}
            />
            <SelectInput
              name="category"
              label={"Category"}
              value={state.category}
              options={categoriesOptions}
              onChange={handleInputChange}
              onValidate={onValidateSelect}
              error={errors.category}
              containerClass={styles.field}
              required
            />
            <SelectInput
              name="location"
              label={"Location"}
              placeholder={"Where is the item located?"}
              value={state.location}
              onChange={handleInputChange}
              options={locationOptions}
              noOptionsMessage={noLocationsOptionsHandler}
              onValidate={onValidateSelect}
              error={errors.location}
              containerClass={styles.field}
              required
            />
            <div className={`${styles.priceWrapper} ${styles.field}`}>
              <Input
                name="price"
                label={"Price"}
                // labelAlwaysActive
                value={`$ ${state.price}`}
                onChange={handleInputChange}
                // type="number"
                // min={0}
                // step="0.01"
                error={errors.price}
                inputClass={isPriceEmpty ? styles.emptyInput : ""}
                onClick={() => priceInputFocusHandler(state.price)}
                onBlur={() => priceInputBlurHandler(state.price)}
              />
              <div className={styles.priceInfo}>
                <Info title="Price" description="test" />
              </div>
            </div>

            <SelectInput
              label={"Sell As"}
              name="sellAs"
              value={state.sellAs}
              onChange={handleInputChange}
              options={sellAsOptions}
              containerClass={styles.field}
            />

            <Input
              name="quantity"
              type="number"
              label={"Quantity"}
              value={state.quantity}
              onChange={handleInputChange}
              onBlur={() => blurInputQuantityHandler()}
              error={errors.quantity}
              disabled={state.sellAs === "single" || state.sellAs === "out"}
              containerClass={styles.field}
            />

            <Input
              name="sku"
              type="text"
              label={"SKU"}
              value={state.sku}
              onChange={handleInputChange}
              // onBlur={() => validate("sku", state.sku, errors, setErrors)}
              // error={errors.sku}
              containerClass={styles.field}
            />
          </div>
          {/* <div className={styles.infoFormButtons}> */}
          <div className={styles.lol}>
            {editListingLoading || createListingLoading ? (
              <Loader />
            ) : (
              <>
                <div className={`${styles.controls}`}>
                  <Button
                    onClick={handleSubmit}
                    className={styles.btn}
                    variant="primary"
                  >
                    Save & Continue
                  </Button>
                  <Button
                    onClick={(e) => handleSubmit(e, true)}
                    className={styles.btn}
                    variant="secondary"
                  >
                    Save Draft
                  </Button>
                  {/* <Button
                    onClick={(e) => handleSubmitDuplicate(e)}
                    className={styles.btn}
                    variant="secondary"
                  >
                    Duplicate
                  </Button> */}
                  <Button
                    onClick={handleCancel}
                    className={styles.btn}
                    variant="alert"
                    disabled={!listingId}
                  >
                    Cancel
                  </Button>
                </div>

                <ListingSteps step={listingStep} isControlsVisible={false} />
              </>
            )}
          </div>
        </form>
      </ContentContainer>
    </div>
  );
}

CreateListing.propTypes = {};

export default CreateListing;
