import React from "react";
import PropTypes from "prop-types";

export default function ArrowDown(props) {
  return (
    <svg
      style={props.style}
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 88.95 61.06`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0,-952.36218)">
        <path
          className="st0"
          d="M33.51,952.86c1.7,0,3,1.4,3,3v10.8c18.2,5,43.4,16.4,51.8,42.3c0.3,1,0.1,2.1-0.6,2.9c-1.1,1.3-3,1.4-4.2,0.3
		c-17-14.9-40.3-16.2-47-16.2v10c0,0.7-0.2,1.3-0.7,1.9c-1.1,1.3-2.9,1.5-4.2,0.4l-30-25c-0.1-0.1-0.3-0.2-0.4-0.4
		c-1.1-1.3-0.9-3.2,0.4-4.2l30-25c0.4-0.4,1-0.6,1.5-0.7C33.31,952.86,33.41,952.86,33.51,952.86z M30.51,962.26l-22.3,18.6
		l22.3,18.6v-6.6c0-1.6,1.2-2.9,2.8-3c1.9-0.1,24.4-0.9,44.5,10.9c-10.5-16.9-30.4-25.3-45.1-29c-1.3-0.3-2.3-1.5-2.2-2.9
		L30.51,962.26L30.51,962.26z"
        />
      </g>
    </svg>
  );
}

ArrowDown.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowDown.defaultProps = {
  className: "",
  width: 11.98,
  height: 11.23,
};
