import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "router";

import {
  selectorPlans,
  selectorCurrentSubscription,
} from "selectors/subscriptionSelectors";

import subscriptionsReducer from "store/entities/Subscriptions/subscriptions.reducer";
import headerReducer from "store/entities/Header/header.reducer";

import ContentContainer from "components/simple/ContentContainer";
import PlanCard from "./PlanCard/PlanCard";
import Loader from "components/simple/Loader/Loader";

import styles from "./selectNewPlan.module.scss";
import helpers from "styles/helpers.module.scss";

const { fetchPlansRequest, subscriptionRequest } = subscriptionsReducer.actions;

const {
  actions: { setHeaderTitle },
} = headerReducer;

const SelectNewPlan = ({ history }) => {
  const plansList = useSelector(selectorPlans);
  const currentSubscription = useSelector(selectorCurrentSubscription);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!plansList) {
      dispatch(fetchPlansRequest());
    }

    if (!currentSubscription) {
      dispatch(subscriptionRequest());
    }

    // TODO: get linkBack from the "from" params
    dispatch(
      setHeaderTitle({
        title: "Select New Plan",
        linkBack: `${routes.myProfileScreen.split("/")[1]}`,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectNewSubscriptionHandler = (plan_id) => {
    if (plan_id) {
      history.push(`${routes.paymentScreen}/${plan_id}`);
    } else {
      alert("We will email you.");
    }
  };

  return (
    <ContentContainer className={styles.selectNewPlanScreen}>
      <div className={styles.title}>Choose Your Subscription Plan</div>
      <div className={`${styles.subtitle} ${helpers.contentRowLine}`}>
        Sign Up Now. Upgrade Anytime!
      </div>
      <div className={styles.cardContainer}>
        {plansList ? (
          <>
            {plansList.map((plan) => {
              return (
                <ContentContainer key={plan.id} className={styles.card}>
                  <PlanCard
                    plan={plan}
                    currentPlan={currentSubscription?.active_plan?.id}
                    selectNewSubscriptionHandler={selectNewSubscriptionHandler}
                  />
                </ContentContainer>
              );
            })}
            <ContentContainer className={styles.card}>
              <PlanCard
                plan={{
                  non_standart: true,
                  plan_title: "Lets Talk!",
                  plan_type: "Enterprise",
                  max_integrations: "All",
                  short_description: "For High-Volume Retailers",
                }}
                selectNewSubscriptionHandler={selectNewSubscriptionHandler}
              />
            </ContentContainer>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </ContentContainer>
  );
};

export default SelectNewPlan;
