import React from "react";
import PropTypes from "prop-types";

export default function ArrowDown(props) {
  return (
    <svg
      style={props.style}
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 36 44`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="M36,42c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-7.73-6.27-14-14-14S4,34.27,4,42c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-9.94,8.06-18,18-18
			S36,32.06,36,42z M18,22c-6.08,0-11-4.92-11-11S11.92,0,18,0s11,4.92,11,11S24.08,22,18,22z M18,18c3.87,0,7-3.13,7-7s-3.13-7-7-7
			s-7,3.13-7,7S14.13,18,18,18z"
          />
        </g>
      </g>
    </svg>
  );
}

ArrowDown.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowDown.defaultProps = {
  className: "",
  width: 11.98,
  height: 11.23,
};
