import React from "react";

import { Button, Modal } from "components/simple";
import BootstrapModal from "react-bootstrap/Modal";

import modalStyles from "./infoModal.module.scss";
import helpers from "styles/helpers.module.scss";

function infoModal({
  isInfoPopupShown,
  closeModalHandler,
  submitModalHandler,
}) {
  return (
    <div>
      <Modal
        setShow={closeModalHandler}
        show={isInfoPopupShown}
        containerStyles={modalStyles.modalContainer}
        contentStyles={modalStyles.modalContent}
      >
        <BootstrapModal.Body bsPrefix={modalStyles.modalBody}>
          <div className={modalStyles.leftSide}></div>

          <div className={modalStyles.rightSide}>
            <div className={`${helpers.contentRowLine} ${modalStyles.title}`}>
              Add Placeholders
            </div>
            <div className={modalStyles.text}>
              {`Fill in the variables in the right place. Example: 
              "The price for the {{Title Listing}} is {{Price Listing}}."`}
            </div>
            <Button
              onClick={submitModalHandler}
              className={modalStyles.saveBtn}
            >
              Okay, got it!
            </Button>
          </div>
        </BootstrapModal.Body>
      </Modal>
    </div>
  );
}

export default infoModal;
