import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useValidate } from "hooks/useValidation";

import modalReducer from "store/entities/Modal/modal.reducer";

import {
  ContentContainer,
  Button,
  ModalConfirmReject as DeleteModal,
  Loader,
  SelectInput,
} from "components/simple";
import DragNDrop from "./DragAndDrop/DragNDrop";
import AddAddressModal from "./addAddressModal/AddAddressModal";

import styles from "./address.module.scss";
import password from "../Password/password.module.scss";
import helpers from "styles/helpers.module.scss";

const {
  actions: { showModal },
} = modalReducer;

const Address = ({
  profile,
  timezones,
  onAddNewAddress,
  isTimezonesLoading,
  onUpdateTimezone,
  isProfileLoading,
  onSaveNewAddressesOrder,
  onDeleteAddress,
  isDeleteLoading,
}) => {
  const dispatch = useDispatch();
  const validate = useValidate();

  const [addressToDelete, setAddressToDelete] = useState("");
  const [isAddressPopupShown, setIsAddressPopupShown] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [modalValues, setModalValues] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [modalErrors, setModalErrors] = useState({});

  // Add Address Modal
  const modalInputsChangeHandler = (name, value) => {
    setModalValues({ ...modalValues, [name]: value });
  };

  const submitAddressModalHandler = () => {
    const isError = onSubmitValidate(modalValues);
    if (isError) return;

    postAddressHandler();
  };

  const onSubmitValidate = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      const isError = validate(key, value, modalErrors, setModalErrors);
      if (isError) return isError;
    }
  };

  const postAddressHandler = () => {
    const body = {
      address_line_1: modalValues.address1,
      address_line_2: modalValues.address2,
      city: modalValues.city,
      state: modalValues.state,
      zip_code: modalValues.zipCode,
      id: Math.random() * 10,
    };
    dispatch(onAddNewAddress(body));
    closeModalHandler();
  };

  const saveNewAddressesOrderHandler = (arr) => {
    const addressesIdArr = arr.map((address) => address.id);
    dispatch(onSaveNewAddressesOrder({ addressesIdArr }));
  };

  const closeModalHandler = () => {
    setIsAddressPopupShown(false);
    setModalErrors({});
    setModalValues({
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
    });
  };

  // end

  const setCurrentTimezoneHandler = () => {
    for (let i = 0; i < timezones.length; i++) {
      if (timezones[i].split(" (")[0] === profile.timezone) {
        setTimezone(timezones[i]);
      }
    }
  };

  const selectTimezoneHandler = (_, value) => {
    setTimezone(value);
    dispatch(
      onUpdateTimezone({
        uid: profile.id,
        data: { timezone: value.split(" (")[0] },
      })
    );
  };

  useEffect(() => {
    if (timezones && profile) {
      setCurrentTimezoneHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezones, profile]);

  const timezonesOptions = timezones?.map((timezone) => ({
    value: timezone,
    label: timezone,
  }));

  const deleteAddressHandler = () => {
    dispatch(onDeleteAddress(addressToDelete));
  };

  const setIsDeleteModalShown = (uid) => {
    dispatch(showModal("deleteAddress"));
    setAddressToDelete(uid);
  };

  return (
    <ContentContainer className={styles.addressContainer}>
      {!isTimezonesLoading && !isProfileLoading ? (
        <>
          <div className={`${helpers.contentRowLine} ${styles.titleBlock}`}>
            <div className={helpers.sectionTitle}>Address & Timezone</div>
            <Button
              onClick={() => setIsAddressPopupShown(true)}
              className={styles.btn}
              variant="transparent"
            >
              Add Address
            </Button>
          </div>

          <div className={`${password.inputContainer}`}>
            {profile?.addresses.length ? (
              <>
                <label>Drag addresses in a priority order</label>

                <DragNDrop
                  onSaveNewOrder={saveNewAddressesOrderHandler}
                  onDeleteAddress={setIsDeleteModalShown}
                  addresses={profile.addresses}
                />
              </>
            ) : (
              <label className={styles.noAddresses}>
                Add new shipping address
              </label>
            )}
          </div>

          <SelectInput
            label={"Timezones"}
            name="timezones"
            value={timezone}
            onChange={selectTimezoneHandler}
            options={timezonesOptions}
            containerClass={styles.timezonesContainer}
          />

          <DeleteModal
            type={"deleteAddress"}
            messageText={"Are you sure you want to delete your address?"}
            confirmBtnText={"Yes"}
            rejectBtnText={"No"}
            onConfirmHandler={deleteAddressHandler}
            isLoading={isDeleteLoading}
          />

          <AddAddressModal
            state={modalValues}
            validate={validate}
            errors={modalErrors}
            setErrors={setModalErrors}
            isAddressPopupShown={isAddressPopupShown}
            closeModalHandler={closeModalHandler}
            modalInputsChangeHandler={modalInputsChangeHandler}
            submitHandler={submitAddressModalHandler}
          />
        </>
      ) : (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
    </ContentContainer>
  );
};

export default Address;
