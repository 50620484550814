import React from "react";
import PropTypes from "prop-types";

export default function CloseX(props) {
  return (
    <svg
      style={{ transform: "rotate(45deg)" }}
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      fill="none"
      viewport="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1={props.width}
        x2={props.width}
        stroke={props.fill}
        strokeWidth="2.5"
      />
      <line
        x2={props.height}
        y2={props.height}
        stroke={props.fill}
        strokeWidth="2.5"
      />
    </svg>
  );
}

CloseX.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

CloseX.defaultProps = {
  className: "",
  width: 16,
  height: 16,
  fill: "#5f7fd3",
};
