import { API, authorization } from "api/api";
import { get, put } from "api/base";

export const fetchAvailablePlans = () => {
  return get(`${API}/subscriptions/plans`, { ...authorization() });
};

export const fetchCurrentSubscription = () => {
  return get(`${API}/subscriptions/user-subscription`, {
    ...authorization(),
  });
};

export const selectNewSubscription = (uid, body) => {
  return put(`${API}/payments/subscriptions/${uid}`, body, {
    ...authorization(),
  });
};
