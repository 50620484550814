import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import {
  selectorEbayConnectUrl,
  selectorEbayConnectUrlError,
  selectorEbayConnectUrlLoading,
} from "../../Selector";

import * as routes from "router/routes";

import platformsReducer from "store/entities/Platforms/platforms.reducer";

import { Button, Loader } from "components/simple";

import styles from "../integrationScreen.module.scss";

const {
  fetchEbayCallbackRequest,
  getEbayConnectUrlRequest,
} = platformsReducer.actions;

const Ebay = ({ marketplaceName, channelId, statusConnection, redirect }) => {
  const ebayConnectUrl = useSelector(selectorEbayConnectUrl);
  const isEbayUrlLoading = useSelector(selectorEbayConnectUrlLoading);
  const urlError = useSelector(selectorEbayConnectUrlError);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const credentials = location.search;

    if (!credentials && !statusConnection) {
      dispatch(getEbayConnectUrlRequest());
    }

    if (credentials) {
      dispatch(
        fetchEbayCallbackRequest({
          credentials,
        })
      );
      history.push(`${routes.integrationsScreen}/${channelId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isEbayUrlLoading || redirect ? (
        <Loader />
      ) : (
        <>
          {statusConnection ? (
            <div
              className={styles.connectMessage}
            >{`Your ${marketplaceName} account is now connected to ListAcross`}</div>
          ) : urlError ? (
            <div>Connection error happened! Please try again later...</div>
          ) : (
            <div className={styles.connectBtnWrapper}>
              <a href={ebayConnectUrl} target="_blank" rel="noreferrer">
                <Button className={styles.connectBtn}>Connect Channel</Button>
              </a>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Ebay;
