import { API, authorization, contentTypeJson } from "api/api";
import { get, post, patch, remove } from "api/base";

export const signup = ({ email, firstName, lastName, phone, password }) => {
  return post(
    `${API}/userprofiles/registration/`,
    JSON.stringify({
      user: {
        email,
        first_name: firstName,
        last_name: lastName,
        password,
      },
      phone_number: phone,
    }),
    {
      ...contentTypeJson(),
    }
  );
};

// Login
export const login = (fields) => {
  return post(`${API}/userprofiles/login/`, JSON.stringify(fields));
};

// Login Facebook
export const loginFacebook = ({ accessToken }) => {
  return post(
    `${API}/userprofiles/facebook-login/`,
    JSON.stringify({ access_token: accessToken })
  );
};

// Login Google
export const loginGoogle = ({ accessToken }) => {
  return post(
    `${API}/userprofiles/google-login/`,
    JSON.stringify({ access_token: accessToken })
  );
};

export const activate = ({ token, password }) => {
  return post(
    `${API}/userprofiles/activate-userprofile/`,
    JSON.stringify({ token, password })
  );
};

export const changePassword = (body) => {
  return post(`${API}/userprofiles/password/change/`, JSON.stringify(body), {
    ...authorization(),
    ...contentTypeJson(),
  });
};

export const resetPassword = ({ email }) => {
  return post(`${API}/userprofiles/password/reset/`, JSON.stringify({ email }));
};

export const resetPasswordConfirm = ({
  newPassword1,
  newPassword2,
  uid,
  token,
}) => {
  return post(
    `${API}/userprofiles/password/reset/confirm/`,
    JSON.stringify({
      new_password1: newPassword1,
      new_password2: newPassword2,
      uid,
      token,
    })
  );
};

export const logout = () => {
  return post(`${API}/userprofiles/logout/`, JSON.stringify(""), {
    ...authorization(),
  });
};

export const fetchProfile = () => {
  return get(`${API}/userprofiles/userprofile`, { ...authorization() });
};

export const setProfileAvatar = ({ uid, data }) => {
  return patch(`${API}/userprofiles/userprofile/${uid}`, data, {
    ...authorization(),
  });
};

export const fetchTimeZones = () => {
  return get(`${API}/userprofiles/timezones/`, { ...authorization() });
};

export const fetchCountries = () => {
  return get(`${API}/userprofiles/countries/`, { ...authorization() });
};

export const updateTimezone = ({ uid, data }) => {
  return patch(`${API}/userprofiles/userprofile/${uid}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
  });
};

export const addNewAddress = (data) => {
  return post(`${API}/userprofiles/addresses`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
  });
};

export const saveNewAddressesOrder = (arr) => {
  return post(
    `${API}/userprofiles/update_order_addresses/`,
    JSON.stringify(arr),
    {
      ...authorization(),
      ...contentTypeJson(),
    }
  );
};

export const deleteAddress = (uid) => {
  return remove(`${API}/userprofiles/addresses/${uid}`, {
    ...authorization(),
  });
};

export const deleteUserProfile = (uid) => {
  return remove(`${API}/userprofiles/userprofile/${uid}`, {
    ...authorization(),
    ...contentTypeJson(),
  });
};
