import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import notificationsReducer from "store/entities/Notification/notifications.reducer";
import platfomrsReducer from "store/entities/Platforms/platforms.reducer";

import * as service from "./platforms.service";
import { makeRequest } from "../../sagas/helpers";

const {
  // Logout
  logoutSuccess,

  // Channels
  getChannelsRequest,
  getChannelsSuccess,
  getChannelsFailure,

  // Get One Channel
  getTargetChannelRequest,
  getTargetChannelSuccess,
  getTargetChannelFailure,

  // Connect Channel
  connectChannelRequest,
  connectChannelSuccess,
  connectChannelFailure,

  // Disconnect Channel
  getDisconnectChannelRequest,
  getDisconnectChannelSuccess,
  getDisconnectChannelFailure,

  // Channel Esty Connect
  getEstyConnectUrlRequest,
  getEstyConnectUrlSuccess,
  getEstyConnectUrlFailure,
  // Esty connect url error
  setEstyConnectUrlError,
  removeEstyConnectUrlError,

  // Channel Esty Callback
  fetchEstyCallbackRequest,
  fetchEstyCallbackSuccess,
  fetchEstyCallbackFailure,

  // Channel WooCommerce Connect
  connectWooCommerceRequest,
  connectWooCommerceSuccess,
  connectWooCommerceFailure,

  // Channel Shopify connect url
  getShopifyConnectUrlRequest,
  getShopifyConnectUrlSuccess,
  getShopifyConnectUrlFailure,

  // Shopify connect callback
  fetchShopifyCallbackRequest,
  fetchShopifyCallbackSuccess,
  fetchShopifyCallbackFailure,

  // Channel Facebook Connect
  connectFacebookRequest,
  connectFacebookSuccess,
  connectFacebookFailure,

  // Channel Facebook Status
  statusFacebookRequest,
  statusFacebookSuccess,
  statusFacebookFailure,

  // Channel Facebook remove Status
  removeFacebookStatus,

  // Channel Ebay Connect url
  getEbayConnectUrlRequest,
  getEbayConnectUrlSuccess,
  getEbayConnectUrlFailure,
  // Ebay connect url error
  setEbayConnectUrlError,
  removeEbayConnectUrlError,

  // Channel Ebay CallBack
  fetchEbayCallbackRequest,
  fetchEbayCallbackSuccess,
  fetchEbayCallbackFailure,
} = platfomrsReducer.actions;

const {
  actions: { addNotification: addNotificationAction },
} = notificationsReducer;

// workers

function* getChannelsWorker() {
  try {
    const response = yield call(makeRequest(service.fetchChannels));

    if (response.message) {
      yield put(getChannelsFailure(response.message));
      yield put(
        addNotificationAction({
          msg: response?.message?.non_field_errors,
          type: "danger",
        })
      );
    } else {
      const sortedChannles = response.results.sort((first, second) =>
        first.status > second.status ? 1 : -1
      );
      yield put(getChannelsSuccess(sortedChannles));
    }
  } catch (error) {
    yield put(getChannelsFailure({ non_field_errors: "Error" }));
  }
}

function* getTargetChannelWorker({ payload }) {
  const { uid } = payload;

  try {
    const response = yield call(makeRequest(service.fetchTargetChannel), uid);

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(getTargetChannelFailure(response.message));
      }
    } else {
      yield put(getTargetChannelSuccess(response));
    }
  } catch (error) {
    yield put(getTargetChannelFailure({ non_field_errors: "Error" }));
  }
}

function* connectChannelWorker({ payload }) {
  const { platform } = payload;

  try {
    let response;
    switch (platform) {
      case "etsy":
        response = yield call(makeRequest(service.disconnectEtsy));
        break;

      default:
        break;
    }

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(connectChannelFailure(response.message));
      }
    } else {
      yield put(connectChannelSuccess());
    }
  } catch (error) {
    yield put(connectChannelFailure({ non_field_errors: "Error" }));
    yield put(
      addNotificationAction({
        msg: "Failed Connect response",
        type: "danger",
      })
    );
  }
}

function* disconnectTargetChannelWorker({ payload }) {
  const { platform } = payload;

  try {
    let response;
    switch (platform) {
      case "etsy":
        response = yield call(makeRequest(service.disconnectEtsy));
        break;

      case "ebay":
        response = yield call(makeRequest(service.disconnectEbay));
        break;

      case "facebook":
        response = yield call(makeRequest(service.disconnectFacebook));
        break;

      case "shopify":
        response = yield call(makeRequest(service.disconnectShopify));
        break;

      case "woocommerce":
        response = yield call(makeRequest(service.disconnectWooCommerce));
        break;

      default:
        break;
    }

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(getDisconnectChannelFailure(response.message));
      }
    } else {
      if (platform === "facebook") {
        yield put(removeFacebookStatus());
      }

      yield put(getDisconnectChannelSuccess(response));

      yield put(getTargetChannelSuccess(null));

      yield put(getChannelsRequest());
      yield put(
        addNotificationAction({
          msg: `${platform} was disconnected`,
          type: "success",
        })
      );
    }
  } catch (error) {
    yield put(getDisconnectChannelFailure({ non_field_errors: "Error" }));
  }
}

function* getEtsyConnectUrlWorker() {
  try {
    const response = yield call(makeRequest(service.fetchEtsyConnectUrl));
    yield put(removeEstyConnectUrlError());

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(getEstyConnectUrlFailure(response.message));
        yield put(
          addNotificationAction({
            msg: response.message.non_field_errors,
            type: "danger",
          })
        );
        yield put(setEstyConnectUrlError(response.message.non_field_errors));
      }
    } else {
      yield put(getEstyConnectUrlSuccess(response.url));
      window.location.href = response.url;
    }
  } catch (error) {
    yield put(getEstyConnectUrlFailure({ non_field_errors: "Error" }));
    yield put(
      addNotificationAction({
        msg: "Failed Connect response",
        type: "danger",
      })
    );
  }
}

function* fetchEtsyConnectCallbackWorker({ payload }) {
  const { credentials } = payload;
  try {
    const response = yield call(
      makeRequest(service.fetchEtsyConnectCallback),
      credentials
    );

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(fetchEstyCallbackFailure(response.message));
        yield put(
          addNotificationAction({
            msg:
              typeof response.message === "string"
                ? response.message
                : JSON.stringify(response.message),
            type: "danger",
          })
        );
      }
    } else {
      yield put(fetchEstyCallbackSuccess());
    }
  } catch (error) {
    yield put(fetchEstyCallbackFailure({ non_field_errors: "Error" }));
    yield put(
      addNotificationAction({
        msg: "Failed Callback response",
        type: "danger",
      })
    );
  }
}

function* getWooCommerceConnectWorker({ payload }) {
  const { url, consumerKey, consumerSecret, channelId } = payload;

  try {
    const response = yield call(makeRequest(service.connectWooCommerce), {
      url,
      consumerKey,
      consumerSecret,
    });

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(connectWooCommerceFailure(response.message));
      }
    } else {
      yield put(
        addNotificationAction({
          msg: "WooCommerce connected successfully",
          type: "success",
        })
      );
      yield put(connectWooCommerceSuccess({ channelId: channelId }));
    }
  } catch (error) {
    yield put(connectWooCommerceFailure({ non_field_errors: "Error" }));
    yield put(
      addNotificationAction({
        msg: "Failed Connect response",
        type: "danger",
      })
    );
  }
}

function* connectWooCommerceSuccessWorker({ payload }) {
  const { channelId } = payload;

  yield put(getTargetChannelRequest({ uid: channelId }));
}

function* getShopifyConnectWorker({ payload }) {
  try {
    const response = yield call(
      makeRequest(service.fetchShopifyConnectUrl),
      payload
    );

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(getShopifyConnectUrlFailure(response.message));
        yield put(
          addNotificationAction({
            msg: "Error",
            type: "danger",
          })
        );
      }
    } else {
      yield put(getShopifyConnectUrlSuccess(response.auth_url));
    }
  } catch (error) {
    yield put(getShopifyConnectUrlFailure({ non_field_errors: "Error" }));
    yield put(
      addNotificationAction({
        msg: "Failed Connect response",
        type: "danger",
      })
    );
  }
}

function* fetchShopifyConnectCallbackWorker({ payload }) {
  try {
    const response = yield call(
      makeRequest(service.fetchShopifyConnectCallback),
      payload
    );

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(fetchShopifyCallbackFailure(response.message));
        yield put(
          addNotificationAction({
            msg: response?.message?.message,
            type: "danger",
          })
        );
      }
    } else {
      yield put(fetchShopifyCallbackSuccess());
    }
  } catch (error) {
    yield put(fetchShopifyCallbackFailure({ non_field_errors: "Error" }));
    yield put(
      addNotificationAction({
        msg: "Failed Callback response",
        type: "danger",
      })
    );
  }
}

function* getFacebookConnectWorker({ payload }) {
  const { email, password } = payload;

  try {
    const response = yield call(makeRequest(service.connectFacebook), {
      email,
      password,
    });

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(connectFacebookFailure());
        yield put(
          addNotificationAction({
            msg: `${response.message[0]}`,
            type: "danger",
          })
        );
      }
    } else {
      yield put(
        addNotificationAction({
          msg: "Connecting Facebook... ",
          type: "success",
        })
      );
      yield put(connectFacebookSuccess());
    }
  } catch (error) {
    yield put(connectFacebookFailure());
    yield put(
      addNotificationAction({
        msg: "Failed Facebook connect response",
        type: "danger",
      })
    );
  }
}

function* getFacebookStatusWorker() {
  try {
    const response = yield call(makeRequest(service.fetchFacebookStatus));

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(statusFacebookFailure(response.message));
      }
    } else {
      yield put(statusFacebookSuccess(response.status));
    }
  } catch (error) {
    yield put(statusFacebookFailure({ non_field_errors: "Error" }));
    yield put(
      addNotificationAction({
        msg: "Failed Facebook status response",
        type: "danger",
      })
    );
  }
}

function* getEbayConnectUrlWorker() {
  try {
    const response = yield call(makeRequest(service.fetchEbayConnectUrl));
    yield put(removeEbayConnectUrlError());

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(getEbayConnectUrlFailure(response.message));
        yield put(
          addNotificationAction({
            msg: response.message.non_field_errors,
            type: "danger",
          })
        );
        yield put(setEbayConnectUrlError(response.message.non_field_errors));
      }
    } else {
      yield put(getEbayConnectUrlSuccess(response));
    }
  } catch (error) {
    yield put(getEbayConnectUrlFailure({ non_field_errors: "Error" }));
    yield put(
      addNotificationAction({
        msg: "Failed Connect response",
        type: "danger",
      })
    );
  }
}

function* fetchEbayConnectCallbackWorker({ payload }) {
  const { credentials } = payload;

  try {
    const response = yield call(
      makeRequest(service.fetchEbayConnectCallback),
      credentials
    );

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(fetchEbayCallbackFailure(response.message));
      }
    } else {
      yield put(fetchEbayCallbackSuccess());
    }
  } catch (error) {
    yield put(fetchEbayCallbackFailure({ non_field_errors: "Error" }));
    yield put(
      addNotificationAction({
        msg: "Failed Callback response",
        type: "danger",
      })
    );
  }
}

// watchers

function* watchGetChannels() {
  yield takeEvery(getChannelsRequest, getChannelsWorker);
}

function* watchGetTargetChannel() {
  yield takeEvery(getTargetChannelRequest, getTargetChannelWorker);
}

function* watchConnectChannel() {
  yield takeEvery(connectChannelRequest, connectChannelWorker);
}

function* watchDisconnectTargetChannel() {
  yield takeEvery(getDisconnectChannelRequest, disconnectTargetChannelWorker);
}

function* watchGetEtsyConnetcUrl() {
  yield takeEvery(getEstyConnectUrlRequest, getEtsyConnectUrlWorker);
}

function* watchFetchEtsyCallback() {
  yield takeEvery(fetchEstyCallbackRequest, fetchEtsyConnectCallbackWorker);
}

function* watchWooCommerceConnect() {
  yield takeEvery(connectWooCommerceRequest, getWooCommerceConnectWorker);
}

function* watchWooCommerceConnectSuccess() {
  yield takeEvery(connectWooCommerceSuccess, connectWooCommerceSuccessWorker);
}

function* watchShopifyConnect() {
  yield takeEvery(getShopifyConnectUrlRequest, getShopifyConnectWorker);
}

function* watchFetchShopifyConnectCallback() {
  yield takeEvery(
    fetchShopifyCallbackRequest,
    fetchShopifyConnectCallbackWorker
  );
}

function* watchFacebookConnect() {
  yield takeEvery(connectFacebookRequest, getFacebookConnectWorker);
}

function* watchFacebookStatus() {
  yield takeEvery(statusFacebookRequest, getFacebookStatusWorker);
}

function* watchEbayConnectUrl() {
  yield takeEvery(getEbayConnectUrlRequest, getEbayConnectUrlWorker);
}

function* watchFetchEbayConnectCallback() {
  yield takeEvery(fetchEbayCallbackRequest, fetchEbayConnectCallbackWorker);
}

export default function* platformsSaga() {
  yield all([
    fork(watchGetChannels),
    fork(watchGetTargetChannel),
    fork(watchConnectChannel),
    fork(watchDisconnectTargetChannel),
    fork(watchGetEtsyConnetcUrl),
    fork(watchFetchEtsyCallback),
    fork(watchWooCommerceConnect),
    fork(watchWooCommerceConnectSuccess),
    fork(watchShopifyConnect),
    fork(watchFetchShopifyConnectCallback),
    fork(watchFacebookConnect),
    fork(watchFacebookStatus),
    fork(watchEbayConnectUrl),
    fork(watchFetchEbayConnectCallback),
  ]);
}
