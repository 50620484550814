import React from "react";

import { Button, Modal } from "components/simple";
import BootstrapModal from "react-bootstrap/Modal";

import newPlanModal from "./modalSelectNewPlan.module.scss";

import helpers from "styles/helpers.module.scss";

import closeIcon from "assets/icons/plus-icon.png";

export default function SelectNewPlanModal({ show, setShow }) {
  return (
    <div>
      <Modal
        setShow={setShow}
        show={show}
        containerStyles={newPlanModal.modalContainer}
        contentStyles={newPlanModal.modalContent}
      >
        <BootstrapModal.Header
          bsPrefix={`${newPlanModal.header} ${helpers.contentRowLine}`}
        >
          <Button
            onClick={() => setShow(false)}
            bsPrefix={newPlanModal.channelCloseBtn}
          >
            <img src={closeIcon} alt="add channel" />
          </Button>
          <BootstrapModal.Title
            id="example-custom-modal-styling-title"
            bsPrefix={newPlanModal.title}
          >
            Select a new plan
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body bsPrefix={newPlanModal.modalBody}>
          <div className={newPlanModal.notification}>
            <div className={newPlanModal.firstLine}>
              You'va reached the maximum number of listings.
            </div>
            <div className={newPlanModal.secondLine}>
              Please select a new plan
            </div>
          </div>
          <Button className={newPlanModal.planBtn}>Upgrade Plan</Button>
        </BootstrapModal.Body>
      </Modal>
    </div>
  );
}
