import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as routes from "router/routes/index";
import {
  selectorProfile,
  selectorProfileLoading,
  selectorIsAvaratLoading,
  selectorIsSignUpModalShown,
} from "selectors/userSelectors";
import { selectorCurrentSubscription } from "selectors/subscriptionSelectors";
import {
  selectorDefaultCard,
  selectorCards,
} from "selectors/paymentsSelectors";

import headerReducer from "store/entities/Header/header.reducer";
import usersReducer from "store/entities/Users/users.reducer";
import subscriptionReducer from "store/entities/Subscriptions/subscriptions.reducer";
import paymentReducer from "store/entities/Payments/payments.reducer";

import moment from "moment";

import { Link } from "react-router-dom";
import ContentContainer from "components/simple/ContentContainer";
import Loader from "components/simple/Loader/Loader";
import Avatar from "./Avatar/Avatar";
import FirstEnter from "./FirstEnter/FirstEnter";
import Button from "components/simple/Button/Button";

import styles from "./profile.module.scss";
import helpers from "styles/helpers.module.scss";

import checkIcon from "assets/icons/check-green.png";

const {
  actions: { setHeaderTitle },
} = headerReducer;

const {
  actions: {
    closeFirstEnterModal,
    setAvatarRequest,
    getUserRequest,
    showFirstEnterModal,
  },
} = usersReducer;

const {
  actions: { fetchCardsRequest },
} = paymentReducer;

const {
  actions: { subscriptionRequest },
} = subscriptionReducer;

const Profile = () => {
  const dispatch = useDispatch();
  const isAvatarLoading = useSelector(selectorIsAvaratLoading);
  const profile = useSelector(selectorProfile);
  const isProfileLoading = useSelector(selectorProfileLoading);
  const isSignUpModalShown = useSelector(selectorIsSignUpModalShown);
  const subscriptionData = useSelector(selectorCurrentSubscription);
  const defaultCard = useSelector(selectorDefaultCard);
  const сards = useSelector(selectorCards);

  if (profile) {
    var {
      phone_number: phone,
      created_date: since,
      photo,
      user: { email },
      user: { first_name: firstName },
      user: { last_name: lastName },
    } = profile;
    var sinceDate = moment(since).format("Do MMMM YYYY");
  }

  if (subscriptionData) {
    var {
      expiration_date: expirationDate,
      active_plan: {
        plan_type: planType,
        max_pushes: maxPushes,
        max_integrations: maxIntegrations,
      },
    } = subscriptionData;
    var expiresIn = moment(expirationDate).format("DD-MM-YYYY");
  }

  useEffect(() => {
    dispatch(setHeaderTitle({ title: "Profile" }));

    if (!profile) {
      dispatch(getUserRequest());
    }

    if (!subscriptionData) {
      dispatch(subscriptionRequest());
    }

    if (!сards) {
      dispatch(fetchCardsRequest());
    }

    const isModalShown = localStorage.getItem("signupSeccess");
    if (isModalShown) {
      dispatch(showFirstEnterModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeSuccessSignupModalHandler = () => {
    dispatch(closeFirstEnterModal());
    localStorage.removeItem("signupSeccess");
  };

  const saveAvatarHandler = (data) => {
    dispatch(setAvatarRequest(data));
  };

  return (
    <>
      {isSignUpModalShown && (
        <FirstEnter closeHandler={closeSuccessSignupModalHandler} />
      )}
      <div className={styles.screenContainer}>
        <ContentContainer className={styles.profileInfo}>
          {profile ? (
            <>
              <Avatar
                photo={photo}
                fullName={`${firstName} ${lastName}`}
                setAvatarHandler={saveAvatarHandler}
                isAvatarLoading={isAvatarLoading}
                isUserDataLoading={isProfileLoading}
              />
              <div
                className={`${styles.personalData} ${helpers.contentRowLine}`}
              >
                <div className={styles.title}>Full Name</div>
                <div className={styles.text}>{`${firstName} ${lastName}`}</div>
              </div>
              <div
                className={`${styles.personalData} ${helpers.contentRowLine}`}
              >
                <div className={styles.title}>Phone</div>
                <div className={styles.text}>
                  {phone ? phone : "Your phone number"}
                </div>
              </div>
              <div
                className={`${styles.personalData} ${helpers.contentRowLine}`}
              >
                <div className={styles.title}>Email</div>
                <div className={styles.text}>{email}</div>
              </div>
              <div
                className={`${styles.personalData} ${helpers.contentRowLine}`}
              >
                <div className={styles.title}>Member Since</div>
                <div className={styles.text}>{sinceDate}</div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </ContentContainer>

        <ContentContainer className={styles.profileSubscription}>
          {subscriptionData ? (
            <>
              <div className={`${styles.subscriptionInfo}`}>
                <div className={`${styles.title} ${helpers.contentRowLine}`}>
                  Subscription
                </div>
                <div
                  className={`${styles.subscriptionType} ${helpers.contentRowLine}`}
                >
                  <div
                    className={styles.typeTitle}
                  >{`${planType} Subscription`}</div>
                  <div className={styles.checkRow}>
                    <img src={checkIcon} alt="check" />
                    <div>{`Up to ${maxPushes} Insertions Per Month`}</div>
                  </div>
                  <div className={styles.checkRow}>
                    <img src={checkIcon} alt="check" />
                    <div>{`${maxIntegrations} Integrations`}</div>
                  </div>
                </div>
                <div
                  className={`${styles.subscriptionData} ${helpers.contentRowLine}`}
                >
                  <div className={styles.block}>
                    <div className={styles.subtitle}>Expiration date</div>
                    <div className={styles.date}>{expiresIn}</div>
                  </div>
                  <div className={styles.block}>
                    <div className={styles.subtitle}>Payment Method</div>
                    {defaultCard ? (
                      <div className={styles.date}>{defaultCard.brand}</div>
                    ) : (
                      <div className={styles.noPayments}>No payments yet</div>
                    )}
                  </div>
                </div>
                <Link to={routes.selectNewPlanScreen}>
                  <Button className={styles.subscriptionBtn}>
                    Upgrade Plan
                  </Button>
                </Link>
              </div>
              <div className={styles.subscriptionImage}></div>
            </>
          ) : (
            <Loader className={styles.subscriptionLoader} />
          )}
        </ContentContainer>
      </div>
    </>
  );
};

export default Profile;
