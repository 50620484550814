import { createSelector } from "reselect";

const subscriptionPlanSelector = (state) =>
  state.subscription.subscriptionPlans
    ? state.subscription.subscriptionPlans.find(
        (plan) => plan?.id === state.subscription.selectedPlanId
      )
    : null;

export const selectorSubscriptionPlan = createSelector(
  subscriptionPlanSelector,
  (plan) => plan
);

const cardsSelector = (state) => state.payments.cards;
export const selectorCards = createSelector(cardsSelector, (items) => items);
