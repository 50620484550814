import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cards: null,
  billingData: null,
  isBillingInfoLoading: false,
  isCardsListLoading: false,
};

const payments = createSlice({
  name: "payments",
  initialState,
  reducers: {
    // When user selecting new subscription plan
    proceedSubscription(state, { payload }) {},
    proceedSubscriptionWithExistingCard(state, { payload }) {},

    // Billing Info
    createBillingInfoRequest() {},
    createBillingInfoSuccess() {},
    createBillingInfoFailure() {},
    // createBillingInfoRequest(state, { payload }) {},
    // createBillingInfoSuccess(state, { payload }) {},
    // createBillingInfoFailure(state, { payload }) {},

    // Create customer profile
    createStripeCustomerRequest(state, { payload }) {},
    createStripeCustomerSuccess(state, { payload }) {},
    createStripeCustomerFailure(state, { payload }) {},

    // Fetch cards
    fetchCardsRequest(state) {
      state.isCardsListLoading = true;
    },
    fetchCardsSuccess(state, { payload }) {
      state.cards = payload;
      state.isCardsListLoading = false;
    },
    fetchCardsFailure(state) {
      state.isCardsListLoading = false;
    },

    // remove cards
    removeCardRequest() {},
    removeCardSuccess() {},
    removeCardFailure() {},

    // Add card
    addCardRequest(state, { payload }) {},
    addCardSuccess(state, { payload }) {},
    addCardFailure(state, { payload }) {},

    // Set default card
    setDefaultCardRequest(state, { payload }) {},
    setDefaultCardSuccess(state, { payload }) {},
    setDefaultCardFailure(state, { payload }) {},

    // Create customer and subscription
    createCustomerAndSubscriptionRequest(state, { payload }) {},
    createCustomerAndSubscriptionSuccess(state, { payload }) {},
    createCustomerAndSubscriptionFailure(state, { payload }) {},

    // Create subscription
    createPaymentSubscriptionRequest(state, { payload }) {},
    createPaymentSubscriptionSuccess(state, { payload }) {},
    createPaymentSubscriptionFailure(state, { payload }) {},

    // Get payment subscriptions
    getPaymentSubscriptionsRequest(state, { payload }) {},
    getPaymentSubscriptionsSuccess(state, { payload }) {},
    getPaymentSubscriptionsFailure(state, { payload }) {},

    // Edit payment subscriptions
    editPaymentSubscribtionRequest(state, { payload }) {},
    editPaymentSubscribtionSuccess(state, { payload }) {},
    editPaymentSubscribtionFailure(state, { payload }) {},

    // Fetch billing info
    fetchBillingInfoRequest(state) {
      state.isBillingInfoLoading = true;
    },
    fetchBillingInfoSuccess(state, { payload }) {
      state.billingData = payload;
      state.isBillingInfoLoading = false;
    },
    fetchBillingInfoFailure(state) {
      state.isBillingInfoLoading = false;
    },
  },
});

export default payments;
