import React, { useState, useRef, useEffect } from "react";

import styles from "./message.module.scss";
import chatStyles from "../Chats/Chat/chat.module.scss";

import replyIcon from "assets/icons/reply.png";

const Message = ({
  message,
  interlocutor,
  searchedMessageRef,
  searchedMessage,
  profile,
  interlocutorAvatar,
  onSelectMessage,
}) => {
  const [isIncomingMessage, setIsIncomingMessage] = useState("");
  const [selectedMessage, setSelectedMessage] = useState("");
  const [isSearchedMessage, setIsSearchedMessage] = useState(false);
  const messageRef = useRef();

  function clearTextSelection() {
    if (document.selection && document.selection.empty) {
      document.selection.empty();
    } else if (window.getSelection) {
      var sel = window.getSelection();
      sel.removeAllRanges();
    }
  }

  const selectMessage = (e) => {
    e.preventDefault();
    if (e.detail === 2) {
      clearTextSelection();
      if (selectedMessage) {
        // remove
        onSelectMessage(message, true);
      } else {
        // add
        onSelectMessage(message);
      }
      setSelectedMessage((prev) => (!prev ? message.id : ""));
    }
  };

  useEffect(() => {
    messageRef.current.addEventListener("onclick", selectMessage, false);

    if (message.type === "Incoming") {
      setIsIncomingMessage(true);
    }

    return () => {
      if (messageRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        messageRef.current.removeEventListener("onclick", selectMessage, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message.text.includes(searchedMessage)) {
      setIsSearchedMessage(true);
    }
    setTimeout(() => {
      setIsSearchedMessage(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedMessage]);

  const renderAvatar = (incoming) => {
    if (incoming) {
      return (
        <>
          {false ? ( // check avatar, talk with Andey
            <div
              className={`${chatStyles.avatarWrapper} ${styles.messageAvatar}`}
            >
              <img src={interlocutorAvatar} alt="avatar" />
            </div>
          ) : (
            <div
              className={`${chatStyles.avatarWrapper} ${styles.messageAvatar}`}
            >
              {selectedMessage ? (
                <div
                  className={`${chatStyles.withoutAvatar} ${styles.replyImg}`}
                >
                  <img src={replyIcon} alt="reply" />
                </div>
              ) : (
                <div
                  className={chatStyles.withoutAvatar}
                  style={{ backgroundColor: interlocutorAvatar }}
                >
                  {interlocutor[0].toUpperCase()}
                </div>
              )}
            </div>
          )}
        </>
      );
    }

    return (
      <>
        {profile.photo ? (
          <div
            className={`${chatStyles.avatarWrapper} ${styles.messageAvatar}`}
          >
            {selectedMessage ? (
              <div className={`${chatStyles.withoutAvatar} ${styles.replyImg}`}>
                <img src={replyIcon} alt="reply" />
              </div>
            ) : (
              <img
                src={`${process.env.REACT_APP_API_URL}${profile?.photo}`}
                alt="avatar"
              />
            )}
          </div>
        ) : (
          <div
            className={`${chatStyles.avatarWrapper} ${styles.messageAvatar}`}
          >
            {selectedMessage ? (
              <div className={`${chatStyles.withoutAvatar} ${styles.replyImg}`}>
                <img src={replyIcon} alt="reply" />
              </div>
            ) : (
              <div
                className={chatStyles.withoutAvatar}
                style={{ backgroundColor: "#b6b6b6" }}
              >
                {profile.name[0].toUpperCase()}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div
      ref={messageRef}
      className={`${styles.message} ${
        isSearchedMessage && styles.searchedMessage
      }`}
      onDoubleClick={selectMessage}
    >
      <div
        ref={message.text.includes(searchedMessage) ? searchedMessageRef : null}
        className={`${styles.messageBody} ${
          !isIncomingMessage && styles.myMessage
        }`}
      >
        {renderAvatar(isIncomingMessage)}
        <div
          className={`${styles.messageText} ${
            selectedMessage && styles.selectedMessage
          }`}
        >
          {message.text}
          {message.attachment?.length > 0 &&
            message.attachment.map((a) => {
              return <div>{a.toString()}</div>;
            })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Message);
