import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionPlans: null,
  selectedPlanId: null,
  currentSubscription: null,
  isSubscriptionLoading: false,
};

const subscription = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    // All Subscriptions
    fetchPlansRequest() {},
    fetchPlansSuccess(state, { payload }) {
      state.subscriptionPlans = payload;
    },
    fetchPlansFailure() {},

    // Current Subscription
    subscriptionRequest(state, { payload }) {
      state.isSubscriptionLoading = true;
    },
    subscriptionSuccess(state, { payload }) {
      state.currentSubscription = payload.results[0];
      state.isSubscriptionLoading = false;
    },
    subscriptionFailure(state) {
      state.isSubscriptionLoading = false;
    },

    selectPlan(state, { payload }) {
      state.selectedPlanId = payload;
    },

    // Expired Subscription
    expiredSubscriptionRequest() {},
  },
});

export default subscription;
