import { call, put, takeEvery } from "redux-saga/effects";
import { history, routes } from "router";

import inventoryReducer from "store/entities/Inventory/inventory.reducer";
import usersReducer from "store/entities/Users/users.reducer";

import notificationsReducer from "../../Notification/notifications.reducer";

import * as service from "../inventory.service";
import { makeRequest } from "../../../sagas/helpers";

const {
  actions: {
    // Fetch inventory
    fetchInventoryRequest,

    // update listing
    updateListingRequest,
    updateListingSuccess,
    updateListingFailure,

    duplicateListingRequest,
    duplicateListingSuccess,
    duplicateListingFailure,

    // delete image
    deleteImageRequest,
    deleteImageSuccess,
    deleteImageFailure,
  },
} = inventoryReducer;

const {
  actions: { logoutRequest },
} = usersReducer;

const {
  actions: { addNotification: addNotificationAction },
} = notificationsReducer;

function* duplicateListingWorker ({ payload }) {
  try {
    const duplicateResponse = yield call(
      makeRequest(service.duplicateListing), payload);
      
      if (duplicateResponse.message) {
        console.log('duplicate fail', duplicateResponse)
        for (const [key, value] of Object.entries(duplicateResponse?.message)) {
          yield put(
            addNotificationAction({
              // msg: `${key}: ${value}`,
              msg: `Draft and duplicate was failed.`,
              type: "danger",
            })
          );
        }
        yield put(duplicateListingFailure(JSON.stringify(duplicateResponse?.message)));
      } else {
        yield put(
          duplicateListingSuccess({ duplicateResponse })
        );
        // yield put(
        //   addNotificationAction({
        //     msg: "Draft was created and duplicated.",
        //     type: "success",
        //   })
        // );
      }
      yield history.push(routes.inventoryScreen);
  } catch (error) {
    yield put(duplicateListingFailure({ non_field_errors: "Error" }));
  }
}

function* duplicateListingSuccessWorker({payload}) {
  yield put(fetchInventoryRequest());
  yield history.push(routes.inventoryScreen);
}

function* updateListingWorker({ payload }) {
  const { action, fields, images, listingId, listingStep, status, draft } = payload;

  try {
    const updateListingBody = { listingId, fields };

    const updateResponse = yield call(
      makeRequest(service.updateListing),
      updateListingBody
    );

    if (updateResponse.message) {
      for (const [key, value] of Object.entries(updateResponse?.message)) {
        yield put(
          addNotificationAction({
            msg: `${key}: ${value}`,
            type: "danger",
          })
        );
      }

      yield put(updateListingFailure(JSON.stringify(updateResponse?.message)));
      yield history.push(routes.inventoryScreen);
    } else {
      if (images?.length) {
        const imageUploadBody = { listingId, images };

        const imagesUploadResponse = yield call(
          makeRequest(service.uploadListingImages),
          imageUploadBody
        );

        if (imagesUploadResponse.message) {
          for (const [key, value] of Object.entries(
            imagesUploadResponse?.message
          )) {
            yield put(
              addNotificationAction({
                msg: `${key}: ${value}`,
                type: "danger",
              })
            );
          }
          yield put(
            updateListingFailure(JSON.stringify(updateResponse?.message))
          );
        } else {
          yield put(
            updateListingSuccess({
              updateResponse,
              action,
              listingStep,
              status,
              draft
            })
          );
        }
      } else {
        yield put(
          updateListingSuccess({ updateResponse, action, listingStep, status, draft })
        );
      }

      yield put(
        addNotificationAction({
          msg: draft ? 'Listing info was updated. Draft was created.' : "Listing info was updated",
          type: "success",
        })
      );
    }
  } catch (error) {
    yield put(updateListingFailure({ non_field_errors: "Error" }));
  }
}

function* updateListingSuccessWorker({ payload }) {
  const { action, updateResponse, listingStep, status, draft } = payload;

  yield put(fetchInventoryRequest());

  if (action && !draft) {
    yield history.push(
      `${routes.inventoryScreen}/${updateResponse.id}/${+listingStep + 1}`
    );
  } else {
    yield history.push(routes.inventoryScreen);
  }
}

function* deleteImageWorker({ payload }) {
  const { uid, deleteCallback, index } = payload;

  try {
    const response = yield call(makeRequest(service.deleteImage), uid);

    if (response.message) {
      if (response.message.logout) {
        yield put(logoutRequest(response.message.logout));
      } else {
        yield put(
          addNotificationAction({
            msg: response?.message?.non_field_errors,
            type: "danger",
          })
        );
        yield put(deleteImageFailure());
      }
    } else {
      yield put(deleteImageSuccess());
      deleteCallback(index);
      yield put(
        addNotificationAction({ msg: "Image was removed", type: "success" })
      );
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(deleteImageFailure());
  }
}

export function* watchUpdateListing() {
  yield takeEvery(updateListingRequest, updateListingWorker);
  yield takeEvery(updateListingSuccess, updateListingSuccessWorker);
  yield takeEvery(duplicateListingRequest, duplicateListingWorker);
  yield takeEvery(duplicateListingSuccess, duplicateListingSuccessWorker);
  yield takeEvery(deleteImageRequest, deleteImageWorker);
}
