import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import { routerMiddleware } from "react-router-redux";
import { createLogger } from "redux-logger";
// import jwtDecode from "jwt-decode";

// import usersReducer from "store/entities/Users/users.reducer";

import { history } from "../router";

import reducers from "./reducers";
import rootSaga from "./sagas";

// const {
//   actions: { logoutRequest },
// } = usersReducer;

const logger = createLogger({ collapsed: true });

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

// const checkTokenExpirationMiddleware = () => (next) => (action) => {
//   const token = localStorage.getItem("token");
//   try {
//     if (token && jwtDecode(token).exp < Date.now() / 1000) {
//       next(logoutRequest());
//       localStorage.clear();
//     }
//     next(action);
//   } catch (err) {
//     next(logoutRequest());
//   }
// };

const middlewares = [
  // checkTokenExpirationMiddleware,
  sagaMiddleware,
  routeMiddleware,
];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept("./reducers/index", () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require("./reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}

export { history };
