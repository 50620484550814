import { createSelector } from "reselect";

// Confirm/Reject modal
const isModalShownSelector = (state) => state.modal.isShown;

export const selectorIsModalShown = createSelector(
  isModalShownSelector,
  (items) => items
);

// Target Modal Factory
const allModalSelector = (state) => state.modal;
export function selectorIsModalShownFactory(type) {
  return createSelector(allModalSelector, (modals) => modals[type]);
}
