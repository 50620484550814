import React from "react";
import PropTypes from "prop-types";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import styles from "./tooltip.module.scss";

const ToolTip = ({ children, text, placement }) => {
  return (
    <OverlayTrigger
      key={placement ? placement : "bottom"}
      placement={placement ? placement : "bottom"}
      overlay={
        <Tooltip
          style={{ marginTop: "15px" }}
          bsPrefix={styles.overlay}
          id={`tooltip-${text}`}
          // modifiers={[{ name: "offset", options: { margin: 20 } }]}
        >
          {text}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

ToolTip.propTypes = {
  text: PropTypes.string.isRequired,
  placement: PropTypes.oneOf([
    "top",
    "top-start",
    "top-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "right",
    "right-start",
    "right-end",
    "left",
    "left-start",
    "left-end",
    "auto",
    "auto-start",
    "auto-end",
  ]),
  clickHandler: PropTypes.func,
};

export default ToolTip;
