import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import notificationsReducer from "store/entities/Notification/notifications.reducer";
import platformsReducer from "store/entities/Platforms/platforms.reducer";
import headerReducer from "store/entities/Header/header.reducer";
import repliesReducer from "store/entities/Replies/replies.reducer";
import inventoryReducer from "store/entities/Inventory/inventory.reducer";
import * as routes from "router/routes";

import {
  selectorCreateAutoReplyLoading,
  selectorAllAutoReplies,
  selectorUpdateAutoReplyLoading,
  selectorReplies,
  selectorRepliesLoading,
  selectorShortcuts,
  selectorShortcutsLoading,
  selectorChannelsWithInbox,
  selectorListings,
} from "../Selector";

import { ContentContainer, Button, Loader } from "components/simple";
import StepsProgressLine from "./StepsProgressLine/StepsProgressLine";
import FirstStep from "./StepFirst/StepFirst";
import SecondStep from "./StepSecond/StepSecond";
import ThirdStep from "./StepThird/StepThird";

import styles from "./stepsBody.module.scss";
import helpers from "styles/helpers.module.scss";

const {
  actions: { setHeaderTitle },
} = headerReducer;

const {
  actions: { addNotification },
} = notificationsReducer;

const {
  actions: { getChannelsRequest },
} = platformsReducer;

const {
  actions: { fetchInventoryRequest },
} = inventoryReducer;

const {
  actions: {
    autoRepliesRequest,
    createAutoReplyRequest,
    updateAutoReplyRequest,
    repliesRequest,
    shortcutsRequest,
  },
} = repliesReducer;

const StepsBody = () => {
  const [step, setStep] = useState(1);

  const { step: paramsStep } = useParams();
  const { autoReplyId } = useParams();
  const { listingId } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isCreateLoading = useSelector(selectorCreateAutoReplyLoading);
  const isEditLoading = useSelector(selectorUpdateAutoReplyLoading);
  const autoReplies = useSelector(selectorAllAutoReplies);
  const replies = useSelector(selectorReplies);
  const isRepliesLoading = useSelector(selectorRepliesLoading);
  const shortcuts = useSelector(selectorShortcuts);
  const isShortcutsLoading = useSelector(selectorShortcutsLoading);
  const channelsWithInbox = useSelector(selectorChannelsWithInbox);
  const listings = useSelector(selectorListings);

  const [state, setState] = useState({
    status: true,
    title: "",
    text: `Hi {{Customer Name}}, 
    \nThanks for reaching out! The price for the {{Title Listing}} is {{Price Listing}}. Let me know when you would like to pick it up.
    \nThanks!
    \n{{Seller Name}}`,
    trigger: "",
    intervalType: "",
    intervalValue: "",
    // selectedListings: [],
    platforms: [],
    recommendation: "",
    isDragModalShown: false,
  });
  const [selectedReply, setSelectedReply] = useState("");

  const [selectedValue, setSelectedValue] = useState([]);

  const changeInputsHandler = (name, value) => {
    if (Array.isArray(state[name])) {
      setState({ ...state, [name]: [value] });
      return;
    }
    setState({ ...state, [name]: value });
  };



  const handleSelectChange = (e) => {
    setSelectedValue(
      Array.isArray(e) ? e.map((x) => {
          return x.value;
        }) : []
        );
  };


  
  const selectSavedReplyHandler = (_, value) => {
    if (!value) {
      setSelectedReply("");
      setState({
        title: "",
        text: "",
      });
      return;
    }
    const reply = replies?.find((r) => r.pk === value);
    setSelectedReply(reply);
  };

  useEffect(() => {
    if (autoReplies) {
      const targetAutoReply = autoReplies?.find(
        (autoReply) => autoReply.id === autoReplyId
      );

      if (targetAutoReply) {
        setState({
          ...state,
          title: targetAutoReply.reply.name,
          text: targetAutoReply.reply.text,
          status: targetAutoReply.status,
          trigger: targetAutoReply.trigger,
          intervalType: targetAutoReply.interval_type,
          intervalValue: targetAutoReply.interval_value,
          selectedListings: targetAutoReply.listing,
          recommendation: targetAutoReply.recommendation,
        });
      }

      if (listingId) {
        setState({
          ...state,
          selectedListings: [...state.selectedListings, listingId],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoReplies]);

  useEffect(() => {
    if (selectedReply) {
      setState({
        ...state,
        title: selectedReply.name,
        text: selectedReply.text,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReply]);

  useEffect(() => {
    setStep(+paramsStep);

    dispatch(
      setHeaderTitle({
        title: "Create or Edit Auto Reply",
        linkBack: location.pathname.split("/")[1],
      })
    );

    if (!channelsWithInbox) {
      dispatch(getChannelsRequest());
    }
    if (!replies) {
      dispatch(repliesRequest());
    }
    if (!shortcuts) {
      dispatch(shortcutsRequest());
    }
    if (!autoReplies) {
      setStep(1);

      if (autoReplyId) {
        history.push(`${routes.autoRepliesScreen}/${1}/${autoReplyId}/edit`);
      } else if (listingId) {
        history.push(`${routes.autoRepliesScreen}/${1}/${listingId}/listing`);
      } else {
        history.push(`${routes.autoRepliesScreen}/${1}`);
      }

      dispatch(autoRepliesRequest());
    }
    if (!listings) {
      dispatch(fetchInventoryRequest());
    }

    if (!localStorage.getItem("autoreplyDragModal")) {
      setTimeout(() => {
        setState({
          ...state,
          isDragModalShown: true,
        });
      }, 3000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyFieldsIterator = (obj) => {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(obj)) {
      if (!value || (!Number.isInteger(value) && !value.length)) return false;
    }
    return true;
  };

  const validateStepsHandler = (stepInt) => {
    const {
      title,
      text,
      trigger,
      intervalType,
      intervalValue,
      selectedListings,
      platforms,
    } = state;

    switch (stepInt) {
      case 1:
        return emptyFieldsIterator({ title, text });
      case 2:
        return emptyFieldsIterator({ trigger, intervalType, intervalValue });
      case 3:
        return emptyFieldsIterator({ selectedListings, platforms });
      default:
        break;
    }
  };

  const nextStepIncreaseHandler = () => {
    if (validateStepsHandler(step)) {
      setStep((step) => step + 1);

      if (autoReplyId) {
        history.push(
          `${routes.autoRepliesScreen}/${step + 1}/${autoReplyId}/edit`
        );
      } else if (listingId) {
        history.push(
          `${routes.autoRepliesScreen}/${step + 1}/${listingId}/listing`
        );
      } else {
        history.push(`${routes.autoRepliesScreen}/${step + 1}`);
      }

      return;
    }
    dispatch(
      addNotification({
        msg: `Fill the fields`,
        type: "danger",
      })
    );
  };

  const renderNextStepHandler = (stepInt) => {
    switch (stepInt) {
      case 1:
        return (
          <FirstStep
            changeSelectHandler={selectSavedReplyHandler}
            changeInputsHandler={changeInputsHandler}
            state={state}
            setState={setState}
            replies={replies}
            shortcuts={shortcuts}
            selectedReply={selectedReply}
          />
        );
      case 2:
        return (
          <SecondStep state={state} changeInputsHandler={changeInputsHandler} />
        );
      case 3:
        return (
          <ThirdStep
            state={state}
            setState={setState}
            changeInputsHandler={changeInputsHandler}
            channels={channelsWithInbox}
            listings={listings}

            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            handleSelectChange={handleSelectChange}
            listingsOptions={listingsOptions}
          />
        );
      default:
        break;
    }
  };

  const successRequestCallback = () => {
    history.push(routes.autoRepliesScreen);
  };

  const createAutoReplyHandler = () => {
    let reply; // saved reply
    let reply_object; // new reply

    if (
      state.title === selectedReply.name &&
      state.text === selectedReply.text
    ) {
      reply = selectedReply.pk;
    } else {
      reply_object = {
        name: state.title,
        text: state.text,
      };
    }

    const data = {
      name: state.title,
      text: state.text,
      trigger: state.trigger,
      interval_type: state.intervalType,
      interval_value: state.intervalValue,
      // listing: state.selectedListings,
      listing: selectedValue,
      platforms: state.platforms,
      reply,
      reply_object,
      status: true,
    };

    if (data.listing.length === 0) {
      dispatch(
        addNotification({
          msg: `Fill the fields`,
          type: "danger",
        })
      );
      return;
    }

    autoReplyId && !state.recommendation
      ? dispatch(
          updateAutoReplyRequest({
            data,
            uid: autoReplyId,
            callback: successRequestCallback,
            fetchData: true,
          })
        )
      : dispatch(
          createAutoReplyRequest({
            data,
            callback: successRequestCallback,
            fetchData: true,
          })
        );
  };

  const listingsOptions = listings?.map((listing) => ({
    value: listing.id,
    label: listing.title,
  }));

  return (
    <ContentContainer className={styles.stepsWrapper}>
      {isRepliesLoading || isShortcutsLoading ? (
        <Loader />
      ) : (
        <>
          <div
            className={`${styles.replyTitle} ${helpers.contentRowLine} ${
              state.title && styles.filledTitle
            }`}
          >
            <input
              name="title"
              value={state.title}
              onChange={({ target }) =>
                changeInputsHandler(target.name, target.value)
              }
              className={styles.titleInput}
              placeholder="Enter Title Of Auto Reply"
              type="text"
            />
          </div>

          <StepsProgressLine
            step={step}
            createHandler={createAutoReplyHandler}
            isCreateLoading={isCreateLoading}
            isEdit={autoReplyId}
            isEditLoading={isEditLoading}
          />

          <div className={styles.stepBodyWrapper}>
            {renderNextStepHandler(step)}
          </div>
          {step >= 3 ? (
            ""
          ) : (
            <Button
              onClick={nextStepIncreaseHandler}
              className={styles.continueBtn}
            >
              Continue
            </Button>
          )}
        </>
      )}
    </ContentContainer>
  );
};

export default StepsBody;
