import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import * as routes from "router/routes";

import { selectorLoginLoading } from "../Selector";

import usersReducer from "store/entities/Users/users.reducer";
import notificationsReducer from "store/entities/Notification/notifications.reducer";

import { Input, Button } from "components/simple";
import { Link } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

import authStyles from "../AuthContainer/auth-container.module.scss";

import googleIcon from "assets/icons/Auth/google-icon.png";
import fbIcon from "assets/icons/Auth/fb-icon.png";

const {
  loginRequest,
  loginFacebookRequest,
  loginGoogleRequest,
  activateRequest,
  //   clearErrorMessage as clearErrorMessageAction
} = usersReducer.actions;

const {
  actions: { addNotification },
} = notificationsReducer;

const Login = ({
  clearErrorMessage,
  isActivateLoading,
  activateSuccess,
  errors,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ email: "", password: "" });
  const { email, password } = state;

  const { token } = useParams();

  const loginLoading = useSelector(selectorLoginLoading);

  const activateHandler = (tokenString, uidString) => {
    dispatch(activateRequest(tokenString, uidString));
  };

  const inputChangeHandler = (name, value) => {
    let modifiedValue;

    if (name === "email") {
      modifiedValue = typeof value === "string" ? value.toLowerCase() : "";
    } else {
      modifiedValue = value;
    }

    setState({ ...state, [name]: modifiedValue });
  };

  const loginHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    // clearErrorMessage();
    dispatch(loginRequest({ email, password }));
  };

  const activateHandlerCallback = useCallback((token) => {
    activateHandler({ token });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // clearErrorMessage();
    if (token) {
      activateHandlerCallback(token);
    }
  }, [activateHandlerCallback, clearErrorMessage, token]);

  const fbAppId = process.env.REACT_APP_FB_APP_ID;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const responseFacebook = (response) => {
    dispatch(loginFacebookRequest({ accessToken: response.accessToken }));
  };

  const onSuccessGoogle = (response) => {
    dispatch(loginGoogleRequest({ accessToken: response.tokenId }));
  };

  const onFailureGoogle = (response) => {
    dispatch(
      addNotification({
        msg: `[Google login failed] res: ${JSON.stringify(response)}`,
        type: "danger",
      })
    );
  };

  return (
    <div>
      <div className={authStyles.title}>Sign In</div>
      <form className={authStyles.form} onSubmit={loginHandler}>
        <Input
          name="email"
          type="email"
          value={email}
          label={"Email"}
          onChange={inputChangeHandler}
          required
          // autocomplete="email"
        />
        <Input
          name="password"
          type="password"
          label={"Password"}
          value={password}
          onChange={inputChangeHandler}
          required
          // autocomplete="password"
        />
        <div className={authStyles.buttonContainer}>
          <Button
            type="submit"
            variant="primary"
            className={authStyles.signBtn}
            isLoading={loginLoading}
          >
            Sign In
          </Button>
          <Link to={routes.resetPasswordScreen}>Forgot Password?</Link>
        </div>

        <div className={authStyles.socialContainer}>
          Sign In With
          <GoogleLogin
            clientId={googleClientId}
            render={(renderProps) => (
              <Button
                className={authStyles.socialButton}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <img src={googleIcon} alt="login with google account" />
              </Button>
            )}
            buttonText="Continue with Google"
            onSuccess={onSuccessGoogle}
            onFailure={onFailureGoogle}
            cookiePolicy={"single_host_origin"}
            //isSignedIn={true}
          />
          <FacebookLogin
            appId={fbAppId}
            render={(renderProps) => (
              <Button
                className={authStyles.socialButton}
                onClick={renderProps.onClick}
              >
                <img src={fbIcon} alt="login with facebook account" />
              </Button>
            )}
            fields="name,email"
            callback={responseFacebook}
          />
        </div>

        <div className={authStyles.altActionContainer}>
          Don't Have an Account?{" "}
          <Link to={routes.signupScreen}>Sign Up Here</Link>
        </div>
      </form>
    </div>
  );
};

Login.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  login: PropTypes.func.isRequired,
  activate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isActivateLoading: PropTypes.bool.isRequired,
  activateSuccess: PropTypes.bool.isRequired,
  clearErrorMessage: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.shape({
      non_field_errors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(),
      ]),
    }),
  ]).isRequired,
};

const mapStateToProps = ({ user }) => {
  return {
    isLoading: user.isLoginLoading,
    isActivateLoading: user.isActivateLoading,
    activateSuccess: user.activateSuccess,
    errorMessage: user.errorMessage,
    errors: user.errors,
  };
};

export default connect(mapStateToProps)(Login);
