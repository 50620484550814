import { createSelector } from "reselect";

// All Auto Replies
const autoRepliesSelector = (state) => state.replies.autoReplies;
export const selectorAllAutoReplies = createSelector(
  autoRepliesSelector,
  (autoReplies) => autoReplies
);

// Saved Auto Replies
export const selectorAutoReplies = createSelector(
  autoRepliesSelector,
  (autoReplies) => autoReplies?.filter((a) => !a.recommendation)
);

// Recommended Auto Replies
export const selectorRecommendedAutoReplies = createSelector(
  autoRepliesSelector,
  (autoReplies) => autoReplies?.filter((a) => a.recommendation)
);

// Auto replies loading
const autoRepliesLoadingSelector = (state) => state.replies.autoRepliesLoading;
export const selectorAutoRepliesLoading = createSelector(
  autoRepliesLoadingSelector,
  (bool) => bool
);

// Create auto reply loading
const createAutoReplyLoadingSelector = (state) =>
  state.replies.createAutoReplyLoading;
export const selectorCreateAutoReplyLoading = createSelector(
  createAutoReplyLoadingSelector,
  (bool) => bool
);

// Update auto reply loading
const updateAutoReplyLoadingSelector = (state) =>
  state.replies.updateAutoReplyLoading;
export const selectorUpdateAutoReplyLoading = createSelector(
  updateAutoReplyLoadingSelector,
  (bool) => bool
);

// Delete auto reply loading
const deleteAutoReplyLoadingSelector = (state) =>
  state.replies.deleteAutoReplyLoading;
export const selectorDeleteAutoReplyLoading = createSelector(
  deleteAutoReplyLoadingSelector,
  (bool) => bool
);

// Replies
const repliesSelector = (state) => state.replies.replies;
export const selectorReplies = createSelector(
  repliesSelector,
  (items) => items
);

// Replies loading
const repliesLoadingSelector = (state) => state.replies.repliesLoading;
export const selectorRepliesLoading = createSelector(
  repliesLoadingSelector,
  (bool) => bool
);

// Shortcuts
const shortcutsSelector = (state) => state.replies.shortcuts;
export const selectorShortcuts = createSelector(
  shortcutsSelector,
  (shortcuts) => {
    if (shortcuts) {
      const temp = [];
      for (let key in shortcuts) {
        temp.push(shortcuts[key]);
      }
      return temp;
    }
    return null;
  }
);

// Shortcuts loading
const shortcutsLoadingSelector = (state) => state.replies.shortcutsLoading;
export const selectorShortcutsLoading = createSelector(
  shortcutsLoadingSelector,
  (bool) => bool
);

// Channles with available Inbox
const channelsSelector = (state) => state.platforms.channelsList;
export const selectorChannelsWithInbox = createSelector(
  channelsSelector,
  (channels) => {
    if (channels) {
      return channels.filter((ch) => ch.inbox);
    }
    return null;
  }
);

// Listings with available Inbox
const listingSelector = (state) => state.inventory.inventoryData;
export const selectorListings = createSelector(listingSelector, (listings) => {
  if (listings.length) {
    return listings;
  }
  return null;
});
