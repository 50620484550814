import React from "react";
import PropTypes from "prop-types";

export default function ArrowDown(props) {
  return (
    <svg
      style={props.style}
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 48 46.41`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g transform="translate(-7.000000, -7.000000)">
          <g transform="translate(7.000000, 7.000000)">
            <g transform="translate(0.000000, 1.533333)">
              <path
                className="st0"
                d="M31.25-0.42c3.09,1.01,5.9,2.66,8.26,4.78l-0.69,6.34c-0.15,1.43,0.79,3.06,2.1,3.63l5.84,2.58
					c0.32,1.54,0.32,8,0,9.54l-5.84,2.58c-1.31,0.58-2.25,2.21-2.1,3.63l0.69,6.34c-2.37,2.12-5.17,3.77-8.26,4.78L26.1,40
					c-0.58-0.42-1.34-0.63-2.1-0.63c-0.76,0-1.52,0.21-2.1,0.63l-5.15,3.77c-3.09-1.01-5.9-2.66-8.26-4.78l0.69-6.34
					c0.16-1.43-0.79-3.05-2.1-3.63l-5.84-2.58c-0.32-1.54-0.32-8,0-9.54l5.84-2.58c1.31-0.58,2.25-2.21,2.1-3.63L8.49,4.36
					c2.37-2.12,5.17-3.77,8.26-4.78l5.15,3.77c0.58,0.42,1.34,0.63,2.09,0.63c0.76,0,1.52-0.21,2.1-0.63L31.25-0.42z M16.27,3.02
					c-1.63,0.67-3.15,1.55-4.55,2.63l0.51,4.71c0.3,2.74-1.38,5.65-3.91,6.77l-4.2,1.85c-0.07,1.54-0.07,3.83,0,5.38l4.2,1.85
					c2.53,1.11,4.21,4.02,3.91,6.77l-0.51,4.71c1.4,1.08,2.92,1.96,4.55,2.64l3.82-2.8c1.08-0.79,2.47-1.23,3.91-1.23
					c1.44,0,2.83,0.43,3.91,1.23l3.82,2.8c1.63-0.67,3.15-1.56,4.55-2.64l-0.51-4.71c-0.3-2.74,1.38-5.65,3.91-6.77l4.2-1.85
					c0.07-1.54,0.07-3.83,0-5.38l-4.2-1.85c-2.53-1.11-4.21-4.02-3.91-6.77l0.51-4.71c-1.4-1.08-2.92-1.96-4.55-2.63l-3.82,2.8
					C26.83,6.61,25.44,7.05,24,7.05c-1.44,0-2.83-0.44-3.91-1.23L16.27,3.02z M24,15.94c-3.26,0-5.9,2.65-5.9,5.9s2.65,5.9,5.9,5.9
					s5.9-2.65,5.9-5.9S27.26,15.94,24,15.94z M24,30.82c-4.95,0-8.97-4.02-8.97-8.97c0-4.95,4.03-8.97,8.97-8.97
					s8.97,4.02,8.97,8.97C32.97,26.79,28.95,30.82,24,30.82z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

ArrowDown.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowDown.defaultProps = {
  className: "",
  width: 11.98,
  height: 11.23,
};
