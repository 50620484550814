/* eslint-disable */
export const loginScreen = "/login";
export const signupScreen = "/signup";
export const resetPasswordScreen = "/reset-password";
export const chooseNewPasswordScreen = "/reset-password";

export const checkInBox = "/check-inbox";
export const deliverySuccess = "/delivery-success";

export const dashboardScreen = "/";

export const inventoryScreen = "/inventory";
export const createListingScreen = "/inventory/create-listing";
export const myProfileScreen = "/profile";
export const integrationsScreen = "/integrations";
export const selectNewPlanScreen = "/select-new-plan";
export const paymentScreen = "/payment";
export const messagesScreen = "/messages";
export const settingsScreen = "/settings";
export const autoRepliesScreen = "/auto-replies";
