import { createSelector } from "reselect";

// Get Profile
const profileSelector = (state) => state.user.profileData;
export const selectorProfile = createSelector(profileSelector, (data) => data);

// Get Profile Loading
const profileLoadingSelector = (state) => state.user.isUserRequestLoading;
export const selectorProfileLoading = createSelector(
  profileLoadingSelector,
  (bool) => bool
);

// Get success SignUp modal
const isSignUpModalShownSelector = (state) => state.user.isFirstEnter;
export const selectorIsSignUpModalShown = createSelector(
  isSignUpModalShownSelector,
  (item) => item
);

// Get Avatar Loading
const isAvatarLoadingSelector = (state) => state.user.isAvatarLoading;
export const selectorIsAvaratLoading = createSelector(
  isAvatarLoadingSelector,
  (item) => item
);

// Get Timezones
const timezonesSelector = (state) => state.user.timezonesList;
export const selectorTimezones = createSelector(
  timezonesSelector,
  (items) => items
);

// Get Timezones Loading
const timezonesLoadingSelector = (state) =>
  state.user.isTimezonesRequestLoading;
export const selectorTimezonesLoading = createSelector(
  timezonesLoadingSelector,
  (item) => item
);

// Get Countries
const countriesSelector = (state) => state.user.countriesList;
export const selectorCountries = createSelector(
  countriesSelector,
  (item) => item
);

// Get Countries Loading
const countriesLoadingSelector = (state) => state.user.isCountriesRequestLoading;
export const selectorCountriesLoading = createSelector(
  countriesLoadingSelector,
  (item) => item
);
