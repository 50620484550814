import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import headerReducer from "store/entities/Header/header.reducer";

import styles from "./styles.module.scss";

import background from "assets/images/sales-volume.png";

const {
  actions: { setHeaderTitle },
} = headerReducer;

function DashboardScreen() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeaderTitle({ title: "Dashboard" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.commingWrapper}>
      <img className={styles.commingImg} src={background} alt="dashboard" />
      <div className={styles.comming}>Coming Soon</div>
    </div>
  );
}

export default DashboardScreen;
