import React from "react";
import styles from "./loader.module.scss";
import PropTypes from "prop-types";

export default function Loader({ variant, className }) {
  return (
    <div className={`${styles.container} ${className}`}>
      <div
        className={`${styles["three-dots-loader"]} ${
          variant === "dark" ? styles["dark-loader"] : ""
        }`}
      ></div>
    </div>
  );
}

Loader.propTypes = {
  variant: PropTypes.string,
};

Loader.defaultProps = {
  variant: "",
};
