import React from "react";

import Button from "components/simple/Button/Button";

import styles from "./stepsProgressLine.module.scss";
import helpers from "styles/helpers.module.scss";

const StepsProgressLine = ({
  step,
  isEdit,
  createHandler,
  isCreateLoading,
  isEditLoading,
}) => {
  return (
    <div className={`${styles.stepsProgress} ${helpers.contentRowLine}`}>
      <div
        className={`${styles.stepProgress} ${
          step === 1 ? styles.currentStep : styles.passedStep
        }`}
      >
        <div className={styles.stepName}>Create Reply</div>
        <div className={styles.stepSeperator}></div>
      </div>
      <div
        className={`${styles.stepProgress} ${
          step === 2 ? styles.currentStep : step > 2 ? styles.passedStep : ""
        }`}
      >
        <div className={styles.stepName}>Select Timing</div>
        <div className={styles.stepSeperator}></div>
      </div>
      <div
        className={`${styles.stepProgress} ${
          step === 3 ? styles.currentStep : step > 3 ? styles.passedStep : ""
        }`}
      >
        <div className={styles.stepName}>Select Filters</div>
        <div className={styles.stepSeperator}></div>
      </div>
      <Button
        onClick={createHandler}
        disabled={step !== 3}
        className={styles.activateBtn}
        isLoading={isEdit ? isEditLoading[isEdit] : isCreateLoading}
      >
        Activate Auto Reply
      </Button>
    </div>
  );
};

export default StepsProgressLine;
