import { createSlice } from "@reduxjs/toolkit";

import createNotific from "./createNotification";

const initialState = {
  toasts: [],
};

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification(state, { payload }) {
      state.toasts = [...state.toasts, createNotific(payload)];
    },
    removeNotification(state, { payload }) {
      state.toasts = state.toasts.filter((toast) => toast.id !== payload);
    },
  },
});

export default notifications;
