import { API, authorization, contentTypeJson } from "api/api";
import { get, post, remove, put } from "api/base";

// TODO: Move to users.service
export const createBillingInfo = ({ billingInfo }) => {
  return post(
    `${API}/userprofiles/billing/`,
    JSON.stringify({ ...billingInfo }),
    { ...authorization(), ...contentTypeJson() }
  );
};
// TODO: end;

export const createStripeCustomer = (paymentToken) => {
  return post(
    `${API}/payments/customer-create/`,
    JSON.stringify({ payment_token: paymentToken }),
    { ...authorization(), ...contentTypeJson() }
  );
};

export const fetchCards = () => {
  return get(`${API}/payments/cards`, { ...authorization() });
};

export const createCard = (paymentToken) => {
  return post(
    `${API}/payments/cards`,
    JSON.stringify({ payment_token: paymentToken }),
    { ...authorization(), ...contentTypeJson() }
  );
};

export const removeCard = (id) => {
  return remove(`${API}/payments/cards/${id}`, {
    ...authorization(),
  });
};

export const setDefaultCard = (cardId) => {
  return post(
    `${API}/payments/cards/set-default-card`,
    JSON.stringify({ card_id: cardId }),
    { ...authorization(), ...contentTypeJson() }
  );
};

export const createCustomerAndSubscription = ({ planId, paymentToken }) => {
  return post(
    `${API}/payments/create-customer-and-subscription/`,
    JSON.stringify({ plan_id: planId, payment_token: paymentToken }),
    { ...authorization(), ...contentTypeJson() }
  );
};

export const createPaymentSubscription = (planId) => {
  return post(
    `${API}/payments/subscription-create/`,
    JSON.stringify({ plan_id: planId }),
    { ...authorization(), ...contentTypeJson() }
  );
};

export const fetchPaymentSubscriptions = () => {
  return get(`${API}/payments/subscriptions`, {
    ...authorization(),
  });
};

export const selectNewSubscription = ({
  currentPaymenySubscriptionId,
  planId,
}) => {
  return put(
    `${API}/payments/subscriptions/${currentPaymenySubscriptionId}`,
    JSON.stringify({ plan_id: planId }),
    {
      ...authorization(),
      ...contentTypeJson(),
    }
  );
};

export const fetchBillingInfo = () => {
  return get(`${API}/userprofiles/billing`, {
    ...authorization(),
  });
};
