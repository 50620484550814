import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useValidate } from "hooks/useValidation";

import * as routes from "router/routes";

import { selectorSignupLoading } from "../Selector";

import usersReducer from "store/entities/Users/users.reducer";
import notificationsReducer from "store/entities/Notification/notifications.reducer";

import { Input, Button } from "components/simple";
import { Link } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

import authStyles from "../AuthContainer/auth-container.module.scss";

import googleIcon from "assets/icons/Auth/google-icon.png";
import fbIcon from "assets/icons/Auth/fb-icon.png";

const {
  signupRequest,
  loginFacebookRequest,
  loginGoogleRequest,
  //   clearErrorMessage as clearErrorMessageAction
} = usersReducer.actions;

const {
  actions: { addNotification },
} = notificationsReducer;

const SignUp = () => {
  const dispatch = useDispatch();
  const validate = useValidate();

  const [state, setState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    password: "",
    passwordConfirm: "",
  });
  const {
    email,
    firstName,
    lastName,
    password,
    passwordConfirm,
    phone,
  } = state;
  const [errors, setErrors] = useState({});

  const signupLoading = useSelector(selectorSignupLoading);

  const inputChangeHandler = (name, value) => {
    let modifiedValue;

    if (name === "email") {
      modifiedValue = typeof value === "string" ? value.toLowerCase() : "";
    } else {
      modifiedValue = value;
    }

    setState({ ...state, [name]: modifiedValue });
  };

  const signupHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const isError = onSubmitValidate(state);
    if (isError) return;

    // clearErrorMessage();
    if (password !== passwordConfirm) {
      dispatch(
        addNotification({ msg: "Passwords are not equal", type: "danger" })
      );
    } else {
      dispatch(signupRequest({ email, firstName, lastName, phone, password }));
    }
  };

  const onSubmitValidate = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      const isError = validate(key, value, errors, setErrors);
      if (isError) return isError;
    }
  };

  const fbAppId = process.env.REACT_APP_FB_APP_ID;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const responseFacebook = (response) => {
    dispatch(loginFacebookRequest({ accessToken: response.accessToken }));
  };

  const onSuccessGoogle = (response) => {
    dispatch(loginGoogleRequest({ accessToken: response.tokenId }));
  };

  const onFailureGoogle = (response) => {
    dispatch(
      addNotification({
        msg: `[Google login failed] res: ${JSON.stringify(response)}`,
        type: "danger",
      })
    );
  };

  return (
    <div>
      <div className={authStyles.title}>Sign Up</div>
      <form className={authStyles.form} style={{ marginTop: 30 }}>
        <Input
          name="email"
          type="email"
          value={email}
          label={"Email"}
          onChange={inputChangeHandler}
          onBlur={() => validate("email", email, errors, setErrors)}
          error={errors.email}
          required
        />
        <Input
          name="firstName"
          type="text"
          value={firstName}
          label={"First Name"}
          onChange={inputChangeHandler}
          onBlur={() => validate("firstName", firstName, errors, setErrors)}
          error={errors.firstName}
          required
        />
        <Input
          name="lastName"
          type="text"
          value={lastName}
          label={"Last Name"}
          onChange={inputChangeHandler}
          onBlur={() => validate("lastName", lastName, errors, setErrors)}
          error={errors.lastName}
          required
        />
        <Input
          name="phone"
          type="text"
          value={phone}
          label={"Phone"}
          placeholder={"+1 (XXX) XXX-XXXX"}
          onChange={inputChangeHandler}
          onBlur={() => validate("phone", phone, errors, setErrors)}
          error={errors.phone}
          required
        />
        <Input
          name="password"
          type="password"
          label={"Password"}
          value={password}
          onChange={inputChangeHandler}
          onBlur={() => validate("password", password, errors, setErrors)}
          error={errors.password}
          required
        />
        <Input
          name="passwordConfirm"
          type="password"
          label={"Confirm Password"}
          value={passwordConfirm}
          onChange={inputChangeHandler}
          // onBlur={() => validate(passwordConfirm)}
          required
        />
        <div className={authStyles.buttonContainer}>
          <Button
            type="submit"
            variant="primary"
            className={authStyles.signBtn}
            isLoading={signupLoading}
            onClick={signupHandler}
          >
            Sign Up
          </Button>
        </div>

        <div className={authStyles.socialContainer}>
          Or Sign Up With
          <GoogleLogin
            clientId={googleClientId}
            render={(renderProps) => (
              <Button
                className={authStyles.socialButton}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <img src={googleIcon} alt="login with google account" />
              </Button>
            )}
            buttonText="Continue with Google"
            onSuccess={onSuccessGoogle}
            onFailure={onFailureGoogle}
            cookiePolicy={"single_host_origin"}
            //isSignedIn={true}
          />
          <FacebookLogin
            appId={fbAppId}
            render={(renderProps) => (
              <Button
                className={authStyles.socialButton}
                onClick={renderProps.onClick}
              >
                <img src={fbIcon} alt="login with facebook account" />
              </Button>
            )}
            fields="name,email"
            callback={responseFacebook}
          />
        </div>

        <div
          className={authStyles.altActionContainer}
          style={{ marginTop: 50 }}
        >
          Already Have an Account?{" "}
          <Link to={routes.loginScreen}>Sign In Here</Link>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
