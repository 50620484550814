import React from "react";

import { Button, Modal, Loader } from "components/simple";
import BootstrapModal from "react-bootstrap/Modal";

import styles from "./importListingsModal.module.scss";
import helpers from "styles/helpers.module.scss";

import closeIcon from "assets/icons/plus-icon.png";

export default function ImportListingsModal({
  show,
  setShow,
  channelsList,
  onImportRequest,
  onConnectChannel,
  isImportLoading,
}) {
  return (
    <div>
      <Modal
        setShow={setShow}
        show={show}
        containerStyles={styles.modalContainer}
        contentStyles={styles.modalContent}
      >
        <BootstrapModal.Header
          bsPrefix={`${styles.header} ${helpers.contentRowLine}`}
        >
          <BootstrapModal.Title
            id="example-custom-modal-styling-title"
            bsPrefix={styles.title}
          >
            Import from Channel
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body bsPrefix={styles.modalBody}>
          {channelsList ? (
            channelsList.map((channel) => {
              const platform = channel.name.toLowerCase();
              return (
                <div
                  key={channel.id}
                  className={
                    channel.status_connection
                      ? `${styles.channel} ${helpers.contentRowLine}`
                      : `${styles.disconnected} ${styles.channel} ${helpers.contentRowLine}`
                  }
                >
                  <div className={styles.channelLogo}>
                    <img src={channel.logo} alt="channel logo" />
                  </div>
                  <div className={styles.channelName}>{channel.name}</div>
                  {channel.status_connection ? (
                    isImportLoading[platform] ? (
                      <Loader className={styles.loader} />
                    ) : (
                      <Button
                        onClick={() => {
                          onImportRequest(platform);
                        }}
                        className={styles.channelAddBtn}
                        variant="transparent"
                      >
                        <img src={closeIcon} alt="add channel" />
                      </Button>
                    )
                  ) : (
                    <Button
                      onClick={() => onConnectChannel(channel.id)}
                      className={styles.channelConnectBtn}
                      variant="transparent"
                    >
                      Connect Channel
                    </Button>
                  )}
                </div>
              );
            })
          ) : (
            <div>Something went wrong... Try again</div>
          )}
        </BootstrapModal.Body>
      </Modal>
    </div>
  );
}
