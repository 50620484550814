import React from "react";

import { Link } from "react-router-dom";

import { Dropdown } from "react-bootstrap";

import "./profile-dropdown.scss";

export default function ProfileDropdown({ items, className }) {
  return (
    <div>
      <Dropdown bsPrefix={`profile-dropdown ${className}`}>
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
        ></Dropdown.Toggle>

        <Dropdown.Menu>
          {items.map((item, index) => (
            <Dropdown.Item
              key={`dropdown-item-${index}`}
              as={Link}
              to={item.to}
              onClick={
                item.onClick
                  ? (e) => {
                      e.preventDefault();
                      item.onClick();
                    }
                  : () => {}
              }
            >
              {item.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
