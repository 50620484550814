import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

import Select, { components } from "react-select";

import SVG from "components/SVG";

import styles from "./select-input.module.scss";
import { customStyles } from "./index";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SVG.ArrowDown
        style={{ transform: "rotate(90deg)" }}
        width={20}
        height={14}
        fill={"#7d7d7d"}
      />
    </components.DropdownIndicator>
  );
};

const SelectInput = ({
  containerClass,
  inputClass = "",
  error,
  onValidate,
  type = "",
  onChange,
  name,
  value,
  invalid,
  fieldError,
  onClick,
  onBlur,
  setFocus,
  label,
  labelClass,
  placeholder,
  options,
  noOptionsMessage,
  disabled,
  isMulti,
  ...inputProps
}) => {
  const [isFocused, setIsFocused] = useState(!!inputProps.value);
  const [defaultSelectValue, setDefaultSelectValue] = useState("");
  const [multiValue, setMultiValue] = useState("");
  const input = useRef(null);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleMultiChange = (e) => {
    setMultiValue(e)
    
    onChange(e);
    onValidate(e);
  };

  const handleChange = ({ value }) => {
    onChange(name, value);
    onValidate(name, value);
  };

  const onMenuClose = () => {
    handleBlur();
    input.current.blur();
  };

  const onMenuOpen = () => {
    handleFocus();
  };

  useEffect(() => {
    if (setFocus) {
      input.current.focus();
    }
  }, [setFocus]);

  useEffect(() => {
    if (options?.length) {
        const defaultSelectValue = options?.find(
          (o) => o.value.toString() === value?.toString()
        );
  
        setDefaultSelectValue(defaultSelectValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <div className={`${styles["input-container"]} ${containerClass}`}>
      {label && (
        <Form.Label
          className={`${styles["input-label"]} ${labelClass}
          ${multiValue.length > 0 ? styles["input-label-active"] : ''}
        ${
          (value && value.toString().length) || isFocused || placeholder
            ? styles["input-label-active"]
            : ""
        }  
        ${isFocused ? styles["custom-input-label-focus"] : ""} 
        `}
        >
          {label}
        </Form.Label>
      )}
      <div className={styles["input-wrap"]}>
        <Select
          isMulti={isMulti}
          ref={input}
          components={{ DropdownIndicator }}
          name={name}
          value={defaultSelectValue}
          options={options}
          noOptionsMessage={noOptionsMessage}
          // onChange={handleChange}
          onChange={isMulti ? handleMultiChange : handleChange}
          onFocus={handleFocus}
          onMenuClose={onMenuClose}
          onMenuOpen={onMenuOpen}
          onBlur={() => {
            handleBlur();
            onBlur();
          }}
          styles={customStyles}
          width={inputProps.width ? inputProps.width : "100%"}
          left={inputProps.left ? inputProps.left : ""}
          menuColor="#5f7fd3"
          optionColor="#262626"
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#EFF2FA",
              primary: "#fff",
            },
          })}
          isDisabled={disabled}
          placeholder={placeholder ? placeholder : ""}
        />
        <Form.Text className={styles.error}>{error}</Form.Text>
      </div>

      {/* <Error component={FormFeedback} error={fieldError} /> */}
    </div>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  containerClass: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  fieldError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({}),
  ]),
  invalid: PropTypes.bool,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onValidate: PropTypes.func,
  setFocus: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()),
};

SelectInput.defaultProps = {
  value: "",
  label: "",
  error: "",
  containerClass: "",
  type: "text",
  labelClass: "",
  inputClass: "",
  fieldError: "",
  invalid: false,
  onClick: () => {},
  onBlur: () => {},
  onChange: () => {},
  onValidate: () => {},
  setFocus: false,
  options: [],
};

export default SelectInput;
