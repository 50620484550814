import { createSelector } from "reselect";

// Whole inventory
const inventorySelector = (state) => state.inventory;
export const selectorInventory = createSelector(
  inventorySelector,
  (items) => items
);

// Categories
const categoriesSelector = (state) => state.inventory.categoriesList;
export const selectorCategories = createSelector(
  categoriesSelector,
  (categories) => categories
);
const categoriesLoadingSelector = (state) =>
  state.inventory.isCategoriesListLoading;
export const selectorCategoriesLoading = createSelector(
  categoriesLoadingSelector,
  (bool) => bool
);

// Inventory Listings
const inventoryListingsSelector = (state) => state.inventory.inventoryData;
export const selectorInventoryListings = createSelector(
  inventoryListingsSelector,
  (items) => items
);

// Inventory Listings loading
const inventoryLoading = (state) => state.inventory.isInventoryLoading;
export const selectorInventoryLoading = createSelector(
  inventoryLoading,
  (bool) => bool
);

// Import listings loading
const isImportLoadingSelector = (state) =>
  state.inventory.importListingsLoading;
export const slectorImportLoading = createSelector(
  isImportLoadingSelector,
  (obj) => obj
);

// Edit listing loading
const editLoadingSelector = (state) => state.inventory.editListingLoading;
export const selectorEditListingLoading = createSelector(
  editLoadingSelector,
  (bool) => bool
);

// Fetch listing loading
const fetchListingLoadingSelector = (state) =>
  state.inventory.fetchListingLoading;
export const selectorFetchListingLoading = createSelector(
  fetchListingLoadingSelector,
  (bool) => bool
);
