import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSingupLoading: false,
  isLoginLoading: false,
  isAddNewAddressLoading: false,
  profileData: null,
  isFirstEnter: false,
  isUserRequestLoading: false,
  isPasswordRequestLoading: false,
  isPasswordRequestFailed: false,
  timezonesList: null,
  isTimezonesRequestLoading: false,
  isUpdateUserTimezoneLoading: false,
  isAvatarLoading: false,
  isSetNewAddressesOrderLoading: false,
  isDeleteAddressLoading: false,
  isDeleteUserLoading: false,
  countriesList: null,
  isCountriesRequestLoading: false,
};

const users = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Signup
    signupRequest(state) {
      state.isSingupLoading = true;
    },
    signupSuccess(state) {
      state.isSingupLoading = false;
    },
    signupFailure(state) {
      state.isSingupLoading = false;
    },

    // Login
    loginRequest(state, { payload }) {
      state.isLoginLoading = true;
    },
    loginSuccess(state, { payload }) {
      state.isLoginLoading = false;
    },
    loginFailure(state, { payload }) {
      state.isLoginLoading = false;
    },

    // Login Facebook
    loginFacebookRequest() {},

    // Login Google
    loginGoogleRequest() {},

    // Logout
    logoutRequest(state, { payload }) {},
    logoutSuccess(state, { payload }) {},

    // close modal Success Sign Up Message
    showFirstEnterModal(state) {
      state.isFirstEnter = true;
    },
    closeFirstEnterModal(state) {
      state.isFirstEnter = false;
    },

    // UserData
    getUserRequest(state, { payload }) {
      state.isUserRequestLoading = true;
    },
    getUserSuccess(state, { payload }) {
      state.profileData = payload;
      state.isUserRequestLoading = false;
      state.isAvatarLoading = false;
    },
    getUserFailure(state, { payload }) {
      state.isUserRequestLoading = false;
    },

    // Change Password
    changePasswordRequest(state) {
      state.isPasswordRequestLoading = true;
    },
    changePasswordSuccess(state) {
      state.isPasswordRequestLoading = false;
      state.isPasswordRequestFailed = false;
    },
    changePasswordFailure(state) {
      state.isPasswordRequestLoading = false;
      state.isPasswordRequestFailed = true;
    },

    // Get Timezones
    getTimezonesRequest(state, { payload }) {
      state.isTimezonesRequestLoading = true;
    },
    getTimezonesSuccess(state, { payload }) {
      state.timezonesList = payload;
      state.isTimezonesRequestLoading = false;
    },
    getTimezonesFailure(state, { payload }) {
      state.isTimezonesRequestLoading = false;
    },

    // update user Timezone
    updateUserTimezoneRequest(state) {
      state.isUpdateUserTimezoneLoading = true;
    },
    updateUserTimezoneSuccess(state) {
      state.isUpdateUserTimezoneLoading = false;
    },
    updateUserTimezoneFailure(state) {
      state.isUpdateUserTimezoneLoading = false;
    },

    // Add new Address
    setNewAddressRequest(state) {
      state.isAddNewAddressLoading = true;
    },
    setNewAddressSuccess(state, { payload: newAddress }) {
      // state.profileData.addresses.push(newAddress);
      // state.profileData = {
      //   ...state.profileData,
      //   addresses: [...state.profileData.addresses, newAddress],
      // };
      state.isAddNewAddressLoading = false;
    },
    setNewAddressFailure(state) {
      state.isAddNewAddressLoading = false;
    },

    // Save new Addresses Order
    setNewAddressesOrderRequest(state) {
      state.isSetNewAddressesOrderLoading = true;
    },
    setNewAddressesOrderSuccess(state) {
      state.isSetNewAddressesOrderLoading = false;
    },
    setNewAddressesOrderFailure(state) {
      state.isSetNewAddressesOrderLoading = false;
    },

    // Delete Address
    deleteAddressRequest(state) {
      state.isDeleteAddressLoading = true;
    },
    deleteAddressSuccess(state) {
      state.isDeleteAddressLoading = false;
    },
    deleteAddressFailure(state) {
      state.isDeleteAddressLoading = false;
    },

    // Avatar
    setAvatarRequest(state) {
      state.isAvatarLoading = true;
    },
    setAvatarSuccess() {},
    setAvatarFailure(state) {
      state.isAvatarLoading = false;
    },

    // Reset password
    resetPasswordRequest() {},
    resetPasswordSuccess() {},
    resetPasswordFailure() {},

    // Reset password confirm
    resetPasswordConfirmRequest() {},
    resetPasswordConfirmSuccess() {},
    resetPasswordConfirmFailure() {},

    // Activate user
    activateRequest() {},
    activateSuccess() {},
    activateFailure() {},

    // Delete user
    deleteUserRequest(state) {
      state.isDeleteUserLoading = true;
    },
    deleteUserSuccess(state) {
      state.isDeleteUserLoading = false;
    },
    deleteUserFailure(state) {
      state.isDeleteUserLoading = false;
    },

    // Get countries
    getCountriesRequest(state, { payload }) {
      state.isCountriesRequestLoading = true;
    },
    getCountriesSuccess(state, { payload }) {
      state.countriesList = payload;
      state.isCountriesRequestLoading = false;
    },
    getCountriesFailure(state, { payload }) {
      state.isCountriesRequestLoading = false;
    },
  },
});

export default users;
