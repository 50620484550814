import React from "react";
import PropTypes from "prop-types";

export default function ArrowDown(props) {
  return (
    <svg
      style={props.style}
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 16.71 16`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.69,9c-0.01-0.03-0.01-0.06-0.02-0.09c0-0.02,0-0.03,0-0.04L14,0.62C13.89,0.25,13.54,0,13.15,0h-9.5
	C3.26,0,2.92,0.25,2.8,0.62L0.03,8.85c0,0.02,0,0.03,0,0.05C0.01,8.96,0,9.02,0,9.09C0,9.1,0,9.12,0,9.13v5.97
	C0,15.6,0.4,16,0.89,16h14.92c0.49,0,0.89-0.4,0.89-0.89V9.13c0-0.02,0-0.03,0-0.04C16.71,9.06,16.7,9.03,16.69,9z M4.29,1.79h8.21
	l2.08,6.45h-3.78c-0.49,0-0.89,0.4-0.89,0.89v1.57H6.78V9.13c0-0.49-0.4-0.89-0.89-0.89H2.13L4.29,1.79z M14.92,14.21H1.78v-4.18
	h3.21v1.57c0,0.49,0.4,0.89,0.89,0.89h4.92c0.49,0,0.89-0.4,0.89-0.89v-1.57h3.22L14.92,14.21z"
      />
    </svg>
  );
}

ArrowDown.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowDown.defaultProps = {
  className: "",
  width: 11.98,
  height: 11.23,
};
