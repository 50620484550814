import React from "react";

import { Button, Modal, Loader } from "components/simple";
import BootstrapModal from "react-bootstrap/Modal";

import pushToChannelModal from "../PushToChannelModal/modalPushToChannel.module.scss";
import helpers from "styles/helpers.module.scss";
import styles from "./deleteModal.module.scss";

const DeleteModal = ({
  step,
  show,
  setShow,
  channelsList,
  onDeleteListing,
  isDeleteLoading,
}) => {
  return (
    <div>
      <Modal
        setShow={() => setShow(step)}
        show={show}
        containerStyles={pushToChannelModal.modalContainer}
        contentStyles={pushToChannelModal.modalContent}
      >
        <BootstrapModal.Header
          bsPrefix={`${pushToChannelModal.header} ${helpers.contentRowLine}`}
        >
          <BootstrapModal.Title
            id="example-custom-modal-styling-title"
            bsPrefix={pushToChannelModal.title}
          >
            Delete Listing from Channel
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body bsPrefix={pushToChannelModal.modalBody}>
          {channelsList?.length ? (
            channelsList.map((channel) => {
              const channelName = channel.name.toLowerCase();
              return (
                <div
                  key={channel.id}
                  className={`${pushToChannelModal.channel} ${helpers.contentRowLine}`}
                >
                  <div className={pushToChannelModal.channelLogo}>
                    <img src={channel.logo} alt="channel logo" />
                  </div>
                  <div className={pushToChannelModal.channelName}>
                    {channel.name}
                  </div>
                  {isDeleteLoading[channelName] ? (
                    <Loader className={styles.loader} />
                  ) : (
                    <Button
                      onClick={() => onDeleteListing(channelName)}
                      isLoading={isDeleteLoading[channelName]}
                      className={styles.deleteBtn}
                      variant="alert"
                    >
                      Delete
                    </Button>
                  )}
                </div>
              );
            })
          ) : (
            <div className={styles.emptyContainer}>
              <div className={styles.emptyText}>
                Not integrated to any platform yet
              </div>
              <Button onClick={() => setShow(step)} className={styles.closeBtn}>
                Close
              </Button>
            </div>
          )}
          <Button
            onClick={() => onDeleteListing("listacross")}
            isLoading={isDeleteLoading["listacross"]}
            className={styles.deleteBtnList}
            variant="alert"
          >
            Delete from Listacross
          </Button>
        </BootstrapModal.Body>
      </Modal>
    </div>
  );
};

export default DeleteModal;
