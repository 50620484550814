import { API, authorization, contentTypeJson } from "api/api";
import { get, post, remove, patch } from "api/base";

export const fetchMessages = ({ payload }) => {
  return get(`${API}/inbox/messages?search=${payload}`, { ...authorization() });
};

export const updateMessage = ({ uid, message }) => {
  return patch(`${API}/inbox/messages/${uid}`, JSON.stringify(message), {
    ...authorization(),
    ...contentTypeJson(),
  });
};

export const fetchInboxReplies = () => {
  return get(
    `${API}/inbox/replies/
  `,
    { ...authorization() }
  );
};

export const fetchEtsyConversations = () => {
  return get(
    `${API}/inbox/etsy-inbox/etsy-conversations
  `,
    { ...authorization() }
  );
};

export const fetchEtsyConversation = ({ conversationId }) => {
  return get(
    `${API}/inbox/etsy-inbox/etsy-conversations/${conversationId}
  `,
    { ...authorization() }
  );
};

export const deleteEtsyConversation = ({ conversationId }) => {
  return remove(
    `${API}/inbox/replies/${conversationId}
  `,
    null,
    { ...authorization() }
  );
};

export const sendEtsyMessage = (body) => {
  return post(
    `${API}/inbox/etsy-inbox/send-message-to-etsy`,
    JSON.stringify(body),
    {
      ...authorization(),
      ...contentTypeJson(),
    }
  );
};
