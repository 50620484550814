import { createSelector } from "reselect";

// Channels
const channelsSelector = (state) => state.platforms.channelsList;
export const selectorChannels = createSelector(
  channelsSelector,
  (items) => items
);
const channelsLoadingSelector = (state) =>
  state.platforms.isChannelsRequestLoading;
export const selectorChannelsLoading = createSelector(
  channelsLoadingSelector,
  (bool) => bool
);

// Channels to push listings
export const selectorChannelsToPush = createSelector(
  channelsSelector,
  (channels) => channels?.filter((ch) => ch.push_listings)
);

// Disconnect channel/integration
const isDisconnectChannelLoading = (state) =>
  state.platforms.isDisconnectChannelLoading;

export const selectorIsDisconnectChannelLoading = createSelector(
  isDisconnectChannelLoading,
  (items) => items
);
