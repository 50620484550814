const customStyles = {
  table: {
    style: {
      color: "#262626",
      backgroundColor: "transparent",
      // overflowX: "auto",
      width: '100vw',
      maxWidth: '100vw',
    },
  },
  tableWrapper: {
    style: {
      display: "flex",
      flex: 1,
      overflowY: "auto",
      overflowX: "auto",
    },
  },
  rows: {
    style: {
      fontFamily: "Aeonik",
      // marginBottom: 8,
      borderBottom: "1px solid #eaeaea",
      minHeight: 84,
      fontSize: "19px",
      // boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.12)"
    },
  },
  headCells: {
    style: {
      fontFamily: "Aeonik",
      fontSize: "19px",
      fontWeight: "normal",
      height: 84,
      maxHeight: 84,
      color: "#808080",
      display: "flex",
      alignItems: "center",
    },
    inactiveSortStyle: {
      opacity: 1,

      "&:focus": {
        color: "#808080",
      },
      "&:hover": {
        color: "#808080",
      },
    },
  },
  headRow: {
    style: {
      backgroundColor: "transparent",
      borderBottom: "1px solid #eaeaea",
      height: 84,
      minHeight: "84px",
    },
  },

  expanderRow: {
    style: {
      marginTop: -20,
      paddingTop: 5,
      marginBottom: 15,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  expanderCell: {
    style: {
      order: 1,
    },
  },
  expanderButton: {
    style: {
      "&:hover:enabled": {
        cursor: "pointer",
      },
      "&:hover:not(:disabled)": {
        cursor: "pointer",
        backgroundColor: "transparent",
      },
      "&:focus": {
        outline: "none",
        backgroundColor: "transparent",
      },
      svg: {
        fill: "#FF455A",
        width: 30,
        height: 30,
      },
    },
  },
  noData: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
  },
};

export default customStyles;
