/* eslint-disable */
import React from "react";
import { Switch } from "react-router";

// Routing
import * as routes from "router/routes";

// Screens
import DashboardScreen from "screens/Dashboard/DashboardScreen/DashboardScreen";
import Inventory from "screens/Inventory";
import CreateListing from "screens/CreateListing";
import Profile from "screens/Profile/Profile";
import SelectNewPlan from "screens/SelectNewPlan/SelectNewPlan";
import Messages from "screens/Messages/Messages";
import Integrations from "screens/Integrations/Integrations";
import TargetIntegration from "screens/Integrations/IntegrationScreen/IntegrationScreen";
import Settings from "screens/Settings/Settings";
import Payment from "screens/Payment/Payment";
import AutoReplies from "screens/AutoReplies/mainScreen/AutoReplies";
import StepsBody from "screens/AutoReplies/AutoRepliesSteps/StepsBody";

// Permissions
import PrivateRoute from "components/PrivateRoute";
// import { AccessControlRoute, permissions } from "components/permissions";

const DashboardContent = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={routes.dashboardScreen}
        component={DashboardScreen}
      />

      <PrivateRoute
        exact
        path={routes.createListingScreen}
        component={CreateListing}
      />

      <PrivateRoute exact path={routes.myProfileScreen} component={Profile} />

      <PrivateRoute
        exact
        path={routes.selectNewPlanScreen}
        component={SelectNewPlan}
      />
      <PrivateRoute
        exact
        path={`${routes.paymentScreen}/:id`}
        component={Payment}
      />

      <PrivateRoute
        exact
        path={routes.integrationsScreen}
        component={Integrations}
      />
      <PrivateRoute
        exact
        path={`${routes.integrationsScreen}/:id`}
        component={TargetIntegration}
      />
      <PrivateRoute
        exact
        path={`${routes.inventoryScreen}/:id?/edit`}
        component={CreateListing}
      />
      <PrivateRoute
        exact
        path={`${routes.inventoryScreen}/:id?/:step?`}
        component={Inventory}
      />

      <PrivateRoute
        path={`${routes.messagesScreen}/:id?`}
        component={Messages}
      />
      <PrivateRoute
        exact
        path={routes.autoRepliesScreen}
        component={AutoReplies}
      />
      <PrivateRoute
        exact
        path={`${routes.autoRepliesScreen}/:step`}
        component={StepsBody}
      />
      <PrivateRoute
        exact
        path={`${routes.autoRepliesScreen}/:step/:autoReplyId/edit`}
        component={StepsBody}
      />

      <PrivateRoute
        exact
        path={`${routes.autoRepliesScreen}/:step/:listingId/listing`}
        component={StepsBody}
      />

      <PrivateRoute exact path={routes.settingsScreen} component={Settings} />
    </Switch>
  );
};

export default DashboardContent;
