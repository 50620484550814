import React from "react";

import { Input } from "../index";

import styles from "./search-input.module.scss";

import searchIcon from "assets/icons/search.png";

export default function SearchInput({ ...props }) {
  return (
    <div className={styles.container}>
      <Input
        containerClass={styles.inputContainer}
        inputClass={styles.input}
        placeholder="Search"
        {...props}
      />
      <img className={styles.icon} src={searchIcon} alt="search" />
    </div>
  );
}
