import { createSelector } from "reselect";

// Conversations
const conversationsSelector = (state) => state.messages.etsyConversationsList;
export const selectorConversations = createSelector(
  conversationsSelector,
  (items) => items
);

// Conversations error
const conversationsErrorSelector = (state) =>
  state.messages.etsyConversationsListError;
export const selectorErrorConversations = createSelector(
  conversationsErrorSelector,
  (error) => error
);

// Open conversation data
const openConversationSelector = (state) => state.messages.openConversation;
export const selectorOpenConversation = createSelector(
  openConversationSelector,
  (conversation) => conversation
);

// Messages
const messagesSelector = (state) => state.messages.messages;
export const selectorMessages = createSelector(
  messagesSelector,
  (items) => items
);

// Messages Loading
const messagesLoadingSelector = (state) =>
  state.messages.isMessagesRequestLoading;
export const selectorLoadingMessages = createSelector(
  messagesLoadingSelector,
  (bool) => bool
);

// Selected messages
const selectedMessagesSelector = (state) => state.messages.selectedMessages;
export const selectorSelectedMessages = createSelector(
  selectedMessagesSelector,
  (items) => items
);

// Searched conversations
export const selectorSearchedConversations = createSelector(
  [messagesSelector, conversationsSelector],
  (messages, convs) => {
    return messages?.reduce((acc, m, i) => {
      const c = convs?.find((c) => c.id === m.conversation_id);
      if (c) {
        return [
          ...acc,
          {
            ...c,
            message: m.text,
            created_at: m.created_at,
            search_id: i,
          },
        ];
      }
      return false;
    }, []);
  }
);
