import { createSelector } from "reselect";

// All cards
const cardsSelector = (state) => state.payments.cards;
export const selectorCards = createSelector(cardsSelector, (items) => items);

// Default card
export const selectorDefaultCard = createSelector(
  cardsSelector,
  (cards) => cards && cards.filter((card) => card.default_card)[0]
);

// Cards loading
const isCardsListLoadingSelector = (state) => state.payments.isCardsListLoading;
export const selectorCardsListLoading = createSelector(
isCardsListLoadingSelector,
  (bool) => bool
);
