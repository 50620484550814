import { store } from "App";
import usersReducer from "store/entities/Users/users.reducer";
import subscriptionsReducer from "store/entities/Subscriptions/subscriptions.reducer";

const { logoutSuccess } = usersReducer.actions;
const { expiredSubscriptionRequest } = subscriptionsReducer.actions;

const getMessage = async (response) => {
  let message = "";
  const dispatch = store.dispatch;

  if (response.status === 401) {
    // #TODO: add dispatch(logoutRequest()) here and remove all logoutRequest from saga workers
    try {
      const responseErrors = await response.json();
      // message = { ...responseErrors, logout: "Session expired" };
      message = { ...responseErrors, non_field_errors: "Session expired" };
    } catch (err) {
      // message = { logout: "Session expired" };
      message = { non_field_errors: "Session expired" };
    }
    dispatch(logoutSuccess());
    return message;
  }

  if (response.status === 403) {
    try {
      const responseErrors = await response.json();

      message = {
        ...responseErrors,
        non_field_errors: "Forbidden request",
      };

      // #TODO: change to "code" when will be done on API
      // if (responseErrors.code === true) {
      if (responseErrors) {
        dispatch(expiredSubscriptionRequest(responseErrors.detail));
      }
    } catch (err) {
      message = { non_field_errors: "Forbidden request" };
    }
    return message;
  }

  try {
    const responseErrors = await response.json();

    // eslint-disable-next-line no-prototype-builtins
    if (responseErrors.hasOwnProperty("non_field_error")) {
      message = { non_field_errors: responseErrors.non_field_error };
      // eslint-disable-next-line no-prototype-builtins
    } else if (responseErrors.hasOwnProperty("detail")) {
      message = { non_field_errors: responseErrors.detail };
      // eslint-disable-next-line no-prototype-builtins
    } else if (responseErrors.hasOwnProperty("error")) {
      message = { non_field_errors: responseErrors.error };
    } else {
      return responseErrors;
    }
  } catch (error) {
    message = { non_field_errors: response.statusText };
  }

  return message;
};

export const checkStatus = async (response) => {
  const { status } = response;

  if (status !== 200 && status !== 201 && status !== 202 && status !== 204) {
    const message = await getMessage(response);
    return { message };
  }
  try {
    if (status !== 204) {
      // eslint-disable-next-line no-return-await
      return await response.json();
    }
  } catch (e) {
    return {};
  }
  return {};
};
