import { createSelector } from "reselect";

const inventorySelector = (state) => state.inventory;

export const selectorInventory = createSelector(
  inventorySelector,
  (items) => items
);

// Create listing loading
const createLoadingSelector = (state) => state.inventory.createListingLoading;
export const selectorCreateLoading = createSelector(
  createLoadingSelector,
  (bool) => bool
);

// Edit listing images
const imagesSelector = (state) => state.inventory.editingListingImages;
export const selectorEditingImages = createSelector(
  imagesSelector,
  (items) => items
);

// Edit listing images loading
const imagesSelectorLoading = (state) =>
  state.inventory.editingListingImagesLoading;
export const selectorEditingImagesLoading = createSelector(
  imagesSelectorLoading,
  (bool) => bool
);
