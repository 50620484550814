import React from "react";

import Button from "components/simple/Button/Button";

import styles from "./planCard.module.scss";
import helpers from "styles/helpers.module.scss";

import checkIcon from "assets/icons/check-green.png";

const PlanCard = ({ plan, currentPlan, selectNewSubscriptionHandler }) => {
  const {
    id,
    cost,
    // description,
    short_description: shortDescription,
    max_integrations: maxIntegrations,
    // max_listing: maxlisting,
    max_pushes: maxPushes,
    plan_type: planType,
    plan_title: planTitle,
    // is_active,
    // expires_in_days,
    non_standart: nonStandart,
  } = plan;

  const [integer, coins] = cost?.length ? cost.split(".") : [];
  return (
    <div className={styles.cardContainer}>
      <div className={`${styles.priceBlock} ${helpers.contentRowLine}`}>
        {cost ? (
          <>
            <div className={styles.price}>
              <div className={styles.integer}>{`$${integer}`}</div>
              <div className={styles.coins}>{`.${coins}`}</div>
            </div>
            <div className={styles.term}>Per Month</div>
          </>
        ) : (
          <div className={styles.withoutPrice}>{planTitle}</div>
        )}
      </div>
      <div className={`${styles.planTitle} ${helpers.contentRowLine}`}>
        {planType}
      </div>
      <div className={`${styles.benefits} ${helpers.contentRowLine}`}>
        <div className={styles.typeTitle}>{shortDescription}</div>
        <div className={styles.checkRow}>
          <img src={checkIcon} alt="check" />
          <div>
            {nonStandart
              ? "100+ Listing Insertions Per Month"
              : `Up to ${maxPushes} Listing Insertions Per Month`}
          </div>
        </div>
        <div className={styles.checkRow}>
          <img src={checkIcon} alt="check" />
          <div>{`${maxIntegrations} Channels`}</div>
        </div>
      </div>
      <Button
        onClick={() => selectNewSubscriptionHandler(id)}
        className={styles.cardBtn}
        disabled={currentPlan && currentPlan === id}
      >
        {currentPlan && currentPlan === id ? "Current Plan" : "Select Plan"}
      </Button>
    </div>
  );
};

export default PlanCard;
