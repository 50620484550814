/* eslint-disable no-return-await */
import { checkStatus } from "api/checkStatus";

export const makeRequest = request => async payload => {
  return await request(payload)
    .then(async response => await checkStatus(response))
    .catch(error => {
      return { message: { non_field_errors: error.message } };
    });
};
