import React from "react";
import PropTypes from "prop-types";

export default function ArrowDown(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <image
        id="Vector_Smart_Object"
        data-name="Vector Smart Object"
        width={props.width}
        height={props.height}
        xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAKCAYAAABWiWWfAAAA7UlEQVQokZXPoUpDYRjG8Z8fyFgRFExeg01Y9RiUaTEoRu16D5bdgbKqLGkYGpQjpllXDHoTYyysLAzGDHuFcdhB95WP532e/8P7ruzUrz+xiTq+LP+2kaOfUMEWPlBbsqiGTvCVhAzfWMc79v5ZlEV+I/gsoYdddLGGFxz9UXSI18h3g++lMAfYj5WreMJpSdEJniPXCW4AaS40jI1yrOIBF4WiczyGn0d++GumQniEY7TDu8NVeJe4j3k7cqN5uFgGY5yhFfoGb7gN3Qp/XAQXlcHE7MRm6IP4mzGfLILKymBqdloj4EboaRnwA/pbMiG3/L41AAAAAElFTkSuQmCC"
      />
    </svg>
  );
}

ArrowDown.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

ArrowDown.defaultProps = {
  className: "",
  width: 19,
  height: 10,
  fill: "#7d7d7d",
};
