import { createSelector } from "reselect";

// Get change password loading
const isPasswordLoadingSelector = (state) =>
  state.user.isPasswordRequestLoading;
export const selectorIsPasswordLoading = createSelector(
  isPasswordLoadingSelector,
  (item) => item
);

// Delete User loading
const deleteUserLoadingSelector = (state) => state.user.isDeleteUserLoading;
export const selectorDeleteUserLoading = createSelector(
  deleteUserLoadingSelector,
  (item) => item
);

// Billing Info
const billingInfoSelector = (state) => state.payments.billingData;
export const selectorBillingInfo = createSelector(
  billingInfoSelector,
  (item) => item
);

const billingInfoLoadingSelector = (state) =>
  state.payments.isBillingInfoLoading;
export const selectorBillingInfoLoading = createSelector(
  billingInfoLoadingSelector,
  (item) => item
);

// Delete address loading
const isDeleteAddressLoadingSelector = (state) =>
  state.user.isDeleteAddressLoading;
export const selectorDeleteAddressLoading = createSelector(
  isDeleteAddressLoadingSelector,
  (bool) => bool
);
