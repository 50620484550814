import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import subscriptionReducer from "store/entities/Subscriptions/subscriptions.reducer";
import notificationsReducer from "../Notification/notifications.reducer";
// import usersReducer from "store/entities/Users/users.reducer";

import * as service from "./subscriptions.service";

import { makeRequest } from "../../sagas/helpers";

const {
  subscriptionRequest,
  subscriptionSuccess,
  subscriptionFailure,
  fetchPlansRequest,
  fetchPlansSuccess,
  fetchPlansFailure,
  expiredSubscriptionRequest,
} = subscriptionReducer.actions;

const {
  actions: { addNotification: addNotificationAction },
} = notificationsReducer;

// workers

function* currentSubscriptionWorker() {
  try {
    const response = yield call(makeRequest(service.fetchCurrentSubscription));
    if (response.message) {
      yield put(subscriptionFailure());
    } else {
      yield put(subscriptionSuccess(response));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(subscriptionFailure());
  }
}

// function* currentSubscriptionWorker() {
//   try {
//     const response = yield call(makeRequest(service.fetchCurrentSubscription));
//     if (response.message) {
//       if (response.message.logout) {
//         yield put(logoutSuccess(response.message.logout));
//       } else {
//         yield put(subscriptionFailure(response.message));
//       }
//     } else {
//       yield put(subscriptionSuccess(response));
//     }
//   } catch (error) {
//     yield put(addNotificationAction({ msg: "Error", type: "danger" }));
//     yield put(subscriptionFailure());
//   }
// }

function* allSubscriptionsWorker() {
  try {
    const response = yield call(makeRequest(service.fetchAvailablePlans));
    if (response.message) {
      yield put(fetchPlansFailure());
    } else {
      yield put(fetchPlansSuccess(response.results));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(fetchPlansFailure());
  }
}

// function* allSubscriptionsWorker() {
//   try {
//     const response = yield call(makeRequest(service.fetchAvailablePlans));
//     if (response.message) {
//       if (response.message.logout) {
//         yield put(logoutSuccess(response.message.logout));
//       } else {
//         yield put(fetchPlansFailure(response.message));
//       }
//     } else {
//       yield put(fetchPlansSuccess(response.results));
//     }
//   } catch (error) {
//     yield put(addNotificationAction({ msg: "Error", type: "danger" }));
//   }
// }

function* expiredSubscriptionWorker({ payload: message }) {
  // window top toast on 403 response
  yield put(
    addNotificationAction({
      msg: message,
      type: "danger",
      position: "top",
      link: "/select-new-plan",
    })
  );
}

// watchers

function* watchCurrentSubscription() {
  yield takeEvery(subscriptionRequest, currentSubscriptionWorker);
}

function* watchAllSubscriprions() {
  yield takeEvery(fetchPlansRequest, allSubscriptionsWorker);
}

function* watchExpiredSubscriprion() {
  yield takeEvery(expiredSubscriptionRequest, expiredSubscriptionWorker);
}

export default function* subscriptionSaga() {
  yield all([
    fork(watchCurrentSubscription),
    fork(watchAllSubscriprions),
    fork(watchExpiredSubscriprion),
  ]);
}
