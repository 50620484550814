import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";

import styles from "./sidebar-date-time.module.scss";
import {
  selectorProfile,
  selectorProfileLoading,
  selectorTimezones,
  selectorTimezonesLoading,
} from "selectors/userSelectors";
import userReducer from "store/entities/Users/users.reducer";

const {
  actions: {
    getUserRequest,
    getTimezonesRequest,
  },
} = userReducer;

export default function SidebarDateTime() {
  const dispatch = useDispatch();
  const timezones = useSelector(selectorTimezones);
  const isTimezonesLoading = useSelector(selectorTimezonesLoading);

  const profile = useSelector(selectorProfile);
  const isProfileLoading = useSelector(selectorProfileLoading);

  let [time, setTime] = useState(null);

  const updateTime = () => {
    setTime(moment.now());
  };

  useEffect(() => {
    if (!profile) {
      dispatch(getUserRequest());
    }
    if (!timezones) {
      dispatch(getTimezonesRequest());
    }
    
    updateTime();
    const intervalIndex = setInterval(updateTime, 1000);
    return () => {
      clearInterval(intervalIndex);
    };
  }, []);

  let selectedTimezone = timezones
    ?.find((item) => item?.split(" (")[0] === profile?.timezone)
    ?.split(" (GMT ")[1]
    .replace(/[()]/g, "");

  const getZone = () => {
    let zone;
    if (selectedTimezone) {
      if (selectedTimezone[0] === "0") {
        zone = "+00:00";
      } else if (selectedTimezone?.length < 3) {
        zone = `${selectedTimezone[0]}0${selectedTimezone[1]}:00`;
      } else if (selectedTimezone?.length >= 3) {
        zone = `${selectedTimezone}:00`;
      }
    }
    return zone;
  };

  let zone = getZone()

  return (
    <>
      {time && !isProfileLoading && !isTimezonesLoading ? (
        <div className={styles.dateTime}>
          <div className={styles.time}>
            {/* {moment(time).zone(getZone()).format("hh:mm A")} */}
            {moment(time).zone(zone ? zone : '').format("hh:mm A")}
          </div>
          <div className={styles.date}>
            {/* {moment(time).zone(getZone()).format("dddd, Do of MMMM")} */}
            {moment(time).zone(zone ? zone : '').format("dddd, Do of MMMM")}
          </div>
        </div>
      ) : null}
    </>
  );
}
