import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Sidebar from "./Sidebar";
// import { ProfileDropDown } from "components/simple";

import DashboardContent from "./DashboardContent";
// import DashboardFooter from "./DashboardFooter";
import Navbar from "./Navbar";

import { connect } from "react-redux";

import userReducer from "store/entities/Users/users.reducer";

import styles from "./dashboard.module.scss";

const {
  actions: { getUserRequest },
} = userReducer;

const Dashboard = ({ getUser = () => {} }) => {
  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles["dashboard-container"]}>
      <Sidebar />
      <div className={styles["dashboard-wrapper"]}>
        <Navbar />
        {/* <header className={styles.header}>
          <ProfileDropDown className={styles.profileDropDown} name="Federica" />
        </header> */}
        <div className={styles["dashboard-content"]}>
          <DashboardContent />
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  getUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(getUserRequest()),
  };
};

export default connect(undefined, mapDispatchToProps)(Dashboard);
