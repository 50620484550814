import React from "react";
import { Link } from "react-router-dom";

import * as routes from "router/routes/index";

import ContentContainer from "components/simple/ContentContainer";
import Button from "components/simple/Button";
import Loader from "components/simple/Loader";

import styles from "./subscription.module.scss";
import helpers from "styles/helpers.module.scss";

import checkIcon from "assets/icons/check-green.png";

const Subscription = ({ subscriptionData }) => {
  if (subscriptionData) {
    var {
      active_plan: {
        plan_type: planType,
        max_pushes: maxPushes,
        max_integrations: maxIntegrations,
        short_description: shortDescription,
      },
    } = subscriptionData;
  }

  return (
    <ContentContainer className={styles.subscriptionContainer}>
      <ContentContainer className={styles.infoBlock}>
        <div className={`${helpers.contentRowLine} ${helpers.sectionTitle}`}>
          Subscription
        </div>
        {subscriptionData ? (
          <>
            <div className={styles.infoContainer}>
              <div>
                <div className={styles.planTitle}>{planType}</div>
                <div className={styles.planSubtitle}>{shortDescription}</div>
                <div>
                  <div className={styles.checkRow}>
                    <img src={checkIcon} alt="check" />
                    <div>{`Up to ${maxPushes} Insertions Per Month`}</div>
                  </div>
                  <div className={styles.checkRow}>
                    <img src={checkIcon} alt="check" />
                    <div>{`${maxIntegrations} Integrations`}</div>
                  </div>
                </div>
              </div>
              <Link to={routes.selectNewPlanScreen}>
                <Button className={styles.btn}>Upgrade</Button>
              </Link>
            </div>
          </>
        ) : (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        )}
      </ContentContainer>
      <div className={styles.imgBlock}></div>
    </ContentContainer>
  );
};

export default Subscription;
