export const API = `${process.env.REACT_APP_API_URL}/api`;

export const authorization = () => {
  return { Authorization: `Token ${localStorage.getItem("token")}` };
};

export const contentTypeJson = () => {
  return { "Content-Type": "application/json" };
};

export const contentTypeMedia = () => {
  return { "Content-Type": "multipart/form-data" };
};
