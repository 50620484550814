import { combineReducers } from "redux";

import Header from "../entities/Header/header.reducer";
import Users from "../entities/Users/users.reducer";
import Notifications from "../entities/Notification/notifications.reducer";
import Inventory from "../entities/Inventory/inventory.reducer";
import Subscription from "../entities/Subscriptions/subscriptions.reducer";
import Payments from "../entities/Payments/payments.reducer";
import Platforms from "../entities/Platforms/platforms.reducer";
import Replies from "../entities/Replies/replies.reducer";
import Modal from "../entities/Modal/modal.reducer";
import Messages from "../entities/Messages/messages.reducer";

const reducers = combineReducers({
  header: Header.reducer,
  user: Users.reducer,
  notification: Notifications.reducer,
  inventory: Inventory.reducer,
  subscription: Subscription.reducer,
  payments: Payments.reducer,
  platforms: Platforms.reducer,
  replies: Replies.reducer,
  modal: Modal.reducer,
  messages: Messages.reducer,
});

export default reducers;
