import React, { useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

import * as routes from "router/routes";

import usersReducer from "store/entities/Users/users.reducer";
import notificationsReducer from "store/entities/Notification/notifications.reducer";

import { Input, Button } from "components/simple";
import { Link } from "react-router-dom";

import authStyles from "../AuthContainer/auth-container.module.scss";

const { resetPasswordConfirmRequest } = usersReducer.actions;

const {
  actions: { addNotification },
} = notificationsReducer;

const RestorePassword = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ newPassword1: "", newPassword2: "" });
  const { newPassword1, newPassword2 } = state;

  const params = useParams();

  const { token, uid } = params;

  const inputChangeHandler = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const submitHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    // clearErrorMessage();
    if (newPassword1 !== newPassword2) {
      dispatch(
        addNotification({ msg: "Passwords are not equal", type: "danger" })
      );
    } else {
      dispatch(
        resetPasswordConfirmRequest({ newPassword1, newPassword2, token, uid })
      );
    }
  };

  return (
    <div>
      <div className={authStyles.title}>Change Password</div>
      <form className={authStyles.form} onSubmit={submitHandler}>
        <Input
          name="newPassword1"
          type="password"
          label={"Password"}
          value={newPassword1}
          onChange={inputChangeHandler}
          required
        />
        <Input
          name="newPassword2"
          type="password"
          label={"Confirm Password"}
          value={newPassword2}
          onChange={inputChangeHandler}
          required
        />
        <div className={authStyles.buttonContainer}>
          <Button type="submit">Change Password</Button>
          {/* <Link to={routes.resetPasswordScreen}>Forgot password?</Link> */}
        </div>

        <div className={authStyles.altActionContainer}>
          Remember Your password?{" "}
          <Link to={routes.loginScreen}>Sign In Here</Link>
        </div>
      </form>
    </div>
  );
};

export default RestorePassword;
