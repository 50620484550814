import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "Dashboard",
  linkBack: null,
};

const header = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeaderTitle(state, { payload }) {
      state.title = payload.title;
      state.isBtnBack = payload.linkBack;
    },

    // Logout
    logoutSuccess(state, { payload }) {
      state = { ...initialState };
    },
  },
});

export default header;
