import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";

import { history, routes } from "router";

import notificationsReducer from "../Notification/notifications.reducer";
import usersReducer from "store/entities/Users/users.reducer";
import paymentsReducer from "store/entities/Payments/payments.reducer";
import subscriptionReducer from "store/entities/Subscriptions/subscriptions.reducer";

import * as service from "./payments.service";

import { makeRequest } from "../../sagas/helpers";

const {
  actions: {
    proceedSubscription,
    proceedSubscriptionWithExistingCard,

    createBillingInfoRequest,
    createBillingInfoSuccess,
    createBillingInfoFailure,

    createStripeCustomerRequest,
    createStripeCustomerSuccess,
    // createStripeCustomerFailure,

    fetchCardsRequest,
    fetchCardsSuccess,
    fetchCardsFailure,

    addCardRequest,
    addCardSuccess,
    addCardFailure,

    removeCardRequest,
    removeCardSuccess,
    removeCardFailure,

    setDefaultCardRequest,
    setDefaultCardSuccess,
    setDefaultCardFailure,

    getPaymentSubscriptionsRequest,
    getPaymentSubscriptionsSuccess,
    getPaymentSubscriptionsFailure,

    createPaymentSubscriptionRequest,
    createPaymentSubscriptionSuccess,
    createPaymentSubscriptionFailure,

    editPaymentSubscribtionRequest,
    editPaymentSubscribtionSuccess,
    editPaymentSubscribtionFailure,

    // Fetch Billing Info
    fetchBillingInfoRequest,
    fetchBillingInfoSuccess,
    fetchBillingInfoFailure,
  },
} = paymentsReducer;

const {
  actions: { subscriptionRequest },
} = subscriptionReducer;

const { logoutSuccess } = usersReducer.actions;

const {
  actions: { addNotification: addNotificationAction },
} = notificationsReducer;

const getSelectedPlan = (state) => state.subscription.selectedPlanId;
const getUserProfile = (state) => state.user.profileData;

// workers

// Change subscription
function* proceedSubscriptionWorker({ payload }) {
  const { paymentMethod, billingInfo } = payload;

  const profile = yield select(getUserProfile);

  yield put(
    createBillingInfoRequest({
      billingInfo: { ...billingInfo, user_profile_id: profile?.id },
      paymentMethod,
      isChangingSubscription: true,
    })
  );
}

function* createBillingInfoRequestWorker({ payload }) {
  const { paymentMethod, billingInfo, isChangingSubscription } = payload;

  try {
    const response = yield call(makeRequest(service.createBillingInfo), {
      billingInfo,
    });
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(createBillingInfoFailure(response.message));
      }
    } else {
      yield put(
        createBillingInfoSuccess({
          res: response?.results,
          paymentMethod,
          isChangingSubscription,
        })
      );
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(createBillingInfoFailure());
  }
}

function* createBillingInfoSuccessWorker({ payload }) {
  const { paymentMethod, isChangingSubscription } = payload;
  // TODO: check if already subscribed
  // if () {}

  // already create user in billing info
  // yield put(
  //   createStripeCustomerRequest({ paymentMethod, isChangingSubscription })
  // );

  yield put(
    createStripeCustomerSuccess({ paymentMethod, isChangingSubscription })
  );
  yield put(fetchBillingInfoRequest());
}

function* proceedSubscriptionWithExistingCardWorker() {
  yield put(getPaymentSubscriptionsRequest({ isChangingSubscription: true }));
}

// Create stripe customer
function* createStripeCustomerRequestWorker({ payload }) {
  // const { paymentMethod, isChangingSubscription } = payload;
  // try {
  //   const response = yield call(
  //     makeRequest(service.createStripeCustomer),
  //     paymentMethod
  //   );
  //   if (response.message) {
  //     if (response.message.logout) {
  //       yield put(logoutSuccess(response.message.logout));
  //     } else {
  //       // TODO: refactor this
  //       if (
  //         response.message?.length &&
  //         response.message[0].match(
  //           /Invalid data: Provided user .* already has a payment method/
  //         )
  //       ) {
  //         yield put(
  //           createStripeCustomerSuccess({
  //             paymentMethod,
  //             isChangingSubscription,
  //           })
  //         );
  //       } else {
  //         yield put(createStripeCustomerFailure(response.message));
  //       }
  //     }
  //   } else {
  //     yield put(
  //       createStripeCustomerSuccess({ paymentMethod, isChangingSubscription })
  //     );
  //   }
  // } catch (error) {
  //   yield put(
  //     addNotificationAction({ msg: JSON.stringify(error), type: "danger" })
  //   );
  //   yield put(createStripeCustomerFailure());
  // }
}

// NOTE thats why addCardRequest fetches twice
function* createStripeCustomerSuccessWorker({ payload }) {
  const { paymentMethod, isChangingSubscription } = payload;
  yield put(addCardRequest({ paymentMethod, isChangingSubscription }));
}

// Fetch cards
function* fetchCardsRequestWorker() {
  try {
    const response = yield call(makeRequest(service.fetchCards));
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(fetchCardsFailure(response.message));
      }
    } else {
      yield put(fetchCardsSuccess(response?.results));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(fetchCardsFailure());
  }
}

// Add card
function* addCardWorker({ payload }) {
  const { paymentMethod, isChangingSubscription } = payload;

  try {
    const response = yield call(makeRequest(service.createCard), paymentMethod);
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(addCardFailure(response.message));
        // NOTE set notification message for add card failure here
        // yield put(addNotificationAction({ msg: response.message.payment_token[0], type: "danger" }));
      }
    } else {
      yield put(addNotificationAction({ msg: "Card added successfully.", type: "success" }));
      yield put(addCardSuccess({ response, isChangingSubscription }));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(addCardFailure());
  }
}

function* addCardSuccessWorker({ payload }) {
  const {
    response: { id: cardId },
    isChangingSubscription,
  } = payload;

  yield put(setDefaultCardRequest({ cardId, isChangingSubscription }));
  yield put(fetchCardsRequest());
}

function* removeCardRequestWorker({ payload }) {
  const id = payload;

  try {
    const response = yield call(makeRequest(service.removeCard), id);
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(removeCardFailure(response.message));
      }
    } else {
      yield put(addNotificationAction({ msg: "Card removed successfully.", type: "success" }));
      yield put(removeCardSuccess(response));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(removeCardFailure());
  }
}

function* removeCardSuccessWorker() {
  yield put(fetchCardsRequest());
}

function* setDefaultCardRequestWorker({ payload }) {
  const { cardId, isChangingSubscription } = payload;

  try {
    const response = yield call(makeRequest(service.setDefaultCard), cardId);
    // if (response.message) {
    //   if (response.message.logout) {
    //     yield put(logoutSuccess(response.message.logout));
    //   } else {
    //     yield put(setDefaultCardFailure(response.message));
    //   }
    // } else {
    //   yield put(setDefaultCardSuccess({ response, isChangingSubscription }));
    // }

    // TODO talk to Andriy to set success message from server. We need other sign of success -
    // not a success message.
    if (response.message !== "The card was set as default payment card") {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(setDefaultCardFailure(response.message));
      }
    } else {
      yield put(setDefaultCardSuccess({ response, isChangingSubscription }));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(setDefaultCardFailure());
  }
}

function* setDefaultCardSuccessWorker({ payload }) {
  const { isChangingSubscription } = payload;

  if (isChangingSubscription) {
    yield put(getPaymentSubscriptionsRequest());
  } else {
    yield put(fetchCardsRequest());
  }
}

function* fetchSubscriptionsRequestWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.fetchPaymentSubscriptions));
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(getPaymentSubscriptionsFailure(response.message));
      }
    } else {
      if (
        response?.results &&
        response.results?.length &&
        response.results[0]
      ) {
        yield put(getPaymentSubscriptionsSuccess(response.results[0]?.id));
      } else {
        yield put(createPaymentSubscriptionRequest());
      }
    }
  } catch (error) {
    yield put(
      addNotificationAction({ msg: JSON.stringify(error), type: "danger" })
    );
    yield put(getPaymentSubscriptionsFailure());
  }
}

function* fetchSubscriptionsSuccessWorker({ payload }) {
  yield put(editPaymentSubscribtionRequest({ oldPlanId: payload }));
}

function* createPaymentSubscriptionRequestWorker() {
  const newPlanId = yield select(getSelectedPlan);

  try {
    const response = yield call(
      makeRequest(service.createPaymentSubscription),
      newPlanId
    );
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(createPaymentSubscriptionFailure(response.message));
      }
    } else {
      yield put(createPaymentSubscriptionSuccess(response));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(createPaymentSubscriptionFailure());
  }
}

function* patchSubscriptionRequestWorker({ payload }) {
  const { oldPlanId } = payload;
  const newPlanId = yield select(getSelectedPlan);

  try {
    const response = yield call(makeRequest(service.selectNewSubscription), {
      currentPaymenySubscriptionId: oldPlanId,
      planId: newPlanId,
    });
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(editPaymentSubscribtionFailure(response.message));
      }
    } else {
      yield put(editPaymentSubscribtionSuccess(response));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(editPaymentSubscribtionFailure());
  }
}

function* editPaymentSubscribtionSuccessWorker() {
  yield put(
    addNotificationAction({
      msg: "Subscription changed successfully",
      type: "success",
    })
  );
  yield put(subscriptionRequest());
  yield history.push(routes.myProfileScreen);
}

// Fetch billing info
function* fetchBillingInfoWorker() {
  try {
    const response = yield call(makeRequest(service.fetchBillingInfo));
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(fetchBillingInfoFailure(response.message));
      }
    } else {
      yield put(fetchBillingInfoSuccess(response));
    }
  } catch (error) {
    yield put(addNotificationAction({ msg: "Error", type: "danger" }));
    yield put(fetchBillingInfoFailure());
  }
}

// function* currentSubscriptionWorker() {
//   try {
//     const response = yield call(makeRequest(service.fetchCurrentSubscription));
//     if (response.message) {
//       if (response.message.logout) {
//         yield put(logoutSuccess(response.message.logout));
//       } else {
//         yield put(subscriptionFailure(response.message));
//       }
//     } else {
//       yield put(subscriptionSuccess(response));
//     }
//   } catch (error) {
//     yield put(addNotificationAction({ msg: "Error", type: "danger" }));
//     yield put(subscriptionFailure());
//   }
// }

// watchers

function* watchProceedSubscription() {
  yield takeEvery(proceedSubscription, proceedSubscriptionWorker);
}

function* watchProceedSubscriptionWithExistingCard() {
  yield takeEvery(
    proceedSubscriptionWithExistingCard,
    proceedSubscriptionWithExistingCardWorker
  );
}

function* watchCreateBillingInfoRequest() {
  yield takeEvery(createBillingInfoRequest, createBillingInfoRequestWorker);
}

function* watchCreateBillingInfoSuccess() {
  yield takeEvery(createBillingInfoSuccess, createBillingInfoSuccessWorker);
}

function* watchCreateStripeCustomerRequest() {
  yield takeEvery(
    createStripeCustomerRequest,
    createStripeCustomerRequestWorker
  );
}

function* watchCreateStripeCustomerSuccess() {
  yield takeEvery(
    createStripeCustomerSuccess,
    createStripeCustomerSuccessWorker
  );
}

function* watchFetchCardsRequest() {
  yield takeEvery(fetchCardsRequest, fetchCardsRequestWorker);
}

function* watchAddCardRequest() {
  yield takeEvery(addCardRequest, addCardWorker);
}

function* watchAddCardSuccess() {
  yield takeEvery(addCardSuccess, addCardSuccessWorker);
}

function* watchRemoveCardRequest() {
  yield takeEvery(removeCardRequest, removeCardRequestWorker);
}

function* watchRemoveCardSuccess() {
  yield takeEvery(removeCardSuccess, removeCardSuccessWorker);
}

function* watchSetDefaultCardRequest() {
  yield takeEvery(setDefaultCardRequest, setDefaultCardRequestWorker);
}

function* watchSetDefaultCardSuccess() {
  yield takeEvery(setDefaultCardSuccess, setDefaultCardSuccessWorker);
}

function* watchFetchSubscriptionsRequest() {
  yield takeEvery(
    getPaymentSubscriptionsRequest,
    fetchSubscriptionsRequestWorker
  );
}

function* watchFetchSubscriptionsSuccess() {
  yield takeEvery(
    getPaymentSubscriptionsSuccess,
    fetchSubscriptionsSuccessWorker
  );
}

function* watchCreatePaymentSubscriptionRequest() {
  yield takeEvery(
    createPaymentSubscriptionRequest,
    createPaymentSubscriptionRequestWorker
  );
}

function* watchCreatePaymentSubscriptionSuccess() {
  yield takeEvery(
    createPaymentSubscriptionSuccess,
    editPaymentSubscribtionSuccessWorker
  );
}

function* watchPatchSubscriptionRequest() {
  yield takeEvery(
    editPaymentSubscribtionRequest,
    patchSubscriptionRequestWorker
  );
}

function* watchPatchSubscriptionSuccess() {
  yield takeEvery(
    editPaymentSubscribtionSuccess,
    editPaymentSubscribtionSuccessWorker
  );
}

function* watchFetchBillingInfo() {
  yield takeEvery(fetchBillingInfoRequest, fetchBillingInfoWorker);
}

export default function* paymentsSaga() {
  yield all([
    fork(watchProceedSubscription),
    fork(watchProceedSubscriptionWithExistingCard),
    fork(watchCreateBillingInfoRequest),
    fork(watchCreateBillingInfoSuccess),
    fork(watchCreateStripeCustomerRequest),
    fork(watchCreateStripeCustomerSuccess),
    fork(watchFetchCardsRequest),
    fork(watchAddCardRequest),
    fork(watchAddCardSuccess),
    fork(watchRemoveCardRequest),
    fork(watchRemoveCardSuccess),
    fork(watchSetDefaultCardRequest),
    fork(watchSetDefaultCardSuccess),
    fork(watchFetchSubscriptionsRequest),
    fork(watchFetchSubscriptionsSuccess),
    fork(watchCreatePaymentSubscriptionRequest),
    fork(watchCreatePaymentSubscriptionSuccess),
    fork(watchPatchSubscriptionRequest),
    fork(watchPatchSubscriptionSuccess),
    fork(watchFetchBillingInfo),
  ]);
}
