import React from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import infoIcon from "assets/icons/Info-default-icon.png";

import styles from "./info.module.scss";
import "./info.scss";

export default function Info({
  title,
  description,
  children,
  placement = "top",
  width = "320px",
  ...props
}) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip
          id="button-tooltip"
          className={"info-tooltip"}
          style={{ width }}
        >
          {title && <div className={styles.title}>{title}</div>}
          {description && (
            <div className={styles.description}>{description}</div>
          )}
          {children}
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <div className={styles.container} {...props}>
          <img
            ref={ref}
            className={styles.icon}
            src={infoIcon}
            alt="info"
            {...triggerHandler}
          />
        </div>
      )}
    </OverlayTrigger>
  );
}
