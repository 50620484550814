import { createSelector } from "reselect";

// Target channel
const targetChannelSelector = (state) => state.platforms.targetChannel;
export const selectorTargetChannel = createSelector(
  targetChannelSelector,
  (channel) => channel
);

// Facebook status
const facebookStatusSelector = (state) => state.platforms.facebookStatus;
export const selectorFacebookStatus = createSelector(
  facebookStatusSelector,
  (status) => status
);

// Etsy connect url
const etsyConnectUrlSelector = (state) => state.platforms.etsyConnectUrl;
export const selectorEtsyConnectUrl = createSelector(
  etsyConnectUrlSelector,
  (url) => url
);

// Etsy connect url loading
const etsyConnectUrlLoadingSelector = (state) =>
  state.platforms.isEtsyConnectUrlLoading;
export const selectorEtsyConnectUrlLoading = createSelector(
  etsyConnectUrlLoadingSelector,
  (bool) => bool
);

// Etsy connect url error
const etsyConnetUrlcErrorSelector = (state) =>
  state.platforms.etsyConnectUrlError;
export const selectorEtsyConnectUrlError = createSelector(
  etsyConnetUrlcErrorSelector,
  (error) => error
);

// Shopify connect url
const shopifyConnectUrlSelector = (state) => state.platforms.shopifyConnectUrl;
export const selectorShopifyConnectUrl = createSelector(
  shopifyConnectUrlSelector,
  (url) => url
);

// Shopify connect url loading
const shopifyConnectUrlLoadingSelector = (state) =>
  state.platforms.isShopifyConnectUrlLoading;
export const selectorShopifyConnectUrlLoading = createSelector(
  shopifyConnectUrlLoadingSelector,
  (bool) => bool
);

// Ebay connect url
const ebayConnectUrlSelector = (state) => state.platforms.ebayConnectUrl;
export const selectorEbayConnectUrl = createSelector(
  ebayConnectUrlSelector,
  (url) => url
);

// Ebay connect url loading
const ebayConnectUrlLoadingSelector = (state) =>
  state.platforms.isEbayConnectUrlLoading;
export const selectorEbayConnectUrlLoading = createSelector(
  ebayConnectUrlLoadingSelector,
  (bool) => bool
);

// Ebay connect url error
const ebayConnetUrlcErrorSelector = (state) =>
  state.platforms.ebayConnectUrlError;
export const selectorEbayConnectUrlError = createSelector(
  ebayConnetUrlcErrorSelector,
  (error) => error
);
