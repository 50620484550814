import React from "react";
import PropTypes from "prop-types";

export default function Plus(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
    >
      <g
        id="Group_76"
        data-name="Group 76"
        transform="translate(-1635.432 -458.877)"
      >
        <path
          id="Path_103"
          data-name="Path 103"
          d={`M-14099-22189.123v${props.height}`}
          transform="translate(15741.556 22648)"
          fill="none"
          stroke="#607cd7"
          strokeWidth="2"
        />
        <path
          id="Path_104"
          data-name="Path 104"
          d={`M0,0V${props.width}`}
          transform="translate(1635.432 465.822) rotate(-90)"
          fill="none"
          stroke="#607cd7"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

Plus.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Plus.defaultProps = {
  className: "",
  width: 14.248,
  height: 13.889,
};
