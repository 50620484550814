import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import headerReducer from "store/entities/Header/header.reducer";
import repliesReducer from "store/entities/Replies/replies.reducer";
import modalReducer from "store/entities/Modal/modal.reducer";

import {
  selectorAutoReplies,
  selectorRecommendedAutoReplies,
  selectorAutoRepliesLoading,
  selectorDeleteAutoReplyLoading,
  selectorUpdateAutoReplyLoading,
} from "../Selector";

import {
  ContentContainer,
  Button,
  Loader,
  ModalConfirmReject as DeleteReplyModal,
} from "components/simple";
import SevedReplyCard from "./SavedReplyCard/SavedReplyCard";
import RecommendedReplyCard from "./RecommendedReplyCard/RecommendedReplyCard";

import styles from "./autoReplies.module.scss";
import card from "./SavedReplyCard/savedReplyCard.module.scss";
import helpers from "styles/helpers.module.scss";
import { routes } from "router";

const {
  actions: { setHeaderTitle },
} = headerReducer;

const {
  actions: { showModal },
} = modalReducer;

const {
  actions: {
    autoRepliesRequest,
    updateAutoReplyRequest,
    deleteAutoReplyRequest,
  },
} = repliesReducer;

const AutoReply = () => {
  const [clickedReplyId, setClickedReplyId] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const autoReplies = useSelector(selectorAutoReplies);
  const recommendedAutoReplies = useSelector(selectorRecommendedAutoReplies);
  const repliesLoading = useSelector(selectorAutoRepliesLoading);
  const deleteReplyLoading = useSelector(selectorDeleteAutoReplyLoading);
  const updateAutoReplyLoading = useSelector(selectorUpdateAutoReplyLoading);

  const newAutoReplyHandler = () => {
    history.push(`${history.location.pathname}/1`);
  };

  useEffect(() => {
    dispatch(setHeaderTitle({ title: "Auto Replies" }));

    if (!autoReplies) {
      dispatch(autoRepliesRequest());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteReplyHandler = () => {
    dispatch(deleteAutoReplyRequest({ uid: clickedReplyId }));
  };

  const showDeleteModalHandler = (uid) => {
    dispatch(showModal());
    setClickedReplyId(uid);
  };

  const editHandler = (id) => {
    history.push(`${routes.autoRepliesScreen}/1/${id}/edit`);
  };

  const activateAutoreplyHandler = (uid, isActive) => {
    dispatch(updateAutoReplyRequest({ uid, data: { status: isActive } }));
  };

  return (
    <div className={styles.autoRepliesContainer}>
      {repliesLoading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <>
          <ContentContainer className={styles.repliesBlock}>
            <div className={`${styles.titleBlock} ${helpers.contentRowLine}`}>
              <div className={styles.sectionTitle}>Saved Auto Replies</div>
              <Button onClick={newAutoReplyHandler} className={styles.btn}>
                New Auto Reply
              </Button>
            </div>
            <div className={styles.savedRepliesBlock}>
              {/* the first card is static */}
              <div className={card.replyCard}>
                <div className={styles.createReplyTitle}>
                  Create first auto reply
                </div>
                <Button
                  onClick={newAutoReplyHandler}
                  variant="transparent"
                  className={styles.createBtn}
                >
                  Create
                </Button>
              </div>
              {autoReplies?.map((autoReply) => (
                <SevedReplyCard
                  key={autoReply.id}
                  autoReply={autoReply}
                  onModalShow={showDeleteModalHandler}
                  onEdit={editHandler}
                  onActivate={activateAutoreplyHandler}
                  activateLoading={updateAutoReplyLoading}
                />
              ))}
            </div>
          </ContentContainer>

          <ContentContainer className={styles.repliesBlock}>
            <div className={`${styles.titleBlock} ${helpers.contentRowLine}`}>
              <div className={styles.sectionTitle}>
                Recommended Auto Replies
              </div>
            </div>
            <div className={styles.savedRepliesBlock}>
              {recommendedAutoReplies?.map((autoReply) => (
                <RecommendedReplyCard
                  key={autoReply.id}
                  title={autoReply.reply.name}
                  autoReplyId={autoReply.id}
                  handler={editHandler}
                />
              ))}
            </div>
          </ContentContainer>
        </>
      )}

      <DeleteReplyModal
        messageText={`Are you sure to delete this reply?`}
        confirmBtnText={"Yes"}
        rejectBtnText={"No"}
        onConfirmHandler={deleteReplyHandler}
        isLoading={deleteReplyLoading}
      />
    </div>
  );
};

export default AutoReply;
