import React from "react";
import PropTypes from "prop-types";

export default function ArrowDown(props) {
  return (
    <svg
      style={props.style}
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 91.01 90.98`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0,-952.36218)">
        <g transform="translate(5.9965554,958.37202)">
          <path
            className="st0"
            d="M39.51-4.51c-11.27,0-22.54,4.29-31.15,12.9c-17.15,17.15-17.15,45.04,0,62.22
			c17.19,17.15,45.11,17.15,62.26,0c17.19-17.19,17.19-45.07,0-62.22C62.05-0.22,50.78-4.51,39.51-4.51z M39.51-0.54
			c8.33,0,16.69,2.62,23.7,7.83L51.7,18.84c-3.76-2.2-7.97-3.33-12.19-3.33s-8.43,1.13-12.19,3.33L15.77,7.29
			C22.82,2.08,31.15-0.54,39.51-0.54z M7.27,15.79l11.59,11.52c-4.43,7.48-4.43,16.9,0,24.38L7.27,63.24
			C-3.11,49.14-3.11,29.86,7.27,15.79z M71.72,15.79c10.38,14.07,10.38,33.34,0,47.44L60.17,51.69c4.39-7.48,4.39-16.9,0-24.38
			L71.72,15.79z M39.51,19.48c5.1,0,10.24,1.95,14.14,5.88c7.83,7.8,7.83,20.44,0,28.28s-20.48,7.83-28.31,0s-7.83-20.48,0-28.28
			C29.27,21.43,34.37,19.48,39.51,19.48z M27.29,60.19h0.04c7.48,4.43,16.87,4.43,24.38,0h0.04l11.48,11.52
			c-14.07,10.42-33.38,10.42-47.44,0L27.29,60.19z"
          />
        </g>
      </g>
    </svg>
  );
}

ArrowDown.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowDown.defaultProps = {
  className: "",
  width: 11.98,
  height: 11.23,
};
