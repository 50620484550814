import React from "react";

import { Link } from "react-router-dom";
import { Button, Tooltip } from "components/simple";
import SVG from "components/SVG";

import styles from "./topControls.module.scss";
import helpers from "styles/helpers.module.scss";

import folderIcon from "assets/icons/small-folder.png";
import notificationIcon from "assets/icons/theme.png";

const TopControls = ({ target, selectedMessages, onDeleteConv }) => {
  const isSelectedMessages = !!selectedMessages.length;

  return (
    <div className={`${styles.controlsContainer} ${helpers.contentRowLine}`}>
      <div className={styles.userInfo}>
        <div>{target.name_interlocutor}</div>
        <div>{target.listing_title}</div>
      </div>
      <div className={styles.btnsWrapper}>
        <div className={styles.controlsWrapper}>
          <Tooltip
            text={isSelectedMessages ? "Delete" : "Select Messages to Delete"}
          >
            <div
              onClick={() => onDeleteConv(target.id)}
              className={`${styles.controlBtn} ${
                !isSelectedMessages ? styles.disabled : ""
              }`}
            >
              <SVG.Delete />
            </div>
          </Tooltip>

          <div className={styles.controlBtn}>
            <img src={folderIcon} alt="folder" />
          </div>
          <div className={styles.controlBtn}>
            <img src={notificationIcon} alt="notification" />
          </div>
        </div>
        <div className={styles.autoreplyBtnWrapper}>
          {/* <Link to={`/auto-replies/1/${target.listing}/listing`}> */}
          <Link to={`/auto-replies/1`}>
            <Button className={styles.autoreplyBtn}>New Auto Reply</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopControls;
