import React, { useEffect, useState } from "react";

import ProfileDropdown from "screens/Dashboard/Navbar/ProfileDropdown";
import { Loader } from "components/simple";

import styles from "./savedReplyCard.module.scss";
import helpers from "styles/helpers.module.scss";

const SavedReplyCard = ({
  autoReply,
  onModalShow,
  onEdit,
  onActivate,
  activateLoading,
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(autoReply.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const profileDropdownItems = [
    {
      title: "Edit Auto Reply",
      onClick: () => {
        onEdit(autoReply.id);
      },
    },
    {
      title: "Delete",
      onClick: () => {
        onModalShow(autoReply.id);
      },
    },
  ];

  const onCheckboxChange = (e) => {
    setIsActive(e.target.checked);
    onActivate(autoReply.id, e.target.checked);
  };

  return (
    <div className={`${!isActive && styles.notActive} ${styles.replyCard}`}>
      <div className={`${styles.titleBclock} ${helpers.contentRowLine}`}>
        <div className={styles.title}>{autoReply.reply.name}</div>
        <ProfileDropdown
          items={profileDropdownItems}
          className={styles.dropdown}
        />
      </div>

      <div className={styles.switcherBlock}>
        {activateLoading[autoReply.id] ? (
          <Loader />
        ) : (
          <>
            <div className={styles.statusTitle}>
              {isActive ? "Active" : "Inactive"}
            </div>
            <div className={styles.switcherContainer}>
              <label className={styles.switch}>
                <input
                  checked={isActive}
                  onChange={onCheckboxChange}
                  type="checkbox"
                />
                <span className={`${styles.slider} ${styles.round}`}></span>
              </label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SavedReplyCard;
