import { createSelector } from "reselect";

// Signup loading
const signupLoadingSelector = (state) => state.user.isSingupLoading;
export const selectorSignupLoading = createSelector(
  signupLoadingSelector,
  (bool) => bool
);

// Login loading
const loginLoadingSelector = (state) => state.user.isLoginLoading;
export const selectorLoginLoading = createSelector(
  loginLoadingSelector,
  (bool) => bool
);
