import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { history, routes } from "router";

import messagesReducer from "store/entities/Messages/messages.reducer";
import notificationsReducer from "store/entities/Notification/notifications.reducer";

import * as service from "./messages.service";
import { makeRequest } from "../../sagas/helpers";

const {
  // Logout
  // logoutRequest,
  logoutSuccess,

  // TODO: delete
  // // Get Etsy Conversations
  // getEtsyConversationsRequest,
  // getEtsyConversationsSuccess,
  // getEtsyConversationsFailure,

  // Delete Etsy Conversation
  deleteEtsyConversationRequest,
  deleteEtsyConversationSuccess,
  deleteEtsyConversationFailure,

  // Get inbox messages
  getMessagesRequest,
  getMessagesSuccess,
  getMessagesFailure,

  // Update message
  updateMessageRequest,
  updateMessageSuccess,
  updateMessageFailure,
  // Clear selected messages
  selectedMessagesClear,
  // Delete message success
  deleteMessageSuccess,
} = messagesReducer.actions;

const {
  actions: { addNotification: addNotificationAction },
} = notificationsReducer;

// watchers

// TODO: delete
// function* getEtsyConversationsWorker() {
// try {
//   const response = yield call(makeRequest(service.fetchEtsyConversations));
//   if (response.message) {
//     if (response.message.logout) {
//       yield put(logoutSuccess(response.message.logout));
//     } else {
//       yield put(getEtsyConversationsFailure(response.message));
//     }
//   } else {
//     yield put(getEtsyConversationsSuccess(response.results));
//   }
// } catch (error) {
//   yield put(getEtsyConversationsFailure({ non_field_errors: "Error" }));
// }
// }

function* deleteEtsyConversationWorker({ payload }) {
  try {
    const { conversationId } = payload;
    const response = yield call(makeRequest(service.deleteEtsyConversation), {
      conversationId,
    });
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(deleteEtsyConversationFailure());
      }
    } else {
      yield put(deleteEtsyConversationSuccess());
    }
  } catch (error) {
    yield put(deleteEtsyConversationFailure());
  }
}

function* getMessagesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.fetchMessages), {
      payload,
    });
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(getMessagesFailure());
      }
    } else {
      yield put(getMessagesSuccess(response.results));
    }
  } catch (error) {
    yield put(getMessagesFailure());
  }
}

function* updateMessageWorker({ payload }) {
  const { message, searchParams } = payload;
  const uid = message.id;

  try {
    const response = yield call(makeRequest(service.updateMessage), {
      uid,
      message,
    });
    if (response.message) {
      if (response.message.logout) {
        yield put(logoutSuccess(response.message.logout));
      } else {
        yield put(updateMessageFailure());
        yield put(
          addNotificationAction({
            msg: "Failed to update message",
            type: "danger",
          })
        );
      }
    } else {
      yield put(updateMessageSuccess());
      if (searchParams) yield put(getMessagesRequest(searchParams));
      if (message.status === "Removed") {
        yield put(deleteMessageSuccess(response));
        yield put(selectedMessagesClear());
        yield put(
          addNotificationAction({
            msg: "Message was deleted",
            type: "success",
          })
        );
      }
    }
  } catch (error) {
    yield put(updateMessageFailure());
    yield put(
      addNotificationAction({
        msg: "Failed to update message",
        type: "danger",
      })
    );
  }
}

// watchers

// TODO: delete
// function* watchGetEtsyConversations() {
//   yield takeEvery(getEtsyConversationsRequest, getEtsyConversationsWorker);
// }

function* watchDeleteEtsyConversation() {
  yield takeEvery(deleteEtsyConversationRequest, deleteEtsyConversationWorker);
}

function* watchGetMessages() {
  yield takeEvery(getMessagesRequest, getMessagesWorker);
}

function* watchUpdateMessage() {
  yield takeEvery(updateMessageRequest, updateMessageWorker);
}

export default function* rootSaga() {
  yield all([
    // fork(watchGetEtsyConversations),
    fork(watchDeleteEtsyConversation),
    fork(watchGetMessages),
    fork(watchUpdateMessage),
  ]);
}
