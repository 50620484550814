import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";

import { selectorIsDisconnectChannelLoading } from "selectors/platformsSelectors";

import platformsReducer from "store/entities/Platforms/platforms.reducer";
import headerReducer from "store/entities/Header/header.reducer";
import modalReducer from "store/entities/Modal/modal.reducer";

import Loader from "components/simple/Loader/Loader";
import IntegrationCard from "./IntegrationCard/IntegrationCard";
import DisconnectIntegrationModal from "components/simple/ModalConfirmReject";

import styles from "./integrations.module.scss";

const {
  getChannelsRequest: getChannelsAction,
  getDisconnectChannelRequest: disconnectChannelAction,
} = platformsReducer.actions;

const {
  actions: { showModal },
} = modalReducer;

const {
  actions: { setHeaderTitle },
} = headerReducer;

const Integrations = ({
  channelsList,
  getChannelsHandler,
  disconnectChannelHandler,
}) => {
  const dispatch = useDispatch();
  const [integrationToDisconnect, setIntegrationToDisconnect] = useState(null);
  const isDisconnectRequestLoading = useSelector(
    selectorIsDisconnectChannelLoading
  );

  useEffect(() => {
    dispatch(setHeaderTitle({ title: "Integrations" }));

    // if (!channelsList) {
    // }
    getChannelsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showPopupToDisableHandler = (intergation) => {
    setIntegrationToDisconnect(intergation);
    dispatch(showModal());
  };

  const disconnectIntegrationHandler = () => {
    disconnectChannelHandler({
      uid: integrationToDisconnect.id,
      platform: integrationToDisconnect.name.toLowerCase(),
    });
  };

  return (
    <div>
      {channelsList?.length > 0 ? (
        <div className={styles.cardsContainer}>
          {channelsList.map((integration) => (
            <IntegrationCard
              disconnectHandler={showPopupToDisableHandler}
              key={integration.id}
              integration={integration}
            />
          ))}
        </div>
      ) : channelsList?.length === 0 ? (
        <div style={{ textAlign: "center" }}>No data</div>
      ) : (
        <Loader />
      )}

      <DisconnectIntegrationModal
        messageText={`Are you sure to disconnect ${integrationToDisconnect?.name} channel?`}
        confirmBtnText={"Yes"}
        rejectBtnText={"No"}
        onConfirmHandler={disconnectIntegrationHandler}
        isLoading={isDisconnectRequestLoading}
      />
    </div>
  );
};

Integrations.propTypes = {
  channelsList: PropTypes.array,
  getChannelsHandler: PropTypes.func,
  disconnectChannelHandler: PropTypes.func,
};

const mapStateToProps = ({ platforms }) => {
  return {
    channelsList: platforms.channelsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChannelsHandler: () => dispatch(getChannelsAction()),
    disconnectChannelHandler: (id, name) =>
      dispatch(disconnectChannelAction(id, name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
