import React, { useRef, useState } from "react";

import Tooltip from "components/simple/Tooltip";

import styles from "./bottomControls.module.scss";

import sendIcon from "assets/icons/send.png";
import folderIcon from "assets/icons/folder.png";
import attachIcon from "assets/icons/attach.png";
import closeIcon from "assets/icons/plus-icon.png";

function ArrayBufferToBinary(buffer) {
  // Convert an array buffer to a string bit-representation: 0 1 1 0 0 0...
  var dataView = new DataView(buffer);
  var response = "",
    offset = 8 / 8;
  for (var i = 0; i < dataView.byteLength; i += offset) {
    response += dataView.getInt8(i).toString(2);
  }
  return response;
}

const BottomControls = ({ onSendMessage, conversationId, platform }) => {
  const [message, setMessage] = useState("");
  // const [files, setFiles] = useState([]);
  const [binaryFiles, setBinaryFiles] = useState([]);
  const fileInputRef = useRef();

  const sendMessageHandler = async () => {
    // console.log(binaryFiles);

    if (message || binaryFiles.length) {
      onSendMessage({
        send: true,
        conversation_id: conversationId,
        text: message,
        type: "Outgoing",
        created_at: new Date(),
        attachment: binaryFiles,
        // platform,
      });

      setMessage("");
      // setFiles([]);
      setBinaryFiles([]);
      fileInputRef.current.value = "";
    }
  };

  const fileInputClickHandler = () => {
    fileInputRef.current.click();
  };

  const fileInputChangeHandler = (e) => {
    if (e.target.files.length) {
      // setFiles([...files, e.target.files[0]]);

      // console.log(files);

      var generator = new FileReader();

      // generator.readAsText(files[0]);
      generator.onload = function () {
        // console.log(generator.result);

        const bData = ArrayBufferToBinary(generator.result);
        setBinaryFiles([...binaryFiles, bData]);
      };
      generator.onerror = function (e) {};
      // generator.readAsBinaryString(e.target.files[0]);
      generator.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const removeAttachmentsHandler = (e) => {
    e.stopPropagation();
    // setFiles([]);
    setBinaryFiles([]);
    fileInputRef.current.value = "";
  };

  return (
    <div className={styles.bottomControlsContainer}>
      <form className={styles.textAreaBlock}>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          name="message"
          placeholder="Your Message"
          cols="30"
          rows="10"
        ></textarea>
        <Tooltip text={"Send"}>
          <div onClick={sendMessageHandler} className={styles.sendImgBlock}>
            <img src={sendIcon} alt="send message" />
          </div>
        </Tooltip>
      </form>
      <div className={styles.btnsBlock}>
        <div>
          <img src={folderIcon} alt="folder" />
        </div>
        <Tooltip text={"Attach file"}>
          <div onClick={fileInputClickHandler} className={styles.fileContainer}>
            {binaryFiles.length > 0 && (
              <>
                <div className={styles.fileIndicator}>{binaryFiles.length}</div>
                <div
                  onClick={removeAttachmentsHandler}
                  className={styles.remove}
                >
                  <img src={closeIcon} alt="close" />
                </div>
              </>
            )}
            <input
              onChange={fileInputChangeHandler}
              ref={fileInputRef}
              type="file"
              hidden
            />
            <img src={attachIcon} alt="attach file" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default BottomControls;
