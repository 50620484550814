import React from "react";
import { Redirect, Switch } from "react-router";
import { history } from "./history";

// Routes paths
import * as routes from "./routes";

// Guard routes
import PrivateRoute from "components/PrivateRoute";
import UnauthorizedOnlyRoute from "components/UnauthorizedOnlyRoute";

// Components
import AuthContainer from "screens/Auth/AuthContainer";
import Dashboard from "screens/Dashboard";

const MainRouter = () => {
  return (
    // <Router history={history}>
    <Switch>
      <UnauthorizedOnlyRoute
        exact
        path={routes.loginScreen}
        component={AuthContainer}
      />
      <UnauthorizedOnlyRoute
        exact
        path={`${routes.loginScreen}/:token`}
        component={AuthContainer}
      />
      <UnauthorizedOnlyRoute
        exact
        path={routes.signupScreen}
        component={AuthContainer}
      />
      <UnauthorizedOnlyRoute
        exact
        path={routes.resetPasswordScreen}
        component={AuthContainer}
      />
      <UnauthorizedOnlyRoute
        exact
        path={`${routes.chooseNewPasswordScreen}/:uid/:token`}
        component={AuthContainer}
      />

      <PrivateRoute exact path={routes.dashboardScreen} component={Dashboard} />

      <PrivateRoute
        exact
        path={`${routes.inventoryScreen}/:id?/:step?`}
        component={Dashboard}
      />

      <PrivateRoute
        exact
        path={`${routes.inventoryScreen}/:id/edit`}
        component={Dashboard}
      />

      <PrivateRoute
        exact
        path={routes.createListingScreen}
        component={Dashboard}
      />

      <PrivateRoute exact path={routes.myProfileScreen} component={Dashboard} />

      <PrivateRoute
        exact
        path={routes.selectNewPlanScreen}
        component={Dashboard}
      />

      <PrivateRoute
        exact
        path={`${routes.paymentScreen}/:id`}
        component={Dashboard}
      />

      <PrivateRoute
        exact
        path={routes.integrationsScreen}
        component={Dashboard}
      />

      <PrivateRoute
        exact
        path={`${routes.integrationsScreen}/:id`}
        component={Dashboard}
      />

      <PrivateRoute
        path={`${routes.messagesScreen}/:id?`}
        component={Dashboard}
      />

      <PrivateRoute
        exact
        path={routes.autoRepliesScreen}
        component={Dashboard}
      />

      <PrivateRoute
        exact
        path={`${routes.autoRepliesScreen}/:step`}
        component={Dashboard}
      />

      <PrivateRoute
        exact
        path={`${routes.autoRepliesScreen}/:step/:autoReplyId/:edit`}
        component={Dashboard}
      />

      <PrivateRoute
        exact
        path={`${routes.autoRepliesScreen}/:step/:listingId/:listing`}
        component={Dashboard}
      />

      <PrivateRoute exact path={routes.settingsScreen} component={Dashboard} />

      {/* <PrivateRoute exact path={`${routes.packageViewScreen}/:id`} component={Dashboard} /> */}

      <Redirect from="*" to={routes.dashboardScreen} />
    </Switch>
    // </Router>
  );
};

export default MainRouter;
export { history, routes };
