import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import {
  selectorShopifyConnectUrl,
  selectorShopifyConnectUrlLoading,
} from "../../Selector";
import { slectorImportLoading } from "selectors/inventorySelectors";

import * as routes from "router/routes";

import platformsReducer from "store/entities/Platforms/platforms.reducer";
import inventoryReducer from "store/entities/Inventory/inventory.reducer";

import { Input, Button, Loader } from "components/simple";

import styles from "../integrationScreen.module.scss";

const {
  actions: { importListingsRequest },
} = inventoryReducer;

const {
  actions: { getShopifyConnectUrlRequest, fetchShopifyCallbackRequest },
} = platformsReducer;

const Shopify = ({
  marketplaceName,
  statusConnection,
  channelId,
  redirect,
}) => {
  const [state, setState] = useState({
    name: "",
  });

  const connectUrl = useSelector(selectorShopifyConnectUrl);
  const connectUrlLoading = useSelector(selectorShopifyConnectUrlLoading);
  const importListingsLoading = useSelector(slectorImportLoading);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get("code");
    const state = query.get("state");

    if (location.search) {
      dispatch(
        fetchShopifyCallbackRequest({
          state,
          code,
        })
      );
      history.push(`${routes.integrationsScreen}/${channelId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connectUrl) {
      window.open(connectUrl, "_blank", "noopener,noreferrer");
      // TODO: redirect user to ? may be to main page (bcs on '/integrations' will be outdated data)
      history.push(`${routes.integrationsScreen}`);
      // dispatch(removeShopifyConnectUrl());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectUrl]);

  const handleInput = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const handleClearInputs = () => {
    setState({
      name: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getShopifyConnectUrlRequest({ shop_name: state.name }));
    handleClearInputs();
  };

  const importHandler = () => {
    dispatch(
      importListingsRequest({ platform: marketplaceName.toLowerCase() })
    );
  };

  const isConnected = statusConnection;

  return (
    <>
      {redirect ? (
        <Loader />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            marginTop: 30,
          }}
        >
          {!isConnected ? (
            <form onSubmit={handleSubmit}>
              <Input
                name="name"
                label="Shop name"
                value={state.name}
                onChange={handleInput}
                required
              />
              <Button
                isLoading={connectUrlLoading}
                style={{ width: "100%" }}
                type="submit"
              >
                Connect
              </Button>
            </form>
          ) : (
            <div className={styles.importContainer}>
              <div
                className={styles.text}
              >{`Your ${marketplaceName} account is now connected to ListAcross`}</div>
              <div className={styles.text}>
                {`Import listings from your ${marketplaceName} account`}
              </div>
              <Button
                isLoading={importListingsLoading[marketplaceName.toLowerCase()]}
                style={{ width: "300px" }}
                onClick={importHandler}
                className={styles.btn}
              >
                Import
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Shopify;
