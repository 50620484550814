import { createSelector } from "reselect";

const deleteListingLoadingSelector = (state) =>
  state.inventory.deleteListingLoading;

export const selectorDeleteListingLoading = createSelector(
  deleteListingLoadingSelector,
  (obj) => obj
);

// Push listing loading
const pushListingLoading = (state) => state.inventory.pushListingLoading;
export const selectorPushListingLoading = createSelector(
  pushListingLoading,
  (obj) => obj
);
