import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Listing bulk upload
  isListingBulkUploadLoading: false,

  // Inventory list
  inventoryData: [],
  isInventoryLoading: false,

  // Categories list
  categoriesList: [],
  isCategoriesListLoading: false,

  // Import listings
  importListingsLoading: {},

  // Create listing
  createListingLoading: false,

  // get listing
  fetchListingLoading: false,

  // Edit listing
  editingListing: null,
  editListingLoading: false,
  
  duplicateListing: null,
  duplicateListingLoading: false,

  // Edit listing images
  editingListingImages: null,
  editingListingImagesLoading: false,

  // Push Listing
  pushListingLoading: {},

  // Fetch Etsy shipping templates
  isEtsyShippingTemplatesLoading: false,
  etsyShippingTemplatesList: null,

  // Fetch Etsy Listings
  isFetchEtsyListingLoading: false,

  // Delete listing from platform
  deleteListingLoading: {},

  // Delete listing from Listacross
  isDeleteListacrossListingLoading: false,
};

const inventory = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    // Fetch inventory table data
    fetchInventoryRequest(state) {
      state.isInventoryLoading = true;
    },
    fetchInventorySuccess(state, { payload }) {
      state.isInventoryLoading = false;
      state.inventoryData = payload;
    },
    fetchInventoryFailure(state) {
      state.isInventoryLoading = false;
    },

    // Fetch categories list
    fetchCategoriesRequest(state) {
      state.isCategoriesListLoading = true;
    },
    fetchCategoriesSuccess(state, { payload }) {
      state.categoriesList = payload;
      state.isCategoriesListLoading = false;
    },
    fetchCategoriesFailure(state, { payload }) {
      state.isCategoriesListLoading = false;
    },

    // Import listings
    importListingsRequest(state, { payload: { platform } }) {
      state.importListingsLoading = {
        ...state.importListingsLoading,
        [platform]: true,
      };
    },
    importListingsSuccess(state, { payload: platform }) {
      state.importListingsLoading = {
        ...state.importListingsLoading,
        [platform]: false,
      };
    },
    importListingsFailure(state, { payload: platform }) {
      state.importListingsLoading = {
        ...state.importListingsLoading,
        [platform]: false,
      };
    },

    // Create listing
    createListingRequest(state) {
      state.createListingLoading = true;
    },
    createListingSuccess(state, { payload }) {
      const { newListing } = payload;
      state.editingListing = newListing;
      state.createListingLoading = false;
    },
    createListingFailure(state) {
      state.createListingLoading = false;
    },

    // Create silting steps
    setListingStep(state, { payload: step }) {
      state.createListingStep = step;
    },

    // Create listing bulk upload
    createListingBulkImportRequest(state) {
      state.isListingBulkUploadLoading = true;
    },
    createListingBulkImportSuccess(state) {
      state.isListingBulkUploadLoading = false;
    },
    createListingBulkImportFailure(state) {
      state.isListingBulkUploadLoading = false;
    },

    // Edit
    fetchEditingListingRequest(state) {
      state.fetchListingLoading = true;
    },
    fetchEditingListingSuccess(state, { payload }) {
      state.editingListing = payload;
      state.fetchListingLoading = false;
    },
    fetchEditingListingFailure(state) {
      state.fetchListingLoading = false;
    },
    saveEditingListing(state, { payload }) {
      state.editingListing = payload;
    },

    fetchEditingListingImagesRequest(state) {
      state.editingListingImagesLoading = true;
    },
    fetchEditingListingImagesSuccess(state, { payload }) {
      state.editingListingImagesLoading = false;
      state.editingListingImages = payload;
    },
    fetchEditingListingImagesFailure(state) {
      state.editingListingImagesLoading = false;
    },

    updateListingRequest(state) {
      state.editListingLoading = true;
    },
    updateListingSuccess(state, { payload }) {
      const { newListing } = payload;
      state.editingListing = newListing;
      state.editListingLoading = false;
    },
    updateListingFailure(state) {
      state.editListingLoading = false;
      state.editingListing = null;
    },

    duplicateListingRequest(state) {
      state.duplicateListingLoading = true;
    },
    duplicateListingSuccess(state, { payload }) {
      const { duplicatedListing } = payload;
      state.editingListing = duplicatedListing;
      state.duplicateListingLoading = false;
    },
    duplicateListingFailure(state) {
      state.duplicateListingLoading = false;
      state.editingListing = null;
    },

    // delete listing image
    deleteImageRequest() {},
    deleteImageSuccess() {},
    deleteImageFailure() {},

    // Push listing
    pushListingRequest(state, { payload: { platform } }) {
      state.pushListingLoading = {
        ...state.pushListingLoading,
        [platform]: true,
      };
    },
    pushListingSuccess(state, { payload: platform }) {
      state.pushListingLoading = {
        ...state.pushListingLoading,
        [platform]: false,
      };
    },
    pushListingFailure(state, { payload: platform }) {
      state.pushListingLoading = {
        ...state.pushListingLoading,
        [platform]: false,
      };
    },

    // Fetch Etsy shipping templates
    fetchEtsyShippingTemplatesRequest(state) {
      state.isEtsyShippingTemplatesLoading = true;
    },
    fetchEtsyShippingTemplatesSuccess(state, { payload }) {
      state.etsyShippingTemplatesList = payload;
      state.isEtsyShippingTemplatesLoading = false;
    },
    fetchEtsyShippingTemplatesFailure(state, { payload }) {
      state.isEtsyShippingTemplatesLoading = false;
    },

    // Delete listing
    deleteListingRequest(state, { payload: { platform } }) {
      state.deleteListingLoading = {
        ...state.deleteListingLoading,
        [platform]: true,
      };
    },
    deleteListingSuccess(state, { payload: platform }) {
      state.deleteListingLoading = {
        ...state.deleteListingLoading,
        [platform]: false,
      };
    },
    deleteListingFailure(state, { payload: platform }) {
      state.deleteListingLoading = {
        ...state.deleteListingLoading,
        [platform]: false,
      };
    },

    // Logout
    logoutSuccess(state, { payload }) {
      state = { ...initialState };
    },
  },
});

export default inventory;
