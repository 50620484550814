import React from "react";

import Button from "components/simple/Button";

import styles from "./recommendedReplyCard.module.scss";
import main from "../autoReplies.module.scss";
import card from "../SavedReplyCard/savedReplyCard.module.scss";

const RecommendedReplyCard = ({ title, handler, autoReplyId }) => {
  return (
    <div className={card.replyCard}>
      <div className={`${main.createReplyTitle} ${styles.recommendedTitle}`}>
        {title}
      </div>
      <Button
        onClick={() => handler(autoReplyId)}
        variant="transparent"
        className={`${main.createBtn} ${styles.recommendedBtn}`}
      >
        Add Reply
      </Button>
    </div>
  );
};

export default RecommendedReplyCard;
