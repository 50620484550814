import { createSelector } from "reselect";

// Get all plans
const plansSelector = (state) => state.subscription.subscriptionPlans;
export const selectorPlans = createSelector(plansSelector, (list) => {
  if (list) {
    return list.slice().sort((a, b) => Number(a.cost) - Number(b.cost));
  }
  return null;
});

// Get current Subscription
const currentSubscriptionSeelctor = (state) =>
  state.subscription.currentSubscription;
export const selectorCurrentSubscription = createSelector(
  currentSubscriptionSeelctor,
  (item) => item
);
