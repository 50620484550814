import React, { useState } from "react";

import styles from "./platformSwitcher.module.scss";

const PlatformSwitcher = ({ channel, addHandler, removeHandler }) => {
  const [isChecked, setIsChecked] = useState(false);

  const onCheckboxChange = ({ target }) => {
    setIsChecked(target.checked);
    if (target.checked) {
      addHandler(channel.id, target.name);
    } else {
      removeHandler(channel.id, target.name);
    }
  };

  return (
    <div
      className={`${styles.switcherBlock} ${styles.platformSwitcher} ${
        !isChecked && styles.disabledPlatform
      }`}
    >
      <div className={styles.infoBlock}>
        <div className={styles.imgContainer}>
          <img src={channel.logo} alt="market logo" />
        </div>
        <div className={styles.platformName}>{channel.name}</div>
      </div>

      <div className={styles.switcherContainer}>
        <label className={styles.switch}>
          <input
            name="platforms"
            checked={isChecked}
            onChange={onCheckboxChange}
            type="checkbox"
          />
          <span className={`${styles.slider} ${styles.round}`}></span>
        </label>
      </div>
    </div>
  );
};

export default PlatformSwitcher;
