import Plus from "./Plus";
import ArrowDown from "./ArrowDown";
import Dashboard from "./Dashboard";
import Integrations from "./Integrations";
import Inventory from "./Inventory";
import Messages from "./Messages";
import AutoReplies from "./AutoReplies";
import Support from "./Support";
import Settings from "./Settings";
import Refer from "./Refer";
import CloseX from "./CloseX";
import Edit from "./Edit";
import Delete from "./Delete";
import Sort from "./Sort";

const icons = {
  Plus,
  ArrowDown,
  Dashboard,
  Integrations,
  Inventory,
  Messages,
  AutoReplies,
  Support,
  Settings,
  Refer,
  CloseX,
  Edit,
  Delete,
  Sort,
};

export default icons;
