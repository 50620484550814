import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import modalReducer from "store/entities/Modal/modal.reducer";
import { selectorIsModalShownFactory } from "selectors/modalSelectors";

import { Button, Input, SelectInput } from "components/simple";
import BootstrapModal from "react-bootstrap/Modal";

import styles from "./styles.module.scss";

import notificationsReducer from "store/entities/Notification/notifications.reducer";
import paymentsReducer from "store/entities/Payments/payments.reducer";
import {
  selectorCards,
  selectorDefaultCard,
} from "selectors/paymentsSelectors";

import {
  selectorBillingInfo,
  selectorBillingInfoLoading,
} from "../../../screens/Settings/Selector";

import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51GqiPzH7OfnnyW5V26DRll5Hsyriq9fnnTamAehTft35Ogr8vO2xXwrtOCg42KZmJ1ubj2QorFQNwGVeA5LDw5zW00EkMHhHv9"
);

const {
  actions: { hideModal },
} = modalReducer;

const {
  actions: {
    addCardRequest,
    createBillingInfoRequest,
  },
} = paymentsReducer;

const {
  actions: { addNotification },
} = notificationsReducer;

const CheckoutForm = ({
  isLoading,
  confirmBtnText,
  rejectBtnText,
  setHide,
  billingData,
  countries,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [checkedCountry, setCheckedCountry] = useState(
    billingData?.country || ""
  );
  const [modalValues, setModalValues] = useState({
    streetAddress: billingData?.address || "",
    city: billingData?.city || "",
    email: billingData?.email || "",
    billingName: billingData?.name || "",
    zipCode: billingData?.zip_code || "",
    phone: billingData?.phone || "",
    floor: "",
    state: "",
  });

  const countriesOptions = Object.keys(countries).map((item) => {
    return { value: countries[item], label: countries[item] };
  });

  const selectCountryHandler = (_, value) => {
    setCheckedCountry(value);
    // dispatch(
    //   onUpdateTimezone({
    //     uid: profile.id,
    //     data: { timezone: value.split(" (")[0] },
    //   })
    // );
  };

  const selectChangeHandler = (name, value) => {
    setModalValues({ ...modalValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const body = {
      // firstName: modalValues.firstName,
      // lastName: modalValues.lastName,
      // floor: modalValues.floor,
      // state: modalValues.state,
      address: modalValues.streetAddress,
      city: modalValues.city,
      country: checkedCountry,
      email: modalValues.email,
      name: modalValues.billingName,
      zip_code: modalValues.zipCode,
      phone: modalValues.phone,
    };

    if (cardComplete) {
      setProcessing(true);
    }

    const payload = await stripe.createToken(
      elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement)
    );

    if (
      !billingData ||
      (!billingData?.address || billingData?.address !== body.address) ||
      (!billingData?.city || billingData?.city !== body.city) ||
      (!billingData?.country || billingData?.country !== body.country) ||
      (!billingData?.email || billingData?.email !== body.email) ||
      (!billingData?.name || billingData?.name !== body.name) ||
      (!billingData?.zip_code || billingData?.zip_code !== body.zip_code)
    ) {
      dispatch(
        createBillingInfoRequest({
          paymentMethod: payload?.token?.id,
          billingInfo: body,
          isChangingSubscription: false,
        })
      );
    } else {
      dispatch(
        addCardRequest({
          payment_token: payload?.token?.id,
          paymentMethod: payload?.token?.id,
          isChangingSubscription: false,
        })
      );
    }

    if (payload?.error) {
      setError(payload?.error);
      dispatch(
        addNotification({
          msg: payload?.error?.message,
          type: "danger",
        })
      );
    } else {
      setHide();
    }
  };

  const inputStyle = {
    base: {
      fontSize: "24px",
      color: "#000",
      fontWeight: "300",
      ":-webkit-autofill": {
        color: "#000",
      },
      "::placeholder": {
        color: "#7d7d7d",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  };

  const CARD_NUMBER_OPTIONS = {
    placeholder: "Card Number",
    showIcon: true,
    iconStyle: "solid",
    style: inputStyle,
  };

  const CARD_EXPIRATION_OPTIONS = {
    placeholder: "Expiration Date (MM/YY)",
    style: inputStyle,
  };

  const CARD_CVC_OPTIONS = {
    placeholder: "Security Code",
    style: inputStyle,
  };

  let warnText =
    "By clicking or tapping the button below, you also authorize ListAcross to store this card and use it in the future to charge you for any fees, expenses, and other amounts which may owe to ListAcross as described in the Terms of Use.";

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.cardInfo}>
        <div className={`${styles["input-container"]}`}>
          <div className={styles["input-wrap"]}>
            <CardNumberElement
              options={CARD_NUMBER_OPTIONS}
              onChange={(e) => {
                setError(e.error);
                setCardComplete(e.complete);
              }}
            />
          </div>
        </div>
        <div className={`${styles["input-container"]}`}>
          <div className={styles["input-wrap"]}>
            <CardExpiryElement
              options={CARD_EXPIRATION_OPTIONS}
              onChange={(e) => {
                setError(e.error);
                setCardComplete(e.complete);
              }}
            />
          </div>
        </div>
        <div className={`${styles["input-container"]}`}>
          <div className={styles["input-wrap"]}>
            <CardCvcElement
              options={CARD_CVC_OPTIONS}
              onChange={(e) => {
                setError(e.error);
                setCardComplete(e.complete);
              }}
            />
          </div>
        </div>
      </div>
      {/* <div className={styles.cardInfo}>
        <Input
          name="firstName"
          label="First Name"
          value={modalValues.firstName}
          onChange={selectChangeHandler}
          containerClass={styles.passInputContainer}
          type="text"
          // maxLength={16}
        />
        <Input
          name="lastName"
          label="Last Name"
          value={modalValues.lastName}
          onChange={selectChangeHandler}
          containerClass={styles.passInputContainer}
          type="text"
        />
      </div> */}
      <div className={styles.billingAddressTitle}>Billing Address</div>
      <div className={styles.cardInfo}>
        <Input
          name="billingName"
          label="Billing Name"
          value={modalValues.billingName}
          onChange={selectChangeHandler}
          containerClass={styles.passInputContainer}
          type="text"
          required
        />
        <SelectInput
          label={"Country or Region"}
          name="country"
          value={checkedCountry}
          onChange={selectCountryHandler}
          options={countriesOptions}
          containerClass={styles.passInputContainer}
          required
        />
      </div>
      <div className={styles.cardInfo}>
        <Input
          name="streetAddress"
          label="Street Address"
          value={modalValues.streetAddress}
          onChange={selectChangeHandler}
          containerClass={styles.streetAddressInput}
          type="text"
          required
        />
        <Input
          name="floor"
          label="Apt. / Suite / Floor"
          value={modalValues.floor}
          onChange={selectChangeHandler}
          containerClass={styles.floorInput}
          type="text"
        />
      </div>
      <div className={styles.cardInfo}>
        <div className={styles.cityAndStateContainer}>
          <Input
            name="city"
            label="City"
            value={modalValues.city}
            onChange={selectChangeHandler}
            containerClass={styles.passInputContainer}
            type="text"
            required
          />
          <Input
            name="state"
            label={"State or Province"}
            value={modalValues.state}
            onChange={selectChangeHandler}
            type="text"
          />
          {/* <SelectInput
            label={"State or Province"}
            name="state"
            value={state}
            onChange={selectStateHandler}
            options={stateOptions}
            containerClass={styles.stateOrProvince}
          /> */}
        </div>
        <Input
          name="zipCode"
          label="Zip or Postal Code"
          value={modalValues.zipCode}
          onChange={selectChangeHandler}
          containerClass={styles.zipCode}
          type="text"
          required
        />
      </div>
      <div className={styles.cardInfo}>
        <Input
          name="phone"
          label="Phone Number"
          value={modalValues.phone}
          onChange={selectChangeHandler}
          containerClass={styles.passInputContainer}
          type="text"
        />
        <Input
          name="email"
          label="Email"
          value={modalValues.email}
          onChange={selectChangeHandler}
          containerClass={styles.passInputContainer}
          type="text"
          required
        />
      </div>
      <div className={styles.warnInfo}>{warnText}</div>
      <div className={styles.modalBtnContainer}>
        <Button type="submit" isLoading={isLoading}>
          {confirmBtnText}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setHide();
          }}
          className={styles.modalBtn}
        >
          {rejectBtnText}
        </Button>
      </div>
    </form>
  );
};

const Modal = ({
  type,
  onConfirmHandler,
  isLoading,
  messageText,
  confirmBtnText,
  rejectBtnText,
  containerStyles,
  contentStyles,
  countries,
}) => {
  const dispatch = useDispatch();
  const selectorIsModalShown = selectorIsModalShownFactory(type);
  const isModalShown = useSelector(selectorIsModalShown);
  const cards = useSelector(selectorCards);
  const billingData = useSelector(selectorBillingInfo);

  const setHide = () => {
    dispatch(hideModal(type));
  };

  useEffect(() => {
    if (!isLoading && isModalShown) {
      setHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <div>
      <BootstrapModal
        show={isModalShown}
        onHide={setHide}
        aria-labelledby="example-custom-modal-styling-title"
        centered={true}
        dialogClassName={`${containerStyles} ${styles.modalContainer}`}
        contentClassName={`${contentStyles} ${styles.modalContent}`}
      >
        <BootstrapModal.Header bsPrefix={`${styles.header}`}>
          <BootstrapModal.Title
            id="example-custom-modal-styling-title"
            bsPrefix={styles.title}
          >
            {messageText}
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body bsPrefix={styles.modalBody}>
          <div style={{ width: "100%" }}>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                onConfirmHandler={onConfirmHandler}
                isLoading={isLoading}
                confirmBtnText={confirmBtnText}
                rejectBtnText={rejectBtnText}
                setHide={setHide}
                hideModal={hideModal}
                billingData={billingData}
                countries={countries}
              />
            </Elements>
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

Modal.propTypes = {
  onConfirmHandler: PropTypes.func,
  onRejectHandler: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  messageText: PropTypes.string.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  rejectBtnText: PropTypes.string.isRequired,
  containerStyles: PropTypes.object,
  contentStyles: PropTypes.object,
};

export default Modal;
