export let socket;

export const wsConnect = () => {
  if (!socket) {
    socket = new WebSocket("wss://back.listacross.com/ws/inbox/chat/");

    socket.onopen = function (event) {
      wsSend({ Token: localStorage.getItem("token") });
    };

    socket.onerror = function (error) {
      setTimeout(() => {
        wsClose();
        socket = null;
        wsConnect();
      }, 5000);
    };

    socket.onclose = function () {
      console.log("The connection has been closed successfully.");
    };
  }
};

export const wsSend = (data) => {
  try {
    socket.send(JSON.stringify(data));
  } catch (error) {}
};

export const wsClose = () => {
  try {
    socket.close();
  } catch (error) {}
};
