import React from "react";

import { Input, SelectInput } from "components/simple";
import InfoModal from "./infoModal/infoModal";

import styles from "./stepFirst.module.scss";
import stepsBody from "../stepsBody.module.scss";
import password from "../../../Settings/Password/password.module.scss";

const StepFirst = ({
  changeSelectHandler,
  changeInputsHandler,
  state,
  setState,
  replies,
  shortcuts,
  selectedReply,
}) => {
  const repliesOptions = replies?.map((reply) => ({
    value: reply.pk,
    label: reply.name,
  }));

  const closeModalHandler = () => {
    setState({
      ...state,
      isDragModalShown: false,
    });
  };

  const submitModalHandler = () => {
    localStorage.setItem("autoreplyDragModal", true);
    setState({
      ...state,
      isDragModalShown: false,
    });
  };

  // const defaultText = `Hi {{Customer Name}},
  //   \nThanks for reaching out! The price for the {{Title Listing}} is {{Price Listing}}. Let me know when you would like to pick it up.
  //   \nThanks!
  //   \n{{Seller Name}}`;

  // const generateShortcuts = (shortcuts) => {
  //   if (shortcuts) {
  //     const temp = [];
  //     for (let key in shortcuts) {
  //       temp.push(shortcuts[key]);
  //     }
  //     return temp;
  //   }
  //   return [];
  // };
  // const gen = () => {
  //   return [...shortcuts];
  // };
  // const shortcutsArr = useMemo(() => generateShortcuts, shortcuts);

  return (
    <>
      <div className={stepsBody.stepTitle}>Create Your Reply</div>
      <div className={styles.fieldsBlock}>
        <div className={styles.inputsRow}>
          <Input
            name="title"
            value={state.title}
            onChange={changeInputsHandler}
            label={"Create a New Reply or"}
            placeholder={"Enter Reply Title"}
            tooltipTitle={"Information"}
            tooltipDescription={
              "You can modify an existing Saved Reply, or you can create a new one from scratch."
            }
            type="text"
            required
          />

          {replies && (
            <SelectInput
              placeholder={"Select Reply"}
              label={"Select a Previuosly Saved Reply"}
              options={repliesOptions}
              onChange={changeSelectHandler}
              value={state.listingName}
            />
          )}
        </div>
      </div>

      <div className={`${password.inputContainer} ${styles.messageBlock}`}>
        <label htmlFor="text">Your Message</label>
        {shortcuts && (
          <>
            <div>Available Variables:</div>
            <div className={styles.shortcutsContainer}>
              {shortcuts.map((i) => {
                return <div key={i}>{i}</div>;
              })}
            </div>
          </>
        )}
        <textarea
          value={state.text}
          onChange={({ target }) =>
            changeInputsHandler(target.name, target.value)
          }
          rows="10"
          cols="45"
          name="text"
        ></textarea>
      </div>

      <InfoModal
        isInfoPopupShown={state.isDragModalShown}
        closeModalHandler={closeModalHandler}
        submitModalHandler={submitModalHandler}
      />
    </>
  );
};

export default StepFirst;
