import React from "react";
import PropTypes from "prop-types";

export default function ArrowDown(props) {
  return (
    <svg
      style={props.style}
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 11.98 11.23`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <style type="text/css">
	.st0{stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style> */}
      <rect x="0.5" y="0.5" className="st0" width="3.53" height="3.53" />
      <rect x="0.5" y="7.19" className="st0" width="3.53" height="3.53" />
      <rect x="6.99" y="0.5" className="st0" width="4.49" height="10.23" />
    </svg>
  );
}

ArrowDown.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowDown.defaultProps = {
  className: "",
  width: 11.98,
  height: 11.23,
};
