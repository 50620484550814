import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteAddress: false,
  deleteAccount: false,
  addCard: false,
};

const modal = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal(state, { payload }) {
      state[payload] = true;
    },

    hideModal(state, { payload }) {
      state[payload] = false;
    },
  },
});

export default modal;
